import React from "react";

interface VCModalProps {
  data: any;
  onClose: () => void;
}

const VCModal: React.FC<VCModalProps> = ({ data, onClose }) => {
  const jsonData = JSON.stringify(data, null, 2);

  return (
    <div className="bg-gray-600 bg-opacity-50 overflow-y-auto  w-full flex justify-center items-center">
      <div className="modalContent bg-white p-5 rounded-lg shadow-lg w-full  overflow-auto flex flex-col justify-between">
        <h2 className="text-lg font-bold mb-4">Verifiable Credential Data</h2>
        <pre className="whitespace-pre-wrap text-sm overflow-y-auto h-96 w-full">
          {jsonData}
        </pre>
        <button
          onClick={onClose}
          className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 self-end"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default VCModal;
