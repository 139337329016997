import { useParams } from "react-router-dom";
import DefinitionList from "./definition/DefinitionList";

import RouteTransition from "./layout/RouteTransition";
import DefinitionsPanel from "./ledger/DefinitionsPanel";
import LedgerNav from "./ledger/LedgerNav";
import PageHeader from "./layout/PageHeader";
import { useQuery } from "@tanstack/react-query";
import { ledgerQuery } from "@utils/queries";

const Definitions: React.FunctionComponent = () => {
  const { id = "" } = useParams();

  let { data: ledger } = useQuery(["ledger", id], () => ledgerQuery(id), {
    keepPreviousData: true,
  });
  if (!ledger) ledger = { name: "Not Found", definitions: [] };

  return (
    <RouteTransition>
      <PageHeader
        pageTitle={ledger.deletedAt ? `${ledger.name} (Archived)` : ledger.name}
        breadcrumbs={[
          ["All Ledgers", "/ledgers/"],
          [ledger.name, undefined],
        ]}
      />
      <main className="bg-white rounded-lg w-full shadow-sm">
        <LedgerNav ledger={ledger} />
        <section className="p-4 md:p-6">
          {ledger && <DefinitionsPanel ledger={ledger} />}
          <DefinitionList ledger={ledger} />
        </section>
      </main>
    </RouteTransition>
  );
};

export default Definitions;
