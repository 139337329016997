import Select from "react-select";

type MultiSelectProps = {
  name: string;
  placeholder: string;
  value: any;
  isMulti: boolean;
  isClearable: boolean;
  onChange: any;
  backspaceRemovesValue: boolean;
  setActiveFilters: any;
  filters: any;
};

const MultiSelect: React.FunctionComponent<MultiSelectProps> = ({
  name,
  placeholder,
  value,
  isMulti,
  isClearable,
  onChange,
  backspaceRemovesValue,
  setActiveFilters,
  filters,
}: MultiSelectProps) => {
  const handleChange = (value: any) => {
    const currentFilters = value.map((f: any) => f.label);
    setActiveFilters(currentFilters);
    onChange(name, value);
  };

  const mapper = (item: any) => {
    return { label: item, value: item };
  };

  const options = filters.map(mapper);

  return (
    <Select
      placeholder={placeholder}
      options={options}
      value={value}
      onChange={handleChange}
      isMulti={isMulti}
      isClearable={isClearable}
      backspaceRemovesValue={backspaceRemovesValue}
      components={{ ClearIndicator: undefined }}
    />
  );
};

export default MultiSelect;
