import { useState } from "react";
import { Link } from "react-router-dom";

import EntryDynamicForm from "./EntryDynamicForm";
import EntryNewForm from "./EntryNewForm";
import { useQuery } from "@tanstack/react-query";
import { rulesQuery } from "@utils/queries";

type EntryFormSelectionProps = {
  ledger: any;
};

const EntryFormSelection: React.FunctionComponent<EntryFormSelectionProps> = ({
  ledger,
}: EntryFormSelectionProps) => {
  const [definition, setDefinition] = useState<any>(null);

  const handleDefinitionChange = ({ target }: any) => {
    if (target.value === "custom" || target.value === "") {
      setDefinition(target.value);
      return;
    }
    setDefinition(
      ledger.definitions.find(({ id }: any) => id === target.value)
    );
  };

  const { data: rules = [] } = useQuery(
    ["rules", definition?.id],
    () => rulesQuery(definition?.id),
    {
      keepPreviousData: true,
      enabled: !!definition,
      refetchOnWindowFocus: false,
    }
  );
  if (!ledger) return null;

  return (
    <div className="p-5">
      <h3 className="text-lg font-medium leading-6 text-gray-900">
        Create Your Entry Data
      </h3>

      <div className="my-5">
        <select
          onChange={handleDefinitionChange}
          name="definitionSelect"
          className="textfield mb-5"
        >
          <option value="">Select an entry definition to add</option>
          {ledger.definitions.map((def: any) => (
            <option value={def.id} key={def.id}>
              {def.entryName}
            </option>
          ))}
          <option value="custom">Manually Create Entry</option>
        </select>
        <p>
          These events are defined to improve data consistency and ease of use.
          You can still add any custom data by selecting{" "}
          <span>Manually Create Entry</span> or can{" "}
          <Link
            to={`/ledgers/${ledger.id}/definitions/new`}
            className="text-blue-600 hover:text-blue-900"
          >
            create a new definition
          </Link>{" "}
          to support other data.
        </p>
      </div>

      {definition && definition !== "custom" && (
        <EntryDynamicForm
          ledger={ledger}
          definition={definition}
          rules={rules}
        />
      )}

      {definition === "custom" && <EntryNewForm ledger={ledger} />}
    </div>
  );
};

export default EntryFormSelection;
