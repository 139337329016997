import classNames from "classnames";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as IconsChevronRight } from "@images/icon-chevron-right.svg";

type PageHeaderProps = {
  pageTitle: string;
  breadcrumbs?: [string, string | undefined][];
};

const PageHeader: React.FunctionComponent<PageHeaderProps> = ({
  pageTitle,
  breadcrumbs,
}: PageHeaderProps) => {
  const headerClass = classNames(
    "text-3xl md:text-4xl font-semibold tracking-tight text-white",
    {
      "mt-3 mb-6": breadcrumbs,
      "mt-10 mb-8": !breadcrumbs,
    }
  );
  return (
    <>
      {breadcrumbs && (
        <div className="flex items-center text-gray-400 text-sm font-medium mt-6 -ml-2 w-full overflow-auto md:w-auto">
          {breadcrumbs.map(([linkText, linkPath], index) => {
            const breadcrumbsFinished = index >= breadcrumbs.length - 1;
            const sectionClasses = classNames("flex-shrink-0", {
              "px-3 py-2 cursor-default": linkPath,
            });

            return (
              <Fragment key={index}>
                <div className={sectionClasses}>
                  {linkPath && (
                    <Link
                      to={linkPath}
                      className="px-3 py-2 hover:bg-gray-700 rounded hover:text-white"
                    >
                      {linkText}
                    </Link>
                  )}
                  {!linkPath && linkText}
                </div>
                {!breadcrumbsFinished && (
                  <div className="text-gray-500">
                    <IconsChevronRight />
                  </div>
                )}
              </Fragment>
            );
          })}
        </div>
      )}
      <h2 className={headerClass}>{pageTitle}</h2>
    </>
  );
};

export default PageHeader;
