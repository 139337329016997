import ErrorLayout from "@components/layout/ErrorLayout";

const InternalServerError = () => {
  return (
    <ErrorLayout
      statusCode="500"
      title="Internal server error"
      description="The server was unable to complete your request. Please try reloading the page or visiting again later."
    >
      <a href="/">
        Go to dashboard
        <span className="inline-block ml-2" aria-hidden="true">
          &rarr;
        </span>
      </a>
    </ErrorLayout>
  );
};

export default InternalServerError;
