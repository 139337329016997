import ENV from "@config/env";

export const getTransactionUrl = (transactionId: string) => {
  if (!transactionId) return;

  const transactionUrl = ENV.HASHGRAPH_TRANSACTION_URL + "/transactions";

  const [payerAccountId, transaction] = transactionId.split("@");

  return `${transactionUrl}/${payerAccountId}-${transaction
    .split(".")
    .join("-")}`;
};

export const getLedgerTopicUrl = (topicId: string) => {
  if (!topicId) return;

  const topicUrl = ENV.HASHGRAPH_TRANSACTION_URL + "/topics";

  return `${topicUrl}/${topicId}`;
};

export const getHashScanTokenUrl = (tokenId: string) => {
  if (!tokenId) return;

  if (ENV.NODE_ENV === "production")
    return `${ENV.HASHSCAN_URL}/mainnet/token/${tokenId}`;

  return `${ENV.HASHSCAN_URL}/testnet/token/${tokenId}`;
};

export const getHashScanAccountUrl = (accountId: string) => {
  if (!accountId) return;

  if (ENV.NODE_ENV === "production")
    return `${ENV.HASHSCAN_URL}/mainnet/account/${accountId}`;

  return `${ENV.HASHSCAN_URL}/testnet/account/${accountId}`;
};

export const getHashScanTransactionUrl = (transactionId: string) => {
  if (!transactionId) return;

  if (ENV.NODE_ENV === "production")
    return `${ENV.HASHSCAN_URL}/mainnet/transaction/${transactionId}`;

  return `${ENV.HASHSCAN_URL}/testnet/transaction/${transactionId}`;
};
