import { shortDate, shortTime } from "@utils/format";
import { fieldTypes } from "@utils/lookups";
import _ from "lodash";
import { Link } from "react-router-dom";

type DefinitionItemProps = {
  definition: any;
};

const DefinitionItem: React.FunctionComponent<DefinitionItemProps> = ({
  definition,
}: DefinitionItemProps) => {
  if (!definition) return null;
  const fields = _.orderBy(definition?.fields, (f: any) => f.createdAt, "asc");
  return (
    <tr className="text-sm">
      <td className="px-4 py-3 align-top font-medium text-base">
        <Link
          to={`./${definition.id}`}
          className="text-blue-600 hover:text-blue-900"
        >
          {definition.entryName}
        </Link>
      </td>
      <td className="px-4 py-3 align-top">
        <div className="flex flex-col md:flex-row gap-x-2">
          <span className="whitespace-nowrap">
            {shortDate(definition.createdAt)}
          </span>
          <span className="text-gray-500 whitespace-nowrap">
            {shortTime(definition.createdAt)}
          </span>
        </div>
      </td>
      <td className="px-4 py-3 align-top flex flex-col gap-1">
        {fields.map((field: any) => {
          return (
            <div className="text-gray-600 whitespace-nowrap" key={field.id}>
              <span className="text-black">{field.label || field.name}</span>{" "}
              {field.isMultiple
                ? "is multiple values of"
                : `is ${field.type === 10 ? "an" : "a"}`}{" "}
              {fieldTypes[field.type as keyof typeof fieldTypes]}
            </div>
          );
        })}
      </td>
    </tr>
  );
};
export default DefinitionItem;
