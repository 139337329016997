import { Link } from "react-router-dom";
import DocumentationNav from "./DocumentationNav";
import RouteTransition from "../layout/RouteTransition";
import PageHeader from "../layout/PageHeader";

import ManualEntryImage from "../../images/documentation-manual-entry.png";
import DefinedEntryImage from "../../images/documentation-defined-entry.png";

const DocumentationEntries = () => {
  const heading4Class = "text-xl font-semibold mb-4";
  const paragraphClass = "text-gray-700 mb-6";

  return (
    <RouteTransition>
      <PageHeader pageTitle="Documentation" />
      <div className="grid grid-cols-3 gap-6">
        <div className="col-span-3 md:col-span-1">
          <DocumentationNav />
        </div>
        <div className="col-span-3 md:col-span-2">
          <div className="shadow sm:rounded-md">
            <div className="bg-white md:rounded-md overflow-hidden">
              <div className="flex items-center w-full px-4 md:px-6 h-16 md:h-20 border-b border-gray-200">
                <h3 className="text-xl md:text-2xl font-semibold">
                  Getting Started
                </h3>
              </div>
              <article className="p-4 md:p-6">
                <h4 className={heading4Class}>Overview of Entries</h4>

                <p className={paragraphClass}>
                  A ledger is a collection of entries, much like an accounting
                  ledger would be, but able to store a wider range of data
                  including your own custom details.
                </p>

                <p className={paragraphClass}>
                  There are two primary ways to add entries to a leger. One is
                  to use an{" "}
                  <Link to="integration" className="textlink">
                    API Integration
                  </Link>
                  , which is documented extensively on that link. The other is
                  to manually create the entry through the Datasker Web
                  Interfact.
                </p>

                <div className="grid grid-cols-1 gap-3 lg:grid-cols-2 mt-10">
                  <div>
                    <img
                      src={ManualEntryImage}
                      alt="Manual Entry Interface"
                      className="border shadow-lg relative object-cover -left-8"
                    />
                  </div>
                  <div>
                    <h4 className={heading4Class}>Manual Entry Creation</h4>

                    <p className={paragraphClass}>
                      Manual Entry Creation is suitable for occasional or
                      un-automated tasks, which are done infrequently. If the
                      data is a one-off and is of relatively minor importance,
                      it might be appropriate to create a completely ad-hoc
                      entry. After going to the Create New Entry page you can
                      select "Manually Create Entry".
                    </p>
                    <p className={paragraphClass}>
                      After entering your own custom Entry Name, you can add a
                      name and value for each data field you wish to store.
                    </p>
                  </div>
                </div>

                <div className="grid grid-cols-1 gap-3 lg:grid-cols-2 mt-10">
                  <div>
                    <h4 className={heading4Class}>Defined Entry Creation</h4>

                    <p className={paragraphClass}>
                      An easier and more consistent way to enter data via form
                      is to use an Entry Definition. Defining your entries makes
                      the data more consistent and structured but it also helps
                      you with data entry. For regularly used or high value data
                      we strongly recommend using Entry Definitions.
                    </p>
                    <p className={paragraphClass}>
                      When you have a defined entry you can select it from the
                      "Create Your Entry Data" dropdown. This will then create a
                      form, matching your definition details. It customises the
                      layout and inputs to the fields, facilitating (for
                      example) entry of dates or even uploading of documents.
                      Additionally, it also converts your entry rules into form
                      validation.
                    </p>
                  </div>
                  <div>
                    <img
                      src={DefinedEntryImage}
                      alt="Defined Entry Interface"
                      className="border shadow-lg relative object-cover left-10"
                    />
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </RouteTransition>
  );
};

export default DocumentationEntries;
