import React, { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import classNames from "classnames";

const definitionOptions = [
  {
    title: "No Enforcement",
    description: "Any data sent to your ledger is accepted and stored.",
    current: true,
    id: 1,
  },
  {
    title: "Partial Enforcement",
    description:
      "Require validation on specific Entry types, but don't reject unknown data.",
    current: false,
    id: 2,
  },
  {
    title: "Strict Enforcement",
    description:
      "Only allow pre-defined and validated data, reject any undefined events.",
    current: false,
    id: 3,
  },
];

type LedgerFormDefinitionSelectProps = {
  preset: number;
  update: (value: any) => void;
  saving: boolean;
};

const LedgerFormDefinitionSelect: React.FunctionComponent<
  LedgerFormDefinitionSelectProps
> = ({ preset, update }: LedgerFormDefinitionSelectProps) => {
  const defaultOption =
    definitionOptions.find((def) => def.id === preset) || definitionOptions[0];
  const [selected, setSelected] = useState(defaultOption);
  return (
    <Listbox
      value={selected}
      onChange={(option) => {
        update(option.id);
        setSelected(option);
      }}
    >
      {({ open }) => (
        <>
          <Listbox.Label className="sr-only">
            Change Ledger Definition
          </Listbox.Label>
          <div className="relative">
            <div className="flex w-full rounded-sm">
              <div className="relative flex w-full rounded-sm">
                <div className="relative flex w-full items-center py-2 pl-3 pr-4 border border-gray-300 border-r-0 rounded-l-sm text-gray-600">
                  <i className="fal fa-check fa-fw"></i>
                  <p className="ml-2.5 text-sm font-medium">{selected.title}</p>
                </div>
                <Listbox.Button className="relative inline-flex items-center bg-gray-100 p-2 rounded-l-none border border-gray-300 border-l-0 rounded-r-sm text-sm font-medium text-gray-600 hover:bg-gray-200 focus:outline-none focus:z-10 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-blue-500">
                  <span className="sr-only">Change Ledger Definition</span>
                  <i className="fal fa-chevron-down fa-fw"></i>
                </Listbox.Button>
              </div>
            </div>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                static
                className="origin-top-right absolute right-0 mt-2 w-full rounded-sm shadow-lg overflow-hidden bg-white divide-y divide-gray-200 ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                {definitionOptions.map((option) => (
                  <Listbox.Option
                    key={option.title}
                    className={({ active }) =>
                      classNames(
                        active ? "bg-blue-100" : "text-gray-900",
                        "cursor-default select-none relative p-4 text-sm"
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <div className="flex flex-col">
                        <div className="flex justify-between">
                          <p
                            className={
                              selected ? "font-semibold" : "font-normal"
                            }
                          >
                            {option.title}
                          </p>
                          {selected ? (
                            <span
                              className={
                                active ? "text-gray-900" : "text-gray-700"
                              }
                            >
                              <i className="fal fa-check fa-fw"></i>
                            </span>
                          ) : null}
                        </div>
                        <p
                          className={`mt-2 ${
                            active ? "text-gray-700" : "text-gray-600"
                          }`}
                        >
                          {option.description}
                        </p>
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default LedgerFormDefinitionSelect;
