import NavItem from "../layout/NavItem";

import { ReactComponent as IconPuzzle } from "../../images/icon-puzzle.svg";
import { ReactComponent as IconVariable } from "../../images/icon-variable.svg";
import { ReactComponent as IconGrid } from "../../images/icon-grid.svg";
import { ReactComponent as IconTemplate } from "../../images/icon-template.svg";
import { ReactComponent as IconSparkles } from "../../images/icon-sparkles.svg";
import { ReactComponent as IconCollection } from "../../images/icon-collection.svg";

const DocumentationNav = () => {
  const navLinkClass =
    "flex gap-x-4 items-center p-2 text-sm font-semibold text-gray-800 rounded-lg opacity-50 hover:opacity-100";
  const navLinkActiveClass = "bg-gray-100 font-semibold opacity-100";

  return (
    <div className="col-span-1 bg-white shadow overflow-hidden border-gray-200 rounded-lg z-20 p-3 flex flex-col gap-y-1">
      <NavItem
        to="/documentation"
        exact
        activeClassName={navLinkActiveClass}
        className={navLinkClass}
      >
        <span className="text-gray-500">
          <IconSparkles />
        </span>
        <span>Getting Started</span>
      </NavItem>
      <NavItem
        to="/documentation/ledgers"
        activeClassName={navLinkActiveClass}
        className={navLinkClass}
      >
        <span className="text-gray-500">
          <IconCollection />
        </span>
        <span>Working with Ledgers</span>
      </NavItem>
      <NavItem
        to="/documentation/definitions"
        activeClassName={navLinkActiveClass}
        className={navLinkClass}
      >
        <span className="text-gray-500">
          <IconTemplate />
        </span>
        <span>Data Definitions</span>
      </NavItem>
      <NavItem
        to="/documentation/rules"
        activeClassName={navLinkActiveClass}
        className={navLinkClass}
      >
        <span className="text-gray-500">
          <IconVariable />
        </span>
        <span>Entry Rules</span>
      </NavItem>
      <NavItem
        to="/documentation/integration"
        activeClassName={navLinkActiveClass}
        className={navLinkClass}
      >
        <span className="text-gray-500">
          <IconPuzzle />
        </span>
        <span>API Integration</span>
      </NavItem>
      <NavItem
        to="/documentation/entries"
        activeClassName={navLinkActiveClass}
        className={navLinkClass}
      >
        <span className="text-gray-500">
          <IconGrid />
        </span>
        <span>Ledger Entries</span>
      </NavItem>
    </div>
  );
};

export default DocumentationNav;
