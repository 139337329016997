export const fieldTypes = {
  1: "String",
  2: "Number",
  3: "DateTime",
  4: "Boolean",
  5: "Date",
  6: "Document",
  7: "GeoLocation",
  8: "List",
  9: "Percentage",
  10: "Hash",
  11: "Data Definition",
};

export const ruleTypes = {
  1: "is required",
  2: "is longer than",
  3: "is shorter than",
  4: "is after",
  5: "is before",
  6: "is greater than",
  7: "is smaller than",
  8: "equals",
  9: "doesn't equal",
  10: "is true",
  11: "is false",
};

export const ruleTypeConditions = {
  1: "is required",
  2: "must be longer than",
  3: "must be shorter than",
  4: "must be after",
  5: "must be before",
  6: "must be greater than",
  7: "must be smaller than",
  8: "must equal",
  9: "must not equal",
  10: "must be true",
  11: "must be false",
};

export const ledgerPlatforms = {
  1: "Hashgraph",
  2: "Ethereum",
  3: "Daml",
};

export const enforcementModes = {
  1: "No Enforcement",
  2: "Partial Enforcement",
  3: "Strict Enforcement",
};
