import { useContext } from "react";

import Stats from "./dashboard/Stats";
import RouteTransition from "./layout/RouteTransition";
import PageHeader from "./layout/PageHeader";
import DashboardLedgers from "./dashboard/DashboardLedgers";
import { useQuery } from "@tanstack/react-query";
import { entriesQuery, ledgersQuery } from "@utils/queries";
import { LoginContext } from "@utils/reducers";

const Dashboard = () => {
  const { data: ledgers = [] } = useQuery(["ledgers"], ledgersQuery);
  const { data: entries = [] } = useQuery(["entries"], entriesQuery);
  const {
    login: { user },
  } = useContext(LoginContext);

  if (!user) return null;

  return (
    <RouteTransition>
      <PageHeader pageTitle={user.company.name} />

      <Stats entries={entries} ledgers={ledgers} />
      <div className="my-24">
        <DashboardLedgers ledgers={ledgers} />
      </div>
    </RouteTransition>
  );
};
export default Dashboard;
