import NotificationNav from "./NotificationNav";
import RouteTransition from "../layout/RouteTransition";
import PageHeader from "../layout/PageHeader";

const Notification = () => {
  return (
    <RouteTransition>
      <PageHeader pageTitle="Notifications" />
      <div className="grid grid-cols-3 gap-6">
        <div className="col-span-3 md:col-span-1">
          <NotificationNav />
        </div>
        <div className="col-span-3 md:col-span-2">
          <div className="shadow sm:rounded-md">
            <div className="bg-white md:rounded-md overflow-hidden">
              <div className="flex items-center w-full px-4 md:px-6 h-16 md:h-20 border-b border-gray-200">
                <h3 className="text-xl md:text-2xl font-semibold">Inbox</h3>
              </div>
              <div className="p-4 md:p-6">
                <div className="text-center px-4 py-24 md:py-44 text-gray-400">
                  You have no new messages in your Inbox at the moment
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </RouteTransition>
  );
};

export default Notification;
