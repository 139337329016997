import RouteTransition from "./layout/RouteTransition";
import PageHeader from "./layout/PageHeader";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { statisticsQuery } from "@utils/queries";
import Loading from "./shared/Loading";
import {
  getHashScanTokenUrl,
  getHashScanAccountUrl,
} from "@utils/hashgraphUtils";
import ScrollToTopButton from "./icons/ScrollToTopButton";
import { formatNumberWithCommas } from "@utils/format";

const Statistics = () => {
  const { id = "" } = useParams();
  let { data, isLoading, isFetching } = useQuery(
    ["ledger", id],
    () => statisticsQuery(id),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  if (isLoading || isFetching) {
    return <Loading />;
  }

  const pageTitle =
    "Textile Feedstock Provider TraceTexx Digital Badge Register"; // "Statistics for " + data?.companyName + " - " + data?.tokenName;
  const totalVolume = data?.entries.reduce(
    (acc: number, entry: any) => acc + entry.volume,
    0
  );

  return (
    <RouteTransition>
      <PageHeader pageTitle={pageTitle} />
      <main className="flex flex-col lg:flex-row gap-6">
        <section className="bg-white rounded-lg w-full shadow-sm">
          <div className="flex w-full justify-between px-4 md:px-6 h-16 md:h-20 items-center border-b border-gray-200">
            <h3 className="text-xl md:text-2xl font-semibold">
              Total Volume ({data?.entries[0]?.unit}):{" "}
              {formatNumberWithCommas(totalVolume)}
            </h3>
            <h3 className="text-xl md:text-2xl font-semibold text-blue-500 pr-10">
              <a
                href={`${getHashScanTokenUrl(data?.tokenId)}`}
                target="_blank"
                rel="noreferrer"
                className="hover:text-blue-700 hover:font-bold"
                title="Track on HashScan"
              >
                Token ID: {data?.tokenId}
              </a>
            </h3>
          </div>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-100">
              <thead className="bg-gray-100">
                <tr className="text-lg font-medium text-gray-700 uppercase tracking-wider">
                  <th scope="col" className="px-6 py-3 text-left ">
                    Name
                  </th>
                  <th scope="col" className="px-6 py-3 text-center ">
                    Wallet ID
                  </th>
                  <th scope="col" className="px-6 py-3 text-center ">
                    Total Volume
                  </th>
                  <th scope="col" className="px-6 py-3 text-center ">
                    Unit
                  </th>
                  <th scope="col" className="px-6 py-3 text-center ">
                    Badge Level
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-100">
                {data?.entries?.map((entry: any, index: number) => (
                  <tr
                    key={index}
                    className={index % 2 === 1 ? "bg-gray-50" : "bg-white"}
                  >
                    <td className="px-6 py-4 whitespace-nowrap text-base font-medium text-gray-700">
                      {entry.name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-base  text-center text-blue-500">
                      <a
                        href={`${getHashScanAccountUrl(entry.walletId)}`}
                        target="_blank"
                        rel="noreferrer"
                        className="hover:text-blue-700 hover:font-bold "
                        title="Track on HashScan"
                      >
                        {entry.walletId}
                      </a>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-base text-gray-500 text-center">
                      {formatNumberWithCommas(entry.volume)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-base text-gray-500 text-center">
                      {entry.unit}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-base font-medium text-gray-500 text-center">
                      {entry.badgeLevel}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </section>
        <ScrollToTopButton />
      </main>
    </RouteTransition>
  );
};

export default Statistics;
