import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Field, ErrorMessage, Form } from "formik";
import * as Yup from "yup";
import EntryService from "../../services/EntryService";
import FormSuccess from "../layout/FormSuccess";
import FormError from "../layout/FormError";
import Modal from "@components/layout/Modal";
import Loading from "@components/shared/Loading";

const entryService = new EntryService();

const EntryNewForm = ({ ledger }: any) => {
  const [eventData, setEventData] = useState<any>([]);
  const [eventDataError, setEventDataError] = useState<any>("");
  const [success, setSuccess] = useState(false);
  const [formErrors, setFormErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const validate = Yup.object({
    name: Yup.string().required("The entry name is required"),
  });

  const handleAddEventData = (newData: any) => {
    if (!newData.key || !newData.value) {
      return false;
    }
    if (eventData.find((item: any) => item.key === newData.key)) {
      setEventDataError("You must enter unique names for your data values");
      return false;
    }
    setEventData([...eventData, newData]);
    return true;
  };

  return (
    <Formik
      initialValues={{
        name: "",
        entityId: "",
        document: "",
        eventDataKey: "",
        eventDataValue: "",
        ledgerId: "",
        event: {},
      }}
      validationSchema={validate}
      onSubmit={async (values, actions) => {
        setIsLoading(true);
        actions.setSubmitting(true);
        const event = eventData.reduce(
          (accumulator: any, { key, value }: any) => {
            accumulator[key] = value;
            return accumulator;
          },
          {}
        );
        let { eventDataKey, eventDataValue, ...postData } = values;
        postData = { ...postData, ledgerId: ledger.id, event };

        entryService
          .create(postData)
          .then(({ data }) => {
            setSuccess(true);
            actions.resetForm();
            setTimeout(() => {
              navigate(`/entries/${data.id}`);
            }, 3000);
          })
          .catch(({ response }) => {
            const errors = response.data.errors.reduce(
              (accumulator: any, error: any) => {
                if (error.field) {
                  accumulator.push(`${error.field}: ${error.message}`);
                }
                return accumulator;
              },
              []
            );
            setFormErrors(errors);
          })
          .finally(() => {
            actions.setSubmitting(false);
            setIsLoading(false);
          });
      }}
    >
      {(props) => (
        <>
          <FormError
            show={formErrors.length > 0}
            errors={formErrors}
            title="Unable to save entry - errors occurred"
          />

          <FormSuccess show={success}>
            {" "}
            Entry has been saved. Sending you to your new entry details.
          </FormSuccess>
          <Form>
            <div className="mb-5">
              <h4 className="text-lg text-gray-500 mb-3">
                Primary Entry Details
              </h4>

              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-6">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Entry Name
                  </label>
                  <Field
                    name="name"
                    placeholder="Required"
                    className="textfield w-full"
                  />
                  <ErrorMessage
                    component="p"
                    className="sm:text-sm text-red-600"
                    name="name"
                  />
                </div>
              </div>
            </div>
            <div className="">
              <h4 className="text-lg text-gray-500">Add Fields</h4>

              <div className="grid grid-cols-7 gap-4 mt-3">
                <div className="col-span-7 sm:col-span-2">
                  <label
                    htmlFor="eventDataKey"
                    className="block text-sm font-medium text-gray-700"
                  ></label>
                  <Field
                    name="eventDataKey"
                    placeholder="Both fields required"
                    className="p-2 focus:ring-blue-500 focus:border-blue-500 flex-1 block w-full rounded-sm sm:text-sm border border-gray-300"
                  />
                </div>
                <div className="col-span-7 sm:col-span-4">
                  <label
                    htmlFor="eventDataValue"
                    className="block text-sm font-medium text-gray-700"
                  ></label>
                  <Field
                    name="eventDataValue"
                    placeholder="Both fields required"
                    className="p-2 focus:ring-blue-500 focus:border-blue-500 flex-1 block w-full rounded-sm sm:text-sm border border-gray-300"
                  />
                </div>
                <div className="col-span-7 sm:col-span-1 relative">
                  <button
                    className="bg-blue-500 text-white rounded-sm w-full h-full"
                    onClick={(event) => {
                      event.preventDefault();
                      setEventDataError(false);

                      const handled = handleAddEventData({
                        key: props.values.eventDataKey,
                        value: props.values.eventDataValue,
                      });

                      if (handled) {
                        props.setFieldValue("eventDataKey", "");
                        props.setFieldValue("eventDataValue", "");
                      }
                    }}
                  >
                    <i className="fal fa-plus mr-2"></i>add
                  </button>
                </div>
                {eventDataError && (
                  <p className="col-span-7 ml-6 mb-5 text-xs text-red-500 text-center">
                    {eventDataError}
                  </p>
                )}
              </div>

              <dl className="my-3 bg-gray-100 border rounded">
                {eventData.map((item: any, index: any) => {
                  return (
                    <div key={index} className="grid grid-cols-4 gap-4">
                      <dt className="px-3 py-1 font-semibold">{item.key}</dt>
                      <dd className="col-span-3 px-3 py-1">{item.value}</dd>
                    </div>
                  );
                })}
                {!eventData.length && (
                  <div className="p-3 text-sm text-gray-500">
                    No fields sent with this entry
                  </div>
                )}
              </dl>
            </div>

            <div className="text-right">
              <button
                type="submit"
                className="justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <i className="fal fa-cloud-upload mr-2"></i>
                Save
              </button>
            </div>
          </Form>
          <Modal open={isLoading}>
            <div className="flex flex-col">
              <div className="border-b p-4">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Saving Entry
                </h3>
              </div>
              <div className="p-4 space-y-5">
                <Loading />
              </div>
            </div>
          </Modal>
        </>
      )}
    </Formik>
  );
};

export default EntryNewForm;
