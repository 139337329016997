const Footer = () => {
  return (
    <footer className="" aria-labelledby="footerHeading">
      <h2 id="footerHeading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-7xl mx-auto pb-8">
        <div className="pt-12 md:flex justify-end">
          <p className="mt-8 text-sm text-gray-400 md:mt-0 md:order-1">
            © 2024 Civic Ledger Pty Ltd. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
