import { Link } from "react-router-dom";

import EntryEvents from "./EntryEvents";
import { shortDate, shortTime } from "@utils/format";

type EntryItemProps = {
  entry: any;
  ledger: any;
};

const EntryItem: React.FunctionComponent<EntryItemProps> = ({
  entry,
  ledger,
}: EntryItemProps) => {
  const definition = ledger.definitions.find((definition: any) => {
    return definition.entryName === entry.name;
  });

  return (
    <tr>
      <td className="px-4 py-2 md:px-6 md:py-4 md:whitespace-nowrap align-top">
        <div className="flex items-center">
          <Link
            to={`/entries/${entry.id}`}
            className="max-w-xs md:max-w-none text-sm md:text-base text-blue-600 hover:text-blue-900"
          >
            {entry.name}
          </Link>
        </div>
      </td>
      <td
        className="px-4 py-0 md:px-6 md:py-2  align-top"
        style={{ minWidth: "240px" }}
      >
        <dl>{<EntryEvents entry={entry} definition={definition} />}</dl>
      </td>
      <td className="px-4 py-2 md:px-6 md:py-4 whitespace-nowrap align-top">
        <div className="text-sm text-gray-900">
          {shortDate(entry.createdAt)}
        </div>
        <div className="text-sm text-gray-500">
          {shortTime(entry.createdAt)}
        </div>
      </td>
    </tr>
  );
};
export default EntryItem;
