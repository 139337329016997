import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import { Formik, Field, ErrorMessage, Form } from "formik";
import * as Yup from "yup";
import ShowOptionsButton from "@components/layout/ShowOptions";
import DefinitionService from "../../services/DefinitionService";

import FormSuccess from "../layout/FormSuccess";
import FormError from "../layout/FormError";
import { fieldTypes } from "@utils/lookups";

const definitionService = new DefinitionService();

const EntryNewForm = () => {
  const { id: ledgerId } = useParams();
  const [dataFields, setDataFields] = useState<any[]>([]);
  const [dataFieldsError, setDataFieldsError] = useState<any>("");

  const [success, setSuccess] = useState<boolean | null>(null);
  const [formErrors, setFormErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [setValueErrors, setSetValueErrors] = useState<any>([]);

  const navigate = useNavigate();

  const validate = Yup.object({
    entryName: Yup.string().required("The entry name is required"),
  });

  const handleAddDataField = (newField: any) => {
    setSetValueErrors("");
    if (!newField.name || !newField.type) {
      return false;
    }
    if (Number(newField.type) === 8 && !newField.value) {
      setSetValueErrors("Please enter at least one option for list");
      return false;
    }

    setDataFields([...dataFields, newField]);
    return true;
  };

  const handleDeleteField = (indexToDelete: number) => {
    const newDataFields = dataFields.filter(
      (_, index) => index !== indexToDelete
    );
    setDataFields(newDataFields);
  };

  return (
    <Formik
      initialValues={{
        entryName: "",
        fieldName: "",
        fieldLabel: "",
        fieldType: 1,
        fieldValue: undefined,
        isMultiple: false,
      }}
      validationSchema={validate}
      onSubmit={async (values, actions) => {
        setIsLoading(true);
        actions.setSubmitting(true);
        const {
          fieldName,
          fieldType,
          fieldLabel,
          fieldValue,
          isMultiple,
          ...data
        } = values;

        definitionService
          .create({ ...data, ledgerId, fields: dataFields })
          .then((response) => {
            setSuccess(true);
            actions.resetForm();
            setTimeout(() => {
              navigate(`/ledgers/${ledgerId}/definitions/${response.data.id}`);
            }, 3000);
          })
          .catch(({ response }) => {
            const errors = response.data.errors.reduce(
              (accumulator: any, error: any) => {
                if (error.field) {
                  accumulator.push(`${error.field}: ${error.message}`);
                }
                return accumulator;
              },
              []
            );
            setFormErrors(errors);
          })
          .finally(() => {
            actions.setSubmitting(false);
            setIsLoading(false);
          });
      }}
    >
      {(props) => (
        <>
          <FormError
            show={formErrors.length > 0}
            errors={formErrors}
            title="Unable to save Definition - errors occurred"
          />

          <FormSuccess show={!!success}>
            Definition has been saved. Sending you to your new Definition.
          </FormSuccess>

          <Form className="shadow sm:rounded-md sm:overflow-hidden">
            <div className="p-6 bg-white">
              <h4 className="text-lg text-gray-500 mb-3">Entry Definition</h4>
              <div className="grid grid-cols-7 gap-6">
                <div className="col-span-7 sm:col-span-7">
                  <label
                    htmlFor="entryName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Entry Name
                  </label>
                  <Field
                    name="entryName"
                    placeholder="eg. TransactionSent or Contract Signed"
                    className="my-2 w-full textfield"
                  />
                  <p className="mt-1 text-xs text-gray-600">
                    Note that this must be exact:{" "}
                    <span className="p-1 bg-gray-50">NewCustomer</span> will not
                    match <span className="p-1 bg-gray-50">New Customer</span>{" "}
                    or <span className="p-1 bg-gray-50">newCustomer</span>.
                  </p>
                  <ErrorMessage
                    component="p"
                    className="sm:text-sm text-red-600"
                    name="entryName"
                  />
                </div>
              </div>

              <h4 className="text-lg text-gray-500 mb-3 mt-10">Entry Fields</h4>

              <p className="pb-5 text-sm">
                Add the fields that you expect to store with your entry data,
                including the type, an optional label.
              </p>

              <div className="border rounded-sm mb-10">
                {dataFields.length > 0 && (
                  <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200 text-sm text-left">
                      <thead className="bg-gray-300">
                        <tr className="font-semibold">
                          <th scope="col" className="px-3 py-1  break-words">
                            Field Name
                          </th>
                          <th scope="col" className="px-3 py-1  break-words">
                            Label
                          </th>
                          <th scope="col" className="px-3 py-1  break-words">
                            Data Type
                          </th>
                          <th scope="col" className="px-3 py-1  break-words">
                            Options
                          </th>
                          <th scope="col" className="px-3 py-1  "></th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataFields.map((field, index) => {
                          return (
                            <tr
                              key={index}
                              className={`px-4 py-3 whitespace-nowrap group h-12 ${
                                index % 2 === 0 ? "bg-white" : "bg-gray-200"
                              }`}
                            >
                              <td className="px-3 py-1 break-words">
                                {field.name}
                              </td>
                              <td className="px-3 py-1 break-words">
                                {field.label}
                              </td>
                              <td className="px-3 py-1 break-words">
                                {
                                  fieldTypes[
                                    field.type as keyof typeof fieldTypes
                                  ]
                                }{" "}
                                {field.isMultiple && "(Multiple Values)"}
                              </td>
                              <td className="px-3 py-2 break-words">
                                <ShowOptionsButton
                                  type={field.type}
                                  value={field.value}
                                />
                              </td>
                              <td className="px-3 py-1  opacity-100 lg:opacity-0 lg:group-hover:opacity-100 text-right">
                                <div
                                  className=" inline-block text-red-500 underline hover:text-red-700 mr-5"
                                  onClick={() => handleDeleteField(index)}
                                >
                                  <i className="fal fa-trash fa-fw text-base text-gray-500 rounded-full cursor-pointer ml-7 hover:bg-slate-400" />
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}

                {!dataFields.length && (
                  <div className="p-3 text-sm text-gray-500">
                    No data definition with this entry
                  </div>
                )}
              </div>

              <div className="grid grid-cols-7 gap-4 gap-y-1 mt-3">
                <div className="hidden sm:order-4 sm:inline-block"></div>
                <label
                  htmlFor="fieldName"
                  className="col-span-7 sm:col-span-2 sm:order-1 text-sm font-medium text-gray-700"
                >
                  Data Field
                </label>
                <Field
                  name="fieldName"
                  placeholder="eg. customerId"
                  className="col-span-7 sm:col-span-2 sm:order-5 textfield"
                />
                <label
                  htmlFor="fieldLabel"
                  className="col-span-7 sm:col-span-2 sm:order-2 text-sm font-medium text-gray-700"
                >
                  Label
                </label>
                <input
                  name="fieldLabel"
                  value={
                    props.values.fieldLabel
                      ? props.values.fieldLabel
                      : props.values.fieldName
                  }
                  onChange={(e) =>
                    props.setFieldValue("fieldLabel", e.target.value)
                  }
                  placeholder="eg. Customer ID"
                  className="col-span-7 sm:col-span-2 sm:order-6 textfield"
                />
                <label
                  htmlFor="fieldType"
                  className="col-span-7 sm:col-span-2 sm:order-3 text-sm font-medium text-gray-700"
                >
                  Type
                </label>
                <Field
                  name="fieldType"
                  as="select"
                  className="col-span-7 sm:col-span-2 sm:order-7 textfield"
                >
                  {Object.entries(fieldTypes).map(([value, name]) => {
                    return (
                      <option key={value} value={value}>
                        {name}
                      </option>
                    );
                  })}
                </Field>

                {Number(props.values.fieldType) === 8 && (
                  <>
                    <div className="hidden col-span-4 sm:order-9 sm:inline-block"></div>

                    <label
                      htmlFor="fieldType"
                      className="col-span-7 sm:col-span-2 sm:order-10 text-sm font-medium text-gray-700"
                    >
                      Values
                    </label>
                    <div className="col-span-4 hidden sm:order-10 sm:inline-block"></div>

                    <p className="col-span-7 ml-6 mb-5 text-xs text-red-500 text-center">
                      {setValueErrors}
                    </p>
                    <CreatableSelect
                      name="fieldValue"
                      className="col-span-7 sm:col-span-2 sm:order-10"
                      placeholder="Enter list values"
                      onChange={(value) => {
                        const currentValues = value
                          .map((f: any) => f.label)
                          .join(",");
                        props.setFieldValue("fieldValue", currentValues);
                      }}
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                      isMulti
                    />
                  </>
                )}

                {Number(props.values.fieldType) === 11 && (
                  <>
                    <div className="hidden col-span-4 sm:order-9 sm:inline-block"></div>
                    <label
                      htmlFor="isMultiple"
                      className="col-span-7 sm:col-span-1 sm:order-10 text-sm font-medium text-gray-700"
                    >
                      Multiple Values
                    </label>
                    <div className="col-span-4 hidden sm:order-10 sm:inline-block"></div>
                    <div className="col-span-7 sm:col-span-1 sm:order-10">
                      <Field name="isMultiple" type="checkbox" />
                    </div>
                  </>
                )}
                <div className="col-span-7 sm:col-span-1 sm:order-8 relative">
                  <button
                    className="bg-blue-500 text-white rounded-sm w-full h-full"
                    onClick={(event) => {
                      event.preventDefault();

                      setDataFieldsError(false);
                      const handled = handleAddDataField({
                        name: props.values.fieldName,
                        label: props.values.fieldLabel,
                        type: props.values.fieldType,
                        value: props.values.fieldValue,
                        isMultiple: props.values.isMultiple,
                      });

                      if (handled) {
                        props.setFieldValue("fieldName", "");
                        props.setFieldValue("fieldLabel", "");
                        props.setFieldValue("fieldType", 1);
                        props.setFieldValue("fieldValue", undefined);
                        props.setFieldValue("isMultiple", false);
                      }
                    }}
                  >
                    <i className="fal fa-plus mr-2"></i>add
                  </button>
                </div>
                {dataFieldsError && (
                  <p className="col-span-7 ml-6 mb-5 text-xs text-red-500 text-center">
                    {dataFieldsError}
                  </p>
                )}
              </div>
            </div>

            {!isLoading && (
              <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                <button
                  type="submit"
                  className="justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  <i className="fal fa-cloud-upload mr-2"></i>
                  Create New Definition
                </button>
              </div>
            )}
            {isLoading && (
              <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                <button
                  disabled={true}
                  className="justify-center cursor-not-allowed opacity-30 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  <i className="fal fa-cloud-upload mr-2"></i>
                  Create New Definition
                </button>
              </div>
            )}
          </Form>
        </>
      )}
    </Formik>
  );
};

export default EntryNewForm;
