import { useState } from "react";

import UserService from "../../services/UserService";
const userService = new UserService();

const Logout = () => {
  const [confirmLogout, setConfirmLogout] = useState(false);

  const logout = () => {
    if (confirmLogout) {
      userService.logOut();
      window.location.href = "/";
    } else {
      setConfirmLogout(true);
    }
  };
  return (
    <div className="mt-3 flex justify-center gap-x-2">
      <button
        className="flex items-center py-2 px-3 border border-gray-300 shadow-sm text-sm font-semibold rounded-md text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        onClick={logout}
      >
        <i className="fal fa-sign-out mr-2"></i>
        {confirmLogout ? "Confirm Log Out" : "Log Out"}
      </button>
      {confirmLogout && (
        <button
          className="flex items-center py-2 px-3 border border-gray-300 shadow-sm text-sm font-semibold rounded-md text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          onClick={() => setConfirmLogout(false)}
        >
          <i className="fal fa-window-close"></i>
        </button>
      )}
    </div>
  );
};

export default Logout;
