import React from "react";

const NotificationIcon: React.FunctionComponent = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 23 23"
    >
      <path
        fill="#ffffff"
        d="M11.5 23a2.875 2.875 0 002.875-2.875h-5.75A2.875 2.875 0 0011.5 23zM11.5 2.757l-1.146.232A5.752 5.752 0 005.75 8.625c0 .902-.193 3.159-.66 5.38-.231 1.102-.541 2.25-.953 3.245h14.726c-.412-.995-.722-2.143-.953-3.246-.467-2.22-.66-4.477-.66-5.379a5.752 5.752 0 00-4.604-5.636L11.5 2.757zm8.94 14.493c.321.643.693 1.151 1.122 1.438H1.438c.43-.287.802-.795 1.123-1.438 1.291-2.588 1.753-7.361 1.753-8.625a7.19 7.19 0 015.756-7.045 1.438 1.438 0 112.861 0 7.19 7.19 0 015.758 7.045c0 1.264.46 6.037 1.752 8.625z"
      ></path>
    </svg>
  );
};

export default NotificationIcon;
