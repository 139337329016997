import ErrorLayout from "@components/layout/ErrorLayout";

const NetworkError = () => {
  return (
    <ErrorLayout
      title="Network error"
      description="A network error has occurred, please check your internet connection and try again"
    >
      <a
        href="/"
        className="inline-block btn-secondary text-sm rounded hover:underline hover:text-blue-500"
      >
        Go to dashboard
        <span className="inline-block ml-2" aria-hidden="true">
          &rarr;
        </span>
      </a>
    </ErrorLayout>
  );
};

export default NetworkError;
