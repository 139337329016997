import { useState, useEffect } from "react";

import { useParams } from "react-router-dom";
import { isWithinInterval, isBefore } from "date-fns";

import EntriesPanel from "./ledger/EntriesPanel";
import LedgerNav from "./ledger/LedgerNav";
import EntryList from "./entry/EntryList";
import EntryFilterOptions from "./entry/EntryFilterOptions";
import RouteTransition from "./layout/RouteTransition";
import PageHeader from "./layout/PageHeader";
import { useQuery } from "@tanstack/react-query";
import { ledgerQuery } from "@utils/queries";
import Modal from "@components/layout/Modal";
import Loading from "@components/shared/Loading";
import _ from "lodash";
import ScrollToTopButton from "./icons/ScrollToTopButton";
import { useContext } from "react";
import { LoginContext } from "@utils/reducers";

const Entries = () => {
  const { id = "" } = useParams();
  const {
    login: { user },
  } = useContext(LoginContext);

  let {
    data: ledger = { entries: [] },
    isLoading,
    isFetching,
  } = useQuery(["ledger", id], () => ledgerQuery(id), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  if (!ledger) ledger = { name: "Not Found", entries: [] };

  const [activeFilters, setActiveFilters] = useState<any>([]);
  const [filters, setFilters] = useState<any>([]);
  const [filterByDate, setFilterByDate] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    const getData = async () => {
      const entryNames = new Set(ledger.entries.map(({ name }: any) => name));
      if (!_.isEqual([...entryNames], activeFilters)) {
        setActiveFilters([...entryNames]);
        setFilters([...entryNames]);
      }
    };
    getData();
  }, [activeFilters, ledger]);

  if (isLoading || isFetching) {
    return (
      <Modal open={isLoading || isFetching}>
        <div className="flex flex-col">
          <div className="border-b p-4">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Loading Entries
            </h3>
          </div>
          <div className="p-4 space-y-5">
            <Loading />
          </div>
        </div>
      </Modal>
    );
  }

  let filteredEntries = ledger.entries.filter(({ name, createdAt }: any) => {
    if (filterByDate) {
      return (
        activeFilters.includes(name) &&
        isBefore(startDate, endDate) &&
        isWithinInterval(createdAt, {
          start: startDate,
          end: endDate,
        })
      );
    } else {
      return activeFilters.includes(name);
    }
  });

  return (
    <RouteTransition>
      <PageHeader
        pageTitle={ledger.deletedAt ? `${ledger.name} (Archived)` : ledger.name}
        breadcrumbs={[
          ["All Ledgers", "/ledgers/"],
          [ledger.name, undefined],
        ]}
      />
      <main className="bg-white rounded-lg w-full shadow-sm">
        <LedgerNav ledger={ledger} />
        <section className="p-4 md:p-6">
          <div className="max-w-2xl mx-auto my-6">
            {ledger && <EntriesPanel ledger={ledger} />}

            <div className="my-10">
              <h3 className="text-xl font-semibold mt-12 mb-4">
                Filter Entries
              </h3>
              <p className="mt-1 text-sm text-gray-600">
                Select filter options to display Entries for this Ledger.
              </p>
            </div>
            <EntryFilterOptions
              filters={filters}
              activeFilters={activeFilters}
              setActiveFilters={setActiveFilters}
              filterByDate={filterByDate}
              setFilterByDate={setFilterByDate}
              startDate={startDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              endDate={endDate}
            />
          </div>
          <div className="-mx-6 mt-12 px-6 pt-12 border-t border-gray-200">
            <div className="px-4 sm:px-0">
              <div className="flex justify-between items-center">
                <h3 className="text-xl font-semibold">
                  Entries for this Ledger
                </h3>
                {["BlockTexx", "Datasker"].includes(user.company.name) &&
                  ledger.name ===
                    'Ledger One: Textile Feedstock Received "Goods-In"' && (
                    <a
                      href={`/statistics/${id}`}
                      target="_blank"
                      rel="noreferrer"
                      className="hover:text-blue-700 hover:font- text-blue-500 font-bold"
                    >
                      See TraceTexx Digital Badge Register
                    </a>
                  )}
              </div>
            </div>
          </div>

          <div className="md:grid md:grid-cols-3 md:gap-6 mt-5">
            <div className="mt-5 md:mt-0 md:col-span-6">
              {ledger && (
                <EntryList entries={filteredEntries} ledger={ledger} />
              )}
            </div>
          </div>
        </section>
        <ScrollToTopButton />
      </main>
    </RouteTransition>
  );
};

export default Entries;
