import { useState } from "react";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";

type EditInPlaceProps = {
  name: string;
  value: any;
  id: string;
  service: any;
};

const EditInPlace: React.FunctionComponent<EditInPlaceProps> = ({
  name,
  value,
  id,
  service,
}: EditInPlaceProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [field, setField] = useState(value);
  const [saveSuccess, setSaveSuccess] = useState(false);

  const boxClass = classNames(
    "py-1.5 px-2 mx-2 hover:bg-gray-100 rounded cursor-pointer",
    {
      "text-green-600": saveSuccess,
    }
  );

  const handleSubmission = () => {
    if (!isValid) return;
    setIsSubmitting(true);
    service
      .update(id, { [name]: field })
      .then(() => {
        setSaveSuccess(true);
        setTimeout(() => {
          setSaveSuccess(false);
        }, 2000);
      })
      .catch(({ response }: any) => {
        console.error(response);
      })
      .finally(() => {
        setIsSubmitting(false);
        setIsEditing(false);
      });
  };
  return (
    <AnimatePresence>
      {!isEditing && (
        <motion.div
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={boxClass}
          onClick={() => {
            setIsEditing(true);
          }}
        >
          <div className="flex items-center gap-x-2">
            {field}
            <i className="fal fa-edit fa-fw opacity-100 md:opacity-0 md:group-hover:opacity-100 text-base text-gray-500" />
          </div>
        </motion.div>
      )}
      {isEditing && (
        <motion.div
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="flex"
        >
          <input
            type="text"
            autoFocus
            name={name}
            value={field}
            className="bg-white border px-2 py-1 h-8 rounded"
            onChange={(event) => {
              setIsValid(true);
              if (event.target.value === "") setIsValid(false);
              setField(event.target.value);
            }}
          />
          {!isSubmitting && (
            <div className="flex gap-1 pl-2 flex-shrink items-center">
              {isValid && (
                <div
                  className="grid w-7 h-7 place-items-center hover:bg-gray-100 opacity-50 hover:opacity-100 rounded-full cursor-pointer"
                  onClick={handleSubmission}
                >
                  <i className="fal fa-check fa-fw text-lg" />
                </div>
              )}
              {!isValid && (
                <div className="grid w-7 h-7 place-items-center opacity-20 cursor-not-allowed">
                  <i className="fal fa-check fa-fw text-lg" />
                </div>
              )}

              <div
                className="grid w-7 h-7 place-items-center hover:bg-gray-100 opacity-50 hover:opacity-100 rounded-full cursor-pointer"
                onClick={() => {
                  setField(value);
                  setIsEditing(false);
                }}
              >
                <i className="fal fa-times fa-fw text-lg" />
              </div>
            </div>
          )}
          {isSubmitting && (
            <div className="grid w-7 h-7 place-items-center">
              <i className="fal fa-spinner fa-pulse fa-fw h-6" />
            </div>
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
};
export default EditInPlace;
