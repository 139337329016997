import axios from "axios";

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      const status = error.response.status;
      if (
        status === 500 ||
        status === 502 ||
        status === 503 ||
        status === 504
      ) {
        window.location.href = "/NetworkError";
      } else if (status === 401) {
        window.location.href = "/UserInfoChangeError";
      }
    } else if (error.request) {
      window.location.href = "/InternalServerError";
    } else {
      window.location.href = "/UnknownError";
    }
    return Promise.reject(error);
  }
);
