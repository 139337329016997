import DocumentationNav from "./DocumentationNav";
import RouteTransition from "../layout/RouteTransition";
import PageHeader from "../layout/PageHeader";

import NewRuleImage from "../../images/documentation-new-rule.png";
import ListRulesImage from "../../images/documentation-list-rules.png";

const DocumentationRules = () => {
  const heading4Class = "text-xl font-semibold mb-4";
  const paragraphClass = "text-gray-700 mb-6";

  return (
    <RouteTransition>
      <PageHeader pageTitle="Documentation" />
      <div className="grid grid-cols-3 gap-6">
        <div className="col-span-3 md:col-span-1">
          <DocumentationNav />
        </div>
        <div className="col-span-3 md:col-span-2">
          <div className="shadow sm:rounded-md">
            <div className="bg-white md:rounded-md overflow-hidden">
              <div className="flex items-center w-full px-4 md:px-6 h-16 md:h-20 border-b border-gray-200">
                <h3 className="text-xl md:text-2xl font-semibold">
                  Entry Rules
                </h3>
              </div>
              <article className="p-4 md:p-6">
                <h4 className={heading4Class}>Entry Rules</h4>

                <p className={paragraphClass}>
                  Entry Rules allow you to validate data being entered into the
                  ledger, to ensure that it will create useable, properly formed
                  and structured data. Rules are only able to be applied to
                  Defined entry types, so you must set up a data definition
                  first.
                </p>

                <div className="grid grid-cols-1 gap-3 lg:grid-cols-2 mt-10">
                  <div>
                    <h4 className={heading4Class}>Viewing Rules</h4>

                    <p className={paragraphClass}>
                      You can view all of the applied rules by first navigating
                      to a data definition on any of your ledgers. This will
                      then list all of the rules.
                    </p>

                    <p className={paragraphClass}>
                      From this view you can also edit and delete the created
                      rules, by clicking the buttons visible on hover.
                    </p>
                  </div>
                  <div>
                    <img
                      src={ListRulesImage}
                      alt="List Rules Table"
                      className="border shadow-lg relative object-cover left-10"
                    />
                  </div>
                </div>

                <div>
                  <h4 className={heading4Class}>Creating New Rules</h4>
                  <p className={paragraphClass}>
                    At the bottom of the rules list is the form to create a new
                    rule. You can set the field you want to put the rule on, and
                    then what rule to apply. Most rules also allow setting a
                    value, such as the time before, or a minimum length.
                  </p>

                  <p className={paragraphClass}>
                    You cannot create a duplicate rule on any field, so you
                    cannot have a two of the same rule type on a field - for
                    example, it can't be required twice.
                  </p>
                  <img
                    src={NewRuleImage}
                    alt="Create Rule Interface"
                    className="border shadow-lg relative object-cover -left-8"
                  />
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </RouteTransition>
  );
};

export default DocumentationRules;
