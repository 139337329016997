import Logout from "./Logout";
import { Menu } from "@headlessui/react";
import { ReactComponent as IconUserCircle } from "../../images/icon-user-circle.svg";
import { shortDate } from "@utils/format";

type NavUserDetailsProps = {
  user: any;
};

const NavUserDetails: React.FunctionComponent<NavUserDetailsProps> = ({
  user,
}: NavUserDetailsProps) => {
  if (!user) return null;
  return (
    <Menu
      as="div"
      className="md:flex items-center justify-end relative md:flex-1 lg:w-0 text-center"
    >
      <Menu.Button className="rounded-full cursor-pointer p-2 hover:bg-gray-700">
        <IconUserCircle className="w-6 text-white" />
      </Menu.Button>
      <Menu.Items className="absolute rounded-lg shadow-xl top-16 right-0 bg-white px-4 py-6 z-50 overflow-hidden">
        <h4 className="text-lg font-semibold text-gray-900">Your details</h4>
        <dl className="sm:w-96 w-60 border rounded text-left my-5 overflow-hidden">
          <div className="bg-gray-50 p-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-semibold text-gray-500">Company</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 truncate">
              {user.company?.name}
            </dd>
          </div>
          <div className="bg-white p-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-semibold text-gray-500">Name</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 truncate">
              {user.name}
            </dd>
          </div>
          <div className="bg-gray-50 p-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-semibold text-gray-500">Email</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 truncate">
              {user.email}
            </dd>
          </div>
          <div className="bg-white p-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-semibold text-gray-500">Joined</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 truncate">
              {shortDate(user.createdAt)}
            </dd>
          </div>
        </dl>

        <Logout />
      </Menu.Items>
    </Menu>
  );
};

export default NavUserDetails;
