import { enforcementModes, ledgerPlatforms } from "@utils/lookups";
import { Link } from "react-router-dom";

type LedgerItemProps = {
  ledger: any;
};

const LedgerItem: React.FunctionComponent<LedgerItemProps> = ({
  ledger,
}: LedgerItemProps) => {
  return (
    <li
      key={ledger.id}
      className="flex flex-col sm:flex-row gap-4 sm:gap-8 justify-between p-4 md:p-6"
    >
      <div className="flex-grow">
        <Link to={`/ledgers/${ledger.id}`} className="inline-block">
          <h4
            className={`text-lg text-blue-700 hover:text-blue-500 font-semibold mb-2 ${
              ledger.deletedAt ? "opacity-25" : ""
            }`}
          >
            {ledger.name}
          </h4>
        </Link>
        <p className="text-sm text-gray-500 mb-4">{ledger.description}</p>
        <ul className="">
          <li className="inline-block bg-gray-100 text-xs font-medium text-gray-600 px-2 py-1 mr-2 mb-2 md:mb-0 rounded-full whitespace-nowrap">
            {ledger.encryptionMode === "encrypted"
              ? `Encrypted`
              : ledger.encryptionMode === "hashed"
              ? "Hashed"
              : `Not Encrypted`}
          </li>
          <li className="inline-block bg-gray-100 text-xs font-medium text-gray-600 px-2 py-1 mr-2 mb-2 md:mb-0 rounded-full whitespace-nowrap">
            {
              enforcementModes[
                (ledger.enforcementMode as keyof typeof enforcementModes) ?? 1
              ]
            }
          </li>
          <li className="inline-block bg-gray-100 text-xs font-medium text-gray-600 px-2 py-1 mr-2 mb-2 md:mb-0 rounded-full whitespace-nowrap">
            {
              ledgerPlatforms[
                (ledger.ledgerPlatform as keyof typeof enforcementModes) ?? 1
              ]
            }
          </li>
        </ul>
      </div>
      <div className="w-auto sm:w-28 flex-grow-0 flex-shrink-0 flex flex-row sm:flex-col sm:text-center sm:justify-center gap-1 sm:gap-0">
        <span className="text-sm sm:text-4xl font-semibold sm:font-normal text-gray-600 mb-1">
          {ledger.entries.length}
        </span>
        <span className="text-sm">
          {ledger.entries.length > 1 ? "entries" : "entry"}
        </span>
      </div>
    </li>
  );
};

export default LedgerItem;
