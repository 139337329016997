import LedgerSummaryEnforcement from "./LedgerSummaryEnforcement";

type DefinitionsPanelProps = {
  ledger: any;
};

const DefinitionsPanel: React.FunctionComponent<DefinitionsPanelProps> = ({
  ledger,
}: DefinitionsPanelProps) => {
  return (
    <article className="max-w-2xl mx-auto my-6">
      <h3 className="text-2xl font-semibold mb-4">Data Definitions</h3>
      <p className="text-gray-700 mb-6">
        Set up data definitions to provide a structure to your incoming data.
        This makes it easier to input, search, and validate.
      </p>
      <LedgerSummaryEnforcement ledger={ledger} />
    </article>
  );
};

export default DefinitionsPanel;
