const ENV = {
  APP_KEY: process.env.REACT_APP_KEY || "some-secret-key",
  API_HOST: process.env.REACT_APP_API_HOST,
  CLIENT_ID: process.env.REACT_APP_CLIENT_ID || "",
  HASHGRAPH_TRANSACTION_URL:
    process.env.REACT_APP_HASHGRAPH_TRANSACTION_URL ||
    "https://explore.lworks.io/testnet",
  HELP_CENTER_URL:
    process.env.REACT_APP_HELP_CENTER_URL ||
    "https://civicledger.atlassian.net/servicedesk/customer/portal/1",
  HASHSCAN_URL: process.env.REACT_APP_HASHSCAN_URL || "https://hashscan.io",
  NODE_ENV: process.env.NODE_ENV,
};

export default ENV;
