import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import GuestNav from "./layout/GuestNav";
import Signup from "./Signup";
import Login from "./Login";
import ResetPassword from "./ResetPassword";
import Documentation from "./documentation/Documentation";
import DocumentationLedgers from "./documentation/DocumentationLedgers";
import DocumentationEntries from "./documentation/DocumentationEntries";
import DocumentationDefinitions from "./documentation/DocumentationDefinitions";
import DocumentationRules from "./documentation/DocumentationRules";
import DocumentationIntegration from "./documentation/DocumentationIntegration";
import Footer from "./layout/Footer";
import NotFound from "@error/NotFound";
import NetworkError from "@error/NetworkError";
import UnknownError from "@error/UnknownError";
import InternalServerError from "@error/InternalServerError";
import MembershipVerification from "./MembershipVerification";
import { Navigate } from "react-router";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import B2CMembershipVerification from "./B2CMembershipVerification";

const queryClient = new QueryClient();

const GuestHome: React.FunctionComponent = () => {
  const location = useLocation();
  const showOriginalFooter = !(
    location.pathname === "/login" ||
    location.pathname === "/signup" ||
    location.pathname === "/resetPassword"
  );
  return (
    <QueryClientProvider client={queryClient}>
      <div className="App container min-w-full mx-auto min-h-screen flex flex-col">
        <GuestNav />
        <div className=" mx-auto border border-t-0 border-b-0 flex-grow flex flex-col min-w-full bg-zinc-700">
          <AnimatePresence initial={false} mode="wait">
            <Routes location={location} key={location.key}>
              <Route path="/" element={<Navigate to="/login" />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/login" element={<Login />} />
              <Route path="/resetPassword" element={<ResetPassword />} />
              <Route path="/documentation/" element={<Documentation />} />
              <Route
                path="/documentation/ledgers"
                element={<DocumentationLedgers />}
              />
              <Route
                path="/documentation/entries"
                element={<DocumentationEntries />}
              />
              <Route
                path="/documentation/definitions"
                element={<DocumentationDefinitions />}
              />
              <Route
                path="/documentation/rules"
                element={<DocumentationRules />}
              />
              <Route
                path="/documentation/integration"
                element={<DocumentationIntegration />}
              />
              <Route
                path="/memberships/:companyId"
                element={
                  <div className="max-w-7xl mx-auto px-4 sm:px-8 flex-grow flex flex-col w-full">
                    <MembershipVerification />
                  </div>
                }
              />
              <Route
                path="/memberships/:companyName/:id"
                element={
                  <div className="max-w-7xl mx-auto px-4 sm:px-8 flex-grow flex flex-col w-full">
                    <B2CMembershipVerification />
                  </div>
                }
              />
              <Route path="/NetworkError" element={<NetworkError />} />
              <Route path="/UnknownError" element={<UnknownError />} />
              <Route
                path="/InternalServerError"
                element={<InternalServerError />}
              />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </AnimatePresence>
          {showOriginalFooter && <Footer />}
        </div>
      </div>
    </QueryClientProvider>
  );
};

export default GuestHome;
