import { useState } from "react";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import DatePicker from "react-date-picker";
import DateTimePicker from "react-datetime-picker";
import { shortDate, shortTime } from "@utils/format";

type EditDateTimeProps = {
  name: string;
  value: any;
  id: string;
  service: any;
  timeIncluded: boolean;
};

const EditDateTime: React.FunctionComponent<EditDateTimeProps> = ({
  name,
  value,
  id,
  service,
  timeIncluded,
}: EditDateTimeProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isValid] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [field, setField] = useState(value);
  const [saveSuccess, setSaveSuccess] = useState(false);

  const boxClass = classNames(
    "py-1.5 px-2 hover:bg-gray-100 rounded cursor-pointer",
    {
      "text-green-600": saveSuccess,
    }
  );

  const handleSubmission = () => {
    if (!isValid) return;
    setIsSubmitting(true);
    service
      .update(id, { [name]: field })
      .then(() => {
        setSaveSuccess(true);
        setTimeout(() => {
          setSaveSuccess(false);
        }, 2000);
      })
      .catch(({ response }: any) => {
        console.error(response);
      })
      .finally(() => {
        setIsSubmitting(false);
        setIsEditing(false);
      });
  };
  return (
    <AnimatePresence>
      {!isEditing && (
        <motion.div
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={boxClass}
          onClick={() => {
            setIsEditing(true);
          }}
        >
          <div className="flex items-center gap-x-2">
            {field && timeIncluded && (
              <div>
                {shortDate(field)} at {shortTime(field)}
              </div>
            )}
            {field && !timeIncluded && <div>{shortDate(field)}</div>}
            <i className="fal fa-edit fa-fw opacity-100 md:opacity-0 md:group-hover:opacity-100 text-base text-gray-500" />
          </div>
        </motion.div>
      )}
      {isEditing && (
        <motion.div
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="flex"
        >
          {timeIncluded && (
            <DateTimePicker
              onChange={(setDate): any => {
                setField(setDate);
              }}
              className="bg-white border px-1 py-0.5 rounded"
              name="value"
              minDate={new Date(1900, 0, 1)}
              maxDate={new Date(2100, 0, 1)}
              maxDetail="second"
              value={field}
            />
          )}
          {!timeIncluded && (
            <DatePicker
              onChange={(setDate) => {
                setField(setDate);
              }}
              className="bg-white border px-1 pt-px pb-0.5 rounded"
              name="value"
              minDate={new Date(1900, 0, 1)}
              maxDate={new Date(2100, 0, 1)}
              value={field}
            />
          )}
          {!isSubmitting && (
            <div className="flex gap-1 pl-2 flex-shrink items-center">
              {isValid && (
                <div
                  className="grid w-7 h-7 place-items-center hover:bg-gray-100 opacity-50 hover:opacity-100 rounded-full cursor-pointer"
                  onClick={handleSubmission}
                >
                  <i className="fal fa-check fa-fw text-lg" />
                </div>
              )}
              {!isValid && (
                <div className="grid w-7 h-7 place-items-center hover:bg-gray-100 opacity-50 hover:opacity-100 rounded-full cursor-pointer">
                  <i className="fal fa-ban fa-fw text-lg" />
                </div>
              )}

              <div
                className="grid w-7 h-7 place-items-center hover:bg-gray-100 opacity-50 hover:opacity-100 rounded-full cursor-pointer"
                onClick={() => {
                  setField(value);
                  setIsEditing(false);
                }}
              >
                <i className="fal fa-times fa-fw text-lg" />
              </div>
            </div>
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
};
export default EditDateTime;
