import { useState } from "react";
import { Transition } from "@headlessui/react";
import twitterIcon from "../../images/twitter-icon.png";

import { ReactComponent as NavLogo } from "../../images/nav-logo.svg";
import NavItem from "@components/layout/NavItem";

const GuestNav = () => {
  const [isShowing, setIsShowing] = useState(false);
  return (
    <nav>
      <div className="w-screen max-w-full m-0 p-0 flex-grow flex flex-col bg-zinc-700 text-cyan-500">
        <div className="px-4 sm:px-6 border border-b-0 border-t-0 grid grid-cols-2">
          <div className=" items-center md:space-x-10 xl:ml-36 col-span-1  w-1/4 ">
            <div className="flex items-center">
              <div className="lg:w-0 lg:flex-1">
                <NavItem to="https://www.datasker.io/">
                  <div className="w-64 h-16 my-4 ">
                    <NavLogo />
                  </div>
                  <span className="sr-only">Workflow</span>
                </NavItem>
              </div>
            </div>
          </div>
          <div className="flex items-center md:space-x-10 xl:mr-36 justify-end col-span-1 relative ">
            <div className="md:flex items-center justify-end md:flex-1 lg:w-0 text-center">
              <div className="mx-3 hidden lg:flex items-center justify-end md:flex-1 lg:w-0 ml-36 space-x-5">
                <NavItem
                  to="https://www.datasker.io/"
                  exact
                  activeClassName={"text-cyan-500 "}
                  className={"ml-4 text-base font-extralight  hover:text-white"}
                >
                  Home
                </NavItem>
                <NavItem
                  to="https://www.datasker.io/services"
                  exact
                  activeClassName={"text-cyan-500 "}
                  className={"ml-4 text-base font-extralight  hover:text-white"}
                >
                  Services
                </NavItem>
                <NavItem
                  to="https://datasker.io/documentation"
                  activeClassName={"text-cyan-500 "}
                  className={"text-base font-extralight  hover:text-white"}
                >
                  Documentation
                </NavItem>
                <NavItem
                  to="/signup"
                  activeClassName={"text-cyan-500 "}
                  className={
                    "whitespace-nowrap text-base font-extralight hover:text-white"
                  }
                >
                  Sign up
                </NavItem>
                <NavItem
                  to="https://twitter.com/datasker"
                  activeClassName={"text-cyan-500 "}
                  className={
                    "whitespace-nowrap text-base font-extralight hover:text-white"
                  }
                  target="_blank"
                >
                  <img src={twitterIcon} alt="Twitter" className="h-8" />
                </NavItem>
                <NavItem
                  to="https://www.linkedin.com/company/datasker/"
                  activeClassName={"text-cyan-500 "}
                  className={
                    "whitespace-nowrap font-semibold hover:text-white m-0 text-xl"
                  }
                  target="_blank"
                >
                  in
                </NavItem>
                <NavItem
                  to="https://medium.com/civic-ledger"
                  activeClassName={"text-cyan-500 "}
                  className={
                    "whitespace-nowrap font-semibold hover:text-white m-0 text-xl"
                  }
                  target="_blank"
                >
                  M
                </NavItem>
                <NavItem
                  to="/login"
                  className="h-16 ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded shadow-sm text-base bg-cyan-400 opacity-80 hover:bg-cyan-500 text-white border-x-8"
                >
                  Sign In
                </NavItem>
              </div>

              <div className="lg:hidden justify-end">
                <button
                  type="button"
                  onClick={() => setIsShowing(!isShowing)}
                  className="bg-white rounded-sm p-2 items-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
                  aria-expanded="false"
                >
                  <span className="sr-only">Open menu</span>
                  {!isShowing ? (
                    <svg
                      className="block h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                  ) : (
                    <svg
                      className="block h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </div>

          <Transition
            show={isShowing}
            enter="transition ease-out duration-100 transform"
            enterFrom="opacity-0 scale-95"
            className="col-span-2"
            enterTo="opacity-100 scale-100"
            leave="transition ease-in duration-75 transform"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            {(ref) => (
              <div className="lg:hidden" id="mobile-menu">
                <div
                  ref={ref}
                  className="sm:px-3 sm:py-2 sm:rounded-md sm:block bg-transparent mx-auto"
                >
                  <NavItem
                    to="https://www.datasker.io/"
                    className="text-cyan-500 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-extralight"
                  >
                    Home
                  </NavItem>

                  <NavItem
                    to="https://www.datasker.io/services"
                    isActive={true}
                    className="text-cyan-500 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-extralight"
                  >
                    Services
                  </NavItem>

                  <NavItem
                    to="https://datasker.io/documentation"
                    className="text-cyan-500 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-extralight"
                  >
                    Documentations
                  </NavItem>

                  <NavItem
                    to="/signup"
                    onClick={() => setIsShowing(!isShowing)}
                    className="text-cyan-500 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-extralight"
                  >
                    Sign up
                  </NavItem>

                  <NavItem
                    to="https://twitter.com/datasker"
                    className="text-cyan-500 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-extralight"
                    target="_blank"
                  >
                    Twitter
                  </NavItem>

                  <NavItem
                    to="https://www.linkedin.com/company/civic-ledger/"
                    className="text-cyan-500 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-extralight"
                    target="_blank"
                  >
                    LinkedIn
                  </NavItem>

                  <NavItem
                    to="https://medium.com/civic-ledger"
                    className="text-cyan-500 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-extralight"
                    target="_blank"
                  >
                    Medium
                  </NavItem>

                  <NavItem
                    to="/login"
                    onClick={() => setIsShowing(!isShowing)}
                    className="text-cyan-500 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-extralight"
                  >
                    Sign In
                  </NavItem>
                </div>
              </div>
            )}
          </Transition>
        </div>
      </div>
    </nav>
  );
};

export default GuestNav;
