import LedgerForm from "./ledger/LedgerForm";
import RouteTransition from "./layout/RouteTransition";
import PageHeader from "./layout/PageHeader";

const LedgersNew: React.FunctionComponent = () => {
  return (
    <RouteTransition>
      <PageHeader
        pageTitle="Create Ledger"
        breadcrumbs={[
          ["All Ledgers", "/ledgers/"],
          ["Create Ledger", undefined],
        ]}
      />
      <main className="bg-white rounded-lg w-full shadow-sm">
        <section className="p-4 md:p-6">
          <LedgerForm />
        </section>
      </main>
    </RouteTransition>
  );
};

export default LedgersNew;
