import { useState } from "react";
import { Formik, Form } from "formik";
import EntryService from "../../services/EntryService";

import EntryDynamicField from "./EntryDynamicField";
import FormSuccess from "../layout/FormSuccess";
import { createValidation } from "@utils/createValidation";
import { useQuery } from "@tanstack/react-query";
import { ledgersQuery } from "@utils/queries";
import Modal from "@components/layout/Modal";
import Loading from "@components/shared/Loading";

const entryService = new EntryService();

type EntryDynamicFormProps = {
  definition: any;
  ledger: any;
  rules: any;
};

const EntryDynamicForm: React.FunctionComponent<EntryDynamicFormProps> = ({
  definition,
  ledger,
  rules,
}: EntryDynamicFormProps) => {
  const [success, setSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const { data: allLedgers = [] } = useQuery(["ledgers"], ledgersQuery);

  const initialValues = definition.fields.reduce((values: any, field: any) => {
    values[field.name] = "";
    if ([3, 5].includes(field.type)) {
      values[field.name] = new Date();
    }
    if (field.type === 4) {
      values[field.name] = false;
    }
    return values;
  }, {});

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={createValidation(definition, rules)}
      onSubmit={(event, actions) => {
        setIsLoading(true);
        const payload = {
          ledgerId: ledger.id,
          name: definition.entryName,
          event,
        };

        entryService.create(payload).then((response) => {
          if (definition.fields.some(({ type }: any) => type === 6)) {
            // eslint-disable-next-line
            window.location = window.location;
          }
          setSuccess(true);
          actions.resetForm();
          setTimeout(() => {
            setSuccess(false);
          }, 3000);
          setIsLoading(false);
        });
      }}
    >
      {(props) => {
        return (
          <div>
            <Form className="grid grid-cols-2 gap-6 mt-6">
              <div className="col-span-2 text-center">
                <FormSuccess show={success}>
                  Your entry has been successfully saved.
                </FormSuccess>
              </div>

              {definition.fields.map((field: any) => (
                <EntryDynamicField
                  field={field}
                  value={props.values[field.name]}
                  setFieldValue={props.setFieldValue}
                  rules={rules}
                  key={field.id}
                  ledger={ledger}
                  allLedgers={allLedgers.filter(
                    (ledger: any) => !ledger.deletedAt
                  )}
                />
              ))}

              {!isLoading && (
                <div className="col-span-2 text-right">
                  <button
                    type="submit"
                    className="justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    <i className="fal fa-cloud-upload mr-2"></i>
                    Save
                  </button>
                </div>
              )}
              {isLoading && (
                <div className="col-span-2 text-right">
                  <button
                    disabled={true}
                    className="cursor-not-allowed opacity-30 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    <i className="fal fa-cloud-upload mr-2"></i>
                    Save
                  </button>
                </div>
              )}
            </Form>
            <Modal open={isLoading}>
              <div className="flex flex-col">
                <div className="border-b p-4">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Saving Entry
                  </h3>
                </div>
                <div className="p-4 space-y-5">
                  <Loading />
                </div>
              </div>
            </Modal>
          </div>
        );
      }}
    </Formik>
  );
};

export default EntryDynamicForm;
