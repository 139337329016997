import { useParams } from "react-router-dom";

import EntryData from "./entry/EntryData";
import EntryEvents from "./entry/EntryEvents";
import RouteTransition from "./layout/RouteTransition";
import PageHeader from "./layout/PageHeader";
import LedgerNav from "./ledger/LedgerNav";
import { useQuery } from "@tanstack/react-query";
import { entryQuery, ledgerQuery } from "@utils/queries";

const Entry: React.FunctionComponent = () => {
  const { id = "" } = useParams();

  let { data: entry } = useQuery(["entry", id], () => entryQuery(id), {
    keepPreviousData: true,
  });

  let { data: ledger } = useQuery(
    ["ledger", entry?.ledgerId],
    () => ledgerQuery(entry?.ledgerId),
    {
      keepPreviousData: true,
      enabled: !!entry,
    }
  );

  const definition = ledger?.definitions.find((definition: any) => {
    return definition.entryName === entry.name;
  });

  if (!entry) entry = { name: "Not Found" };

  return (
    <RouteTransition>
      <PageHeader
        pageTitle={
          entry?.ledger?.deletedAt
            ? `${entry?.ledger?.name} (Archived)`
            : entry?.ledger?.name
        }
        breadcrumbs={[
          ["All Ledgers", "/ledgers/"],
          [entry?.ledger?.name, `/ledgers/${entry?.ledger?.id}/entries`],
          [entry.name, undefined],
        ]}
      />
      <main className="bg-white rounded-lg w-full shadow-sm">
        <LedgerNav ledger={ledger} />
        <section className="p-4 md:p-6">
          <article className="max-w-2xl mx-auto my-6">
            <h3 className="text-2xl font-semibold mb-4">Entry</h3>
            <p className="text-gray-700 mb-6">
              The provided information and metadata about this Entry.
            </p>
            <div>{entry && <EntryData entry={entry} />}</div>

            <h3 className="text-2xl font-semibold mb-4">Entry Events</h3>
            <p className="text-gray-700 mb-6">
              The provided information about the Entry events.
            </p>
            <div>
              {entry.event && (
                <EntryEvents entry={entry} definition={definition} />
              )}
            </div>
          </article>
        </section>
      </main>
    </RouteTransition>
  );
};

export default Entry;
