import BaseService from "@services/BaseService";
import axios from "axios";

export default class NotificationService extends BaseService {
  entity = "notifications";

  readAll = async () => {
    const headers = this.getHeaders();
    return axios.post(`${this.entity}/readAll`, {}, { headers });
  };
}

export const notificationService = new NotificationService();
