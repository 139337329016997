import ErrorLayout from "@components/layout/ErrorLayout";

const UnknownError = () => {
  return (
    <ErrorLayout
      title="Unknown error"
      description="An unknown error has occurred, please try again later."
    >
      <a
        href="/"
        className="inline-block btn-secondary text-sm rounded  hover:underline hover:text-blue-500"
      >
        Go to dashboard
        <span className="inline-block ml-2" aria-hidden="true">
          &rarr;
        </span>
      </a>
    </ErrorLayout>
  );
};

export default UnknownError;
