import { getLedgerTopicUrl } from "@utils/hashgraphUtils";
import { ReactComponent as IconCube } from "../../images/icon-cube.svg";

const ledgerPlatforms = {
  1: " a Hashgraph Consensus ",
  2: " an Ethereum Smart Contract ",
  3: " a Daml ledger ",
};

type LedgerSummaryDeploymentProps = {
  ledger: any;
};

const LedgerSummaryDeployment: React.FunctionComponent<
  LedgerSummaryDeploymentProps
> = ({ ledger }: LedgerSummaryDeploymentProps) => {
  return (
    <div className="flex flex-col md:flex-row gap-4 border border-gray-300 rounded-md p-4 md:p-6 text-gray-800">
      {ledger.deployment && (
        <>
          <div className="flex-shrink-0 text-gray-500">
            <IconCube />
          </div>
          <div>
            <p>
              Your ledger has been deployed as{" "}
              {
                ledgerPlatforms[
                  ledger.ledgerPlatform as keyof typeof ledgerPlatforms
                ]
              }{" "}
              at{" "}
              <a
                className="text-blue-500 hover:text-blue-700 font-semibold"
                href={`${getLedgerTopicUrl(ledger?.deployment)}`}
              >
                {ledger.deployment}.
              </a>
            </p>
          </div>
        </>
      )}

      {!ledger.deployment && (
        <>
          <div className="flex-shrink-0 text-gray-300">
            <IconCube />
          </div>
          <p>Your ledger has not been deployed yet.</p>
        </>
      )}
    </div>
  );
};

export default LedgerSummaryDeployment;
