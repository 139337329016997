import { useState } from "react";
import { Formik, ErrorMessage, Form } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import CompanyService from "../services/CompanyService";
import dataskerLogo from "../images/logo512.png";
import RouteTransition from "./layout/RouteTransition";
import FormSuccess from "./layout/FormSuccess";
import FormError from "./layout/FormError";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import UserService from "../services/UserService";
import OtpInput from "react18-input-otp";
import SignInFooter from "./layout/SignInFooter";

const companyService = new CompanyService();
const userService = new UserService();
const validate = Yup.object({
  email: Yup.string().email("Email is invalid").required("Email is required"),
  tel: Yup.string().required("Tel is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .matches(/\d/, "Password must include a number")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "Password must match")
    .required("Confirm password is required"),
  name: Yup.string().required("Name is required"),
  companyName: Yup.string().required("Company is required"),
});

const Signup: React.FunctionComponent = () => {
  const [success, setSuccess] = useState(false);
  const [formErrors, setFormErrors] = useState<string[]>([]);
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [resendSuccess, setResendSuccess] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleSubmission = async (values: any, actions: any) => {
    actions.setSubmitting(true);
    setFormErrors([]);
    setIsLoading(true);
    setResendSuccess(false);
    try {
      if (step === 1) {
        const { email, password, tel } = values;
        const response = await userService.signUp(email, password, tel);
        if (response?.status === 200) {
          setStep(2);
        } else if (response?.status === 409) {
          setFormErrors(["Email already exists, please try again!"]);
        } else if (response?.status === 422) {
          setFormErrors(["Invalid email or password, please try again!"]);
        } else {
          setFormErrors(["Failed to sign up, please try again!"]);
        }
      } else if (step === 2) {
        const { email, confirmationCode } = values;
        const verificationResponse = await userService.confirmSignUp(
          email,
          confirmationCode
        );
        if (verificationResponse.success) {
          const { confirmationCode, companyName: name, ...user } = values;
          const company = { name, user };
          await companyService.create(company);
          setSuccess(true);
          actions.resetForm();
          setTimeout(() => {
            navigate("/login");
          }, 1500);
        } else {
          setFormErrors(["Verification code mis-matched, please try again!"]);
        }
      }
    } catch (error) {
      const err = error as any;
      if (err.response && err.response.data && err.response.data.errors) {
        const errorMessages = err.response.data.errors.map(
          (errorObj: any) => errorObj.message
        );
        setFormErrors(errorMessages);
      } else {
        setFormErrors(["An unexpected error occurred."]);
      }
    } finally {
      actions.setSubmitting(false);
      setIsLoading(false);
    }
  };

  const resendCode = async (email: string) => {
    setIsLoading(true);
    try {
      const response = await userService.resendConfirmSignUpCode(email);
      if (response?.status === 200) {
        setResendSuccess(true);
      } else {
        setFormErrors([
          "Unable to resend verification code, please try again!",
        ]);
      }
    } catch (error) {
      setFormErrors(["An unexpected error occurred while resending the code."]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <RouteTransition>
      <div className="max-w-2xl  mx-auto my-6 items-center mb-28">
        <div className=" mx-auto my-6 mb-8">
          <img
            src={dataskerLogo}
            alt="Datasker Logo"
            className="sm:h-32 h-20 m-auto"
          />
          <h2 className="m-auto flex my-4 justify-center sm:text-3xl md:text-4xl text-lg text-cyan-700 font-semibold">
            Sign Up for your Datasker Account
          </h2>
        </div>

        <Formik
          initialValues={{
            email: "",
            tel: "",
            password: "",
            confirmPassword: "",
            name: "",
            companyName: "",
            confirmationCode: "",
          }}
          validationSchema={validate}
          onSubmit={handleSubmission}
        >
          {(props) => (
            <Form>
              <div>
                <div className=" bg-zinc-700">
                  {step === 1 && (
                    <div>
                      <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                          <h3 className="text-lg font-medium leading-6 text-white">
                            Login Details
                          </h3>
                          <p className="mt-1 text-sm text-white">
                            Use these details to log into the website. These
                            details are required.
                          </p>
                        </div>
                      </div>
                      <div className="mt-5 md:mt-0 md:col-span-2">
                        <FormError
                          show={formErrors.length > 0}
                          errors={formErrors}
                          title=""
                        />
                        <div className=" sm:rounded-md sm:overflow-hidden text-white">
                          <div className="px-4 py-5 bg-zinc-700 space-y-6 sm:p-6">
                            <div className="grid grid-cols-1 gap-6">
                              <div className="col-span-1 sm:col-span-1">
                                <label
                                  htmlFor="email"
                                  className="text-base font-medium text-white flex items-end"
                                >
                                  Email
                                  <p className="font-extralight text-sm inline ml-2 opacity-70">
                                    (required)
                                  </p>
                                </label>
                                <div className="mt-1 flex rounded-md shadow-sm">
                                  <input
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    value={props.values.email}
                                    type="email"
                                    name="email"
                                    id="email"
                                    className="p-2 focus:ring-blue-500 focus:border-blue-500 flex-1 block w-full rounded-md sm:text-sm border border-gray-300 text-black"
                                    placeholder=""
                                  />
                                </div>
                                <ErrorMessage
                                  data-testid="emailError"
                                  component="p"
                                  className="sm:text-xs text-red-600 pt-2"
                                  name="email"
                                />
                              </div>
                            </div>

                            <div className="grid grid-cols-1 gap-6">
                              <div className="col-span-1 sm:col-span-1">
                                <label
                                  htmlFor="tel"
                                  className="text-base font-medium text-white flex items-end"
                                >
                                  Tel
                                  <p className="font-extralight text-sm inline ml-2 opacity-70">
                                    (required)
                                  </p>
                                </label>
                                <div className="mt-1 flex rounded-md shadow-sm">
                                  <PhoneInput
                                    name="tel"
                                    defaultCountry="AU"
                                    className="p-2  focus:ring-blue-500  focus:border-blue-500 flex-1 block w-full rounded-md text-sm bg-white border-gray-300 text-black"
                                    value={props.values.tel}
                                    onChange={(phone) =>
                                      props.setFieldValue("tel", phone)
                                    }
                                  />
                                </div>
                                <ErrorMessage
                                  data-testid="telError"
                                  component="p"
                                  className="sm:text-xs text-red-600 pt-2"
                                  name="tel"
                                />
                              </div>
                            </div>

                            <div className="grid grid-cols-1 gap-6">
                              <div className="col-span-1 sm:col-span-1">
                                <label
                                  htmlFor="password"
                                  className="text-base font-medium text-white flex items-end"
                                >
                                  Password
                                  <p className="font-extralight text-sm inline ml-2 opacity-70">
                                    (required)
                                  </p>
                                </label>
                                <div className="mt-1 flex rounded-md shadow-sm relative">
                                  <input
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    value={props.values.password}
                                    type={showPassword ? "text" : "password"}
                                    name="password"
                                    id="password"
                                    className="p-2 focus:ring-blue-500 focus:border-blue-500 flex-1 block w-full rounded-md sm:text-sm border border-gray-300 text-black"
                                    placeholder=""
                                  />
                                  <span
                                    onClick={togglePasswordVisibility}
                                    className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                                  >
                                    <i
                                      className={
                                        showPassword
                                          ? "fa fa-eye-slash"
                                          : "fa fa-eye"
                                      }
                                      aria-hidden="true"
                                      style={{ color: "black" }}
                                    ></i>
                                  </span>
                                </div>
                                <ErrorMessage
                                  component="p"
                                  className="sm:text-xs text-red-600 pt-2"
                                  name="password"
                                />
                              </div>
                            </div>

                            <div className="grid grid-cols-1 gap-6">
                              <div className="col-span-1 sm:col-span-1">
                                <label
                                  htmlFor="confirmPassword"
                                  className="text-base font-medium text-white flex items-end"
                                >
                                  Confirm Password
                                  <p className="font-extralight text-sm inline ml-2 opacity-70">
                                    (required)
                                  </p>
                                </label>
                                <div className="mt-1 flex rounded-md shadow-sm relative">
                                  <input
                                    type={
                                      showConfirmPassword ? "text" : "password"
                                    }
                                    name="confirmPassword"
                                    id="confirmPassword"
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    value={props.values.confirmPassword}
                                    className="p-2 focus:ring-blue-500 focus:border-blue-500 flex-1 block w-full rounded-md sm:text-sm border border-gray-300 text-black"
                                    placeholder=""
                                  />
                                  <span
                                    onClick={toggleConfirmPasswordVisibility}
                                    className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                                  >
                                    <i
                                      className={
                                        showPassword
                                          ? "fa fa-eye-slash"
                                          : "fa fa-eye"
                                      }
                                      aria-hidden="true"
                                      style={{ color: "black" }}
                                    ></i>
                                  </span>
                                </div>
                                <ErrorMessage
                                  component="p"
                                  className="sm:text-xs text-red-600 pt-2"
                                  name="confirmPassword"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div>
                  {step === 1 && (
                    <div className="mt-3">
                      <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                          <h3 className="text-lg font-medium leading-6 text-white">
                            Profile
                          </h3>
                          <p className="mt-1 text-sm text-white">
                            Information about you and your company. All of this
                            information is required.
                          </p>
                        </div>
                      </div>
                      <div className="mt-5 md:mt-0 md:col-span-2">
                        <div className=" sm:rounded-md sm:overflow-hidden">
                          <div className="px-4 py-5 bg-zinc-700 space-y-6 sm:p-6">
                            <div className="grid grid-cols-1 gap-6">
                              <div className="col-span-1 sm:col-span-2">
                                <label
                                  htmlFor="name"
                                  className=" text-base font-medium text-white flex items-end"
                                >
                                  Name
                                  <p className="font-extralight text-sm inline ml-2 opacity-70">
                                    (required)
                                  </p>
                                </label>
                                <div className="mt-1 flex rounded-md shadow-sm">
                                  <input
                                    type="text"
                                    name="name"
                                    id="name"
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    value={props.values.name}
                                    className="p-2 focus:ring-blue-500 focus:border-blue-500 flex-1 block w-full rounded-md sm:text-sm border border-gray-300 text-black"
                                    placeholder=""
                                  />
                                </div>
                                <ErrorMessage
                                  component="p"
                                  className="sm:text-xs text-red-600 pt-2"
                                  name="name"
                                />
                              </div>
                            </div>

                            <div className="grid grid-cols-1 gap-6">
                              <div className="col-span-1 sm:col-span-2">
                                <label
                                  htmlFor="companyName"
                                  className=" text-base font-medium text-white flex items-end"
                                >
                                  Company
                                  <p className="font-extralight text-sm inline ml-2 opacity-70">
                                    (required)
                                  </p>
                                </label>
                                <div className="mt-1 flex rounded-md shadow-sm">
                                  <input
                                    type="text"
                                    name="companyName"
                                    id="companyName"
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    value={props.values.companyName}
                                    className="p-2 focus:ring-blue-500 focus:border-blue-500 flex-1 block w-full rounded-md sm:text-sm border border-gray-300 text-black"
                                    placeholder=""
                                  />
                                </div>
                                <ErrorMessage
                                  component="p"
                                  className="sm:text-xs text-red-600 pt-2"
                                  name="companyName"
                                />
                              </div>
                            </div>
                          </div>
                          {isLoading && (
                            <div className="flex m-auto items-center">
                              <button
                                disabled={true}
                                className="cursor-not-allowed h-12 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded text-base bg-cyan-600 opacity-30 hover:bg-cyan-500 text-white border-x-8 m-auto"
                              >
                                Sign Up
                              </button>
                            </div>
                          )}
                          {!isLoading && (
                            <div className="flex m-auto items-center">
                              <button
                                type="submit"
                                className="h-12 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded text-base bg-cyan-600 opacity-90 hover:bg-cyan-500 text-white border-x-8 m-auto"
                              >
                                Sign Up
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  {step === 2 && (
                    <div>
                      <div className="mt-5 md:mt-0 md:col-span-2">
                        <div className=" sm:rounded-md sm:overflow-hidden">
                          <div className="px-4 py-5 bg-zinc-700 space-y-6 sm:p-6">
                            <div className="grid grid-cols-1 gap-6">
                              <div className="col-span-1 sm:col-span-2">
                                <FormSuccess show={success}>
                                  Your account has been created. Sending you to
                                  the login page.
                                </FormSuccess>
                                <FormSuccess show={resendSuccess}>
                                  Your code has been resent.
                                </FormSuccess>
                                <FormError
                                  show={formErrors.length > 0}
                                  errors={formErrors}
                                  title=""
                                />
                                <h3 className=" text-base font-light text-white flex items-end mb-3">
                                  Verification code has been sent to you email:{" "}
                                  {props.values.email}
                                </h3>

                                <div className="grid grid-cols-1 gap-3 mt-5">
                                  <div className="col-span-1 sm:col-span-1">
                                    <label
                                      htmlFor="confirmPassword"
                                      className=" text-lg font-medium text-white flex items-end mb-5"
                                    >
                                      Please input your Confirmation Code:
                                    </label>
                                    <OtpInput
                                      value={props.values.confirmationCode}
                                      onChange={(value: any) =>
                                        props.setFieldValue(
                                          "confirmationCode",
                                          value
                                        )
                                      }
                                      numInputs={6}
                                      inputStyle="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:outline-none text-5xl 2xl:text-6xl text-center"
                                      containerStyle="w-full flex gap-4 mt-3 justify-center"
                                      errorStyle="border-red-300"
                                      isInputNum={true}
                                      isDisabled={isLoading}
                                    />
                                  </div>
                                </div>
                                <ErrorMessage
                                  component="p"
                                  className="sm:text-xs text-red-600 pt-2"
                                  name="confirmationCode"
                                />
                              </div>
                            </div>
                          </div>
                          {isLoading && (
                            <div>
                              <div className="flex m-auto items-center">
                                <button
                                  disabled={true}
                                  className="cursor-not-allowed h-12 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded text-base bg-cyan-600 opacity-30 hover:bg-cyan-500 text-white border-x-8 m-auto"
                                >
                                  Verify
                                </button>
                              </div>
                              <div className="flex m-auto items-center mt-8 text-white">
                                Didn't receive a code?{" "}
                                <button
                                  disabled={true}
                                  className="cursor-not-allowed opacity-30 text-cyan-300 hover:underline hover:text-cyan-800 ml-2"
                                >
                                  Resend Code
                                </button>
                              </div>
                            </div>
                          )}
                          {!isLoading && (
                            <div>
                              <div className="flex m-auto items-center">
                                <button
                                  type="submit"
                                  className="h-12 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded text-base bg-cyan-600 opacity-90 hover:bg-cyan-500 text-white border-x-8 m-auto"
                                >
                                  Verify
                                </button>
                              </div>
                              <div className="flex m-auto items-center mt-8 text-white">
                                Didn't receive a code?{" "}
                                <button
                                  onClick={() => resendCode(props.values.email)}
                                  className="text-cyan-300 hover:underline hover:text-cyan-800 ml-2"
                                >
                                  Resend Code
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <SignInFooter />
    </RouteTransition>
  );
};

export default Signup;
