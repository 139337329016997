import { useParams } from "react-router-dom";
import RouteTransition from "./layout/RouteTransition";
import PageHeader from "./layout/PageHeader";
import DefinitionNewForm from "./definition/DefinitionNewForm";
import LedgerNav from "./ledger/LedgerNav";
import { useQuery } from "@tanstack/react-query";
import { ledgerQuery } from "@utils/queries";

const DefinitionNew = () => {
  const { id = "" } = useParams();
  let { data: ledger } = useQuery(["ledger", id], () => ledgerQuery(id), {
    keepPreviousData: true,
  });
  return (
    <RouteTransition>
      <PageHeader
        pageTitle={
          ledger?.deletedAt ? `${ledger?.name} (Archived)` : ledger?.name
        }
        breadcrumbs={[
          ["All Ledgers", "/ledgers/"],
          [ledger?.name, `/ledgers/${ledger?.id}/definitions`],
          ["New definition", undefined],
        ]}
      />
      <main className="bg-white rounded-lg w-full shadow-sm">
        <LedgerNav ledger={ledger} />
        <section className="p-4 md:p-6">
          <DefinitionNewForm />
        </section>
      </main>
    </RouteTransition>
  );
};

export default DefinitionNew;
