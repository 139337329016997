import { useParams } from "react-router-dom";
import EntryFormSelection from "./entry/EntryFormSelection";
import LedgerNav from "./ledger/LedgerNav";
import RouteTransition from "./layout/RouteTransition";
import PageHeader from "./layout/PageHeader";
import { ledgerQuery } from "@utils/queries";
import { useQuery } from "@tanstack/react-query";

const EntryNew = () => {
  const { id = "" } = useParams();

  let { data: ledger } = useQuery(["ledger", id], () => ledgerQuery(id), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
  return (
    <RouteTransition>
      <PageHeader
        pageTitle={
          ledger?.deletedAt ? `${ledger?.name} (Archived)` : ledger?.name
        }
        breadcrumbs={[
          ["All Ledgers", "/ledgers/"],
          [ledger?.name, undefined],
        ]}
      />
      <main className="bg-white rounded-lg w-full shadow-sm">
        <LedgerNav ledger={ledger} />
        <section className="p-4 md:p-6">
          <EntryFormSelection ledger={ledger} />
        </section>
      </main>
    </RouteTransition>
  );
};

export default EntryNew;
