import { useNavigate } from "react-router-dom";
import DefinitionItem from "./DefinitionItem";
import classNames from "classnames";

type DefinitionListProps = {
  ledger: any;
};

const DefinitionList: React.FunctionComponent<DefinitionListProps> = ({
  ledger,
}: DefinitionListProps) => {
  const navigate = useNavigate();
  if (!ledger) return null;
  return (
    <div className="flex flex-col -mx-6 mt-12 px-6 pt-12 border-t border-gray-200">
      <div className="flex justify-between items-center mb-3">
        <h3 className="text-xl font-semibold">
          {ledger.definitions.length}{" "}
          {ledger.definitions.length > 1 ? "definitions" : "definition"}
        </h3>
        <button
          className={classNames(
            "flex items-center py-2 px-3 border border-transparent text-sm font-semibold rounded-md",
            ledger.deletedAt
              ? "cursor-not-allowed  text-gray-400 bg-light-blue-500"
              : "text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          )}
          onClick={() => {
            navigate(`/ledgers/${ledger.id}/definitions/new`);
          }}
          disabled={ledger.deletedAt}
        >
          New Definition
        </button>
      </div>
      <div className="overflow-x-auto md:overflow-x-visible">
        <div className="py-2 align-middle inline-block min-w-full">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                  >
                    Created At
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                  >
                    Fields
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {ledger.definitions.map((definition: any) => (
                  <DefinitionItem key={definition.id} definition={definition} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DefinitionList;
