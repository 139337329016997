import { useState } from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { Formik, ErrorMessage, Field, Form } from "formik";
import * as Yup from "yup";
import LedgerService from "../../services/LedgerService";
import FormSuccess from "../layout/FormSuccess";
import FormError from "../layout/FormError";
import Modal from "@components/layout/Modal";
import Loading from "@components/shared/Loading";

const ledgerService = new LedgerService();

const LedgerForm: React.FunctionComponent = () => {
  const [success, setSuccess] = useState(false);
  const [formErrors, setFormErrors] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <Formik
        initialValues={{
          name: "",
          description: "",
          ledgerPlatform: 1,
          enforcementMode: 1,
          encryptionMode: "none",
        }}
        validationSchema={Yup.object({
          name: Yup.string().required("Ledger name is required"),
          ledgerPlatform: Yup.string().required("You must select a platform"),
          enforcementMode: Yup.string().required(
            "You must select the data definition strictness"
          ),
        })}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          setFormErrors([]);
          setIsLoading(true);

          ledgerService
            .create(values)
            .then(({ data }) => {
              setSuccess(true);
              actions.resetForm();
              setTimeout(() => {
                navigate(`/ledgers/${data.id}`);
              }, 3000);
            })
            .catch(({ response }) => {
              if (!response.data.errors) return;
              const errors = response.data.errors.reduce(
                (accumulator: any, error: any) => {
                  if (error.field) {
                    accumulator.push(`${error.field}: ${error.message}`);
                  }
                  return accumulator;
                },
                []
              );
              setFormErrors(errors);
            })
            .finally(() => {
              actions.setSubmitting(false);
              setIsLoading(false);
            });
        }}
      >
        {(props) => {
          const buttonClass = classNames(
            "justify-center py-3 px-5 border border-transparent shadow-sm font-medium rounded w-full text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500",
            {
              "bg-blue-600 hover:bg-blue-700": props.values.name,
              "bg-gray-400": !props.values.name,
            }
          );
          const buttonClassDisabled = classNames(
            "cursor-not-allowed justify-center py-3 px-5 border border-transparent shadow-sm font-medium rounded w-full text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500",
            {
              "bg-gray-400": true,
            }
          );
          return (
            <div>
              <Form className="rounded">
                <FormError
                  show={formErrors.length > 0}
                  errors={formErrors}
                  title="Unable to save ledger - errors"
                />

                <FormSuccess show={success}>
                  Ledger has been saved. Sending you to your new ledger to
                  finalise.
                </FormSuccess>

                <div className="max-w-2xl mx-auto my-6">
                  <h4 className="text-2xl font-semibold mb-6">
                    Create a new Ledger
                  </h4>

                  <p className="text-gray-700 mb-6">
                    Begin the process of storing your data by creating a ledger.
                    The ledger can take multiple different types of data, but
                    they should be related to the same subject. For example, a
                    Small Business ledger might include expenses, incoming
                    revenue, hr events such as new hires, taxation lodgements
                    and more.{" "}
                  </p>
                  <hr className="my-4" />
                  <div className="w-full md:w-2/3 mx-auto p-4 md:p-10 rounded-lg">
                    <label
                      htmlFor="name"
                      className="block font-semibold text-gray-900"
                    >
                      Name for your Ledger
                    </label>
                    <p className="text-sm text-gray-500 my-3">
                      A good name will make it easier to manage your ledger
                    </p>
                    <Field
                      name="name"
                      className="w-full textfield mt-1"
                      placeholder="eg Water Quality Tests"
                    />
                    <ErrorMessage
                      component="p"
                      className="sm:text-xs text-red-600 pt-2"
                      name="name"
                    />

                    <label
                      htmlFor="name"
                      className="block font-semibold text-gray-900 mt-10"
                    >
                      Ledger Description
                      <span className="font-normal ml-1 text-gray-500">
                        (Optional)
                      </span>
                    </label>
                    <p className="text-sm text-gray-500 my-3">
                      Describe your ledger, what it is and how you intend to use
                      it. This will help you identify your ledgers later.
                    </p>
                    <Field
                      as="textarea"
                      name="description"
                      className="textfield w-full mt-1 mb-10"
                    />

                    {isLoading && (
                      <button disabled={true} className={buttonClassDisabled}>
                        Create your new Ledger
                      </button>
                    )}
                    {!isLoading && (
                      <button
                        type="submit"
                        disabled={props.isSubmitting}
                        className={buttonClass}
                      >
                        Create your new Ledger
                      </button>
                    )}
                  </div>
                </div>
              </Form>
              <Modal open={isLoading}>
                <div className="flex flex-col">
                  <div className="border-b p-4">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Creating Ledger
                    </h3>
                  </div>
                  <div className="p-4 space-y-5">
                    <Loading />
                  </div>
                </div>
              </Modal>
            </div>
          );
        }}
      </Formik>
    </>
  );
};

export default LedgerForm;
