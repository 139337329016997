import { Link } from "react-router-dom";

import { ReactComponent as IconCollection } from "../../images/icon-collection.svg";

type LedgerSummaryEntriesProps = {
  ledger: any;
};

const LedgerSummaryEntries: React.FunctionComponent<
  LedgerSummaryEntriesProps
> = ({ ledger }: LedgerSummaryEntriesProps) => {
  return (
    <div className="flex flex-col md:flex-row gap-4 border border-gray-300 rounded-md p-4 md:p-6 text-gray-800">
      <div className="flex-shrink-0 text-gray-500">
        <IconCollection />
      </div>
      <div>
        <p className="mb-4">
          You have {ledger.entries.length ? ledger.entries.length : "no"}{" "}
          entries stored on your ledger.
        </p>
        <div className="flex gap-4">
          <Link
            to={`/ledgers/${ledger.id}/entries`}
            className="text-blue-500 hover:text-blue-700 font-semibold"
          >
            View
          </Link>
          {ledger.deletedAt ? (
            <div className="text-blue-200 font-semibold cursor-not-allowed">
              Create
            </div>
          ) : (
            <Link
              to={`/ledgers/${ledger.id}/entries/new`}
              className="text-blue-500 hover:text-blue-700 font-semibold"
            >
              Create
            </Link>
          )}
          <Link
            to={`/ledgers/${ledger.id}/integration`}
            className="text-blue-500 hover:text-blue-700 font-semibold"
          >
            Integrate an API
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LedgerSummaryEntries;
