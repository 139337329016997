import { useParams } from "react-router-dom";

import RouteTransition from "./layout/RouteTransition";
import PageHeader from "./layout/PageHeader";

import LedgerIntegration from "./ledger/LedgerIntegration";
import LedgerNav from "./ledger/LedgerNav";
import { useQuery } from "@tanstack/react-query";
import { ledgerQuery } from "@utils/queries";

const Integration: React.FunctionComponent = () => {
  const { id = "" } = useParams();

  let { data: ledger } = useQuery(["ledger", id], () => ledgerQuery(id), {
    keepPreviousData: true,
  });
  if (!ledger) return null;
  return (
    <RouteTransition>
      <PageHeader
        pageTitle={ledger.deletedAt ? `${ledger.name} (Archived)` : ledger.name}
        breadcrumbs={[
          ["All Ledgers", "/ledgers/"],
          [ledger.name, undefined],
        ]}
      />
      <main className="bg-white rounded-lg w-full shadow-sm">
        <LedgerNav ledger={ledger} />
        <section className="p-4 md:p-6">
          <LedgerIntegration ledger={ledger} />
        </section>
      </main>
    </RouteTransition>
  );
};

export default Integration;
