import NavItem from "../layout/NavItem";

import { ReactComponent as IconLedgerOutline } from "../../images/icon-ledger-outline.svg";
import { ReactComponent as IconTable } from "../../images/icon-table.svg";
import { ReactComponent as IconCode } from "../../images/icon-code.svg";
import { ReactComponent as IconCollection } from "../../images/icon-collection.svg";
import { ReactComponent as IconSettings } from "../../images/icon-settings.svg";

type LedgerNavProps = {
  ledger: any;
};

const LedgerNav: React.FunctionComponent<LedgerNavProps> = ({
  ledger,
}: LedgerNavProps) => {
  const path = window.location.pathname.split("/");
  const ledgerPath = `/ledgers/${ledger?.id ?? ""}`;
  const section = path[3];
  const isNew = path[4] === "new";

  const navListActiveClass =
    "bg-gray-100 md:bg-transparent md:border-b-2 border-gray-900 text-gray-900";
  const navItemIconClass =
    "w-4 h-4 lg:w-6 lg:h-6 md:hidden lg:block lg:opacity-40 mr-1 lg:mr-0";
  const navItemClass =
    "flex md:h-14 items-center gap-1.5 xl:gap-2 px-3 md:px-1.5 xl:px-3 py-3 text-gray-500 hover:bg-gray-100 hover:text-gray-800 text-sm font-semibold";

  return (
    <ul
      className="flex flex-col md:flex-row gap-0 md:gap-4 px-0 md:px-4 md:h-14 rounded-tl-md rounded-tr-md md:rounded-none overflow-hidden"
      style={{ boxShadow: "inset 0 -1px #E5E7EB" }}
    >
      <li>
        <NavItem
          to={ledgerPath}
          isActive={!section}
          activeClassName={navListActiveClass}
          className={navItemClass}
        >
          <span className={navItemIconClass}>
            <IconLedgerOutline className="w-auto" />
          </span>
          <span>Ledger Outline</span>
        </NavItem>
      </li>
      <li>
        <NavItem
          to={`${ledgerPath}/definitions`}
          isActive={section === "definitions" && !isNew}
          activeClassName={navListActiveClass}
          className={navItemClass}
        >
          <span className={navItemIconClass}>
            <IconTable className="w-auto" />
          </span>
          <span>Data Definitions</span>
        </NavItem>
      </li>
      <li>
        <NavItem
          to={`${ledgerPath}/integration`}
          isActive={section === "integration"}
          activeClassName={navListActiveClass}
          className={navItemClass}
        >
          <span className={navItemIconClass}>
            <IconCode className="w-auto" />
          </span>
          <span>API Integration</span>
        </NavItem>
      </li>
      <li>
        <NavItem
          to={`${ledgerPath}/entries`}
          isActive={section === "entries" && !isNew}
          activeClassName={navListActiveClass}
          className={navItemClass}
        >
          <span className={navItemIconClass}>
            <IconCollection className="w-auto" />
          </span>
          <span>Entry List</span>
        </NavItem>
      </li>
      <li>
        <NavItem
          to={`${ledgerPath}/settings`}
          isActive={section === "settings"}
          activeClassName={navListActiveClass}
          className={navItemClass}
        >
          <span className={navItemIconClass}>
            <IconSettings className="w-auto" />
          </span>
          <span>Settings</span>
        </NavItem>
      </li>
    </ul>
  );
};

export default LedgerNav;
