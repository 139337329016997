import ErrorLayout from "@components/layout/ErrorLayout";

const LedgerNotFoundError = () => {
  return (
    <ErrorLayout
      title="Ledger Not Found"
      description="Sorry, we couldn't find the ledger you're looking for."
    >
      <a
        href="/ledgers"
        className="inline-block btn-secondary text-sm rounded hover:underline hover:text-blue-500"
      >
        See all ledgers
        <span className="inline-block ml-2" aria-hidden="true">
          &rarr;
        </span>
      </a>
    </ErrorLayout>
  );
};

export default LedgerNotFoundError;
