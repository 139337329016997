import { Link } from "react-router-dom";
import DocumentationNav from "./DocumentationNav";
import RouteTransition from "../layout/RouteTransition";
import PageHeader from "../layout/PageHeader";

// import NewRuleImage from "../../images/documentation-new-rule.png";
// import ListRulesImage from "../../images/documentation-list-rules.png";
const data = {
  entryName: "WaterQualityCertified",
  eventData: {
    signer: "E-6728982",
    certificate: "/certs/e6783bc4e2a.pdf",
    approvedTime: "2021-03-04 20:03:27UTC",
  },
};
const DocumentationIntegration = () => {
  const heading4Class = "text-xl font-semibold mb-4";
  const paragraphClass = "text-gray-700 mb-6";

  return (
    <RouteTransition>
      <PageHeader pageTitle="Documentation" />
      <div className="grid grid-cols-3 gap-6">
        <div className="col-span-3 md:col-span-1">
          <DocumentationNav />
        </div>
        <div className="col-span-3 md:col-span-2">
          <div className="shadow sm:rounded-md">
            <div className="bg-white md:rounded-md overflow-hidden">
              <div className="flex items-center w-full px-4 md:px-6 h-16 md:h-20 border-b border-gray-200">
                <h3 className="text-xl md:text-2xl font-semibold">
                  API Integration
                </h3>
              </div>
              <article className="p-4 md:p-6">
                <h4 className={heading4Class}>Integration</h4>

                <p className={paragraphClass}>
                  Integration is a highly technical process, and should be
                  undertaken by skilled developers familiar with HTTP request
                  protocols. The overall intent is that an event in your system,
                  such as a new user, or an important transaction, triggers your
                  application to send an event request to your custom Ledger
                  endpoint. This then stores the record, which is archived for
                  later potential auditing, or can be retrieved by you from
                  another custom endpoint at any time.
                </p>

                <p className={paragraphClass}>
                  The event is added by using the required date to the Ledger
                  with a specific payload to a defined endpoint. The raw CURL
                  request is provided here.
                </p>

                <code className="block text-xs bg-gray-800 text-gray-100 p-3 rounded-sm ">
                  curl POST -H "Content-Type: application/json" -H "X-API-TOKEN:{" "}
                  <span className="text-yellow-500">[your token]</span>" --data{" "}
                  <span className="text-yellow-500">
                    [your payload] [your endpoint]
                  </span>
                </code>

                <h4 className={`${heading4Class} my-8`}>Request Details</h4>

                <p className={paragraphClass}>
                  The following details the request that needs to be sent. Note
                  that much of the data is custom to your specific needs and in
                  line with your{" "}
                  <Link to="definitions" className="textlink">
                    Entry Definitions
                  </Link>
                  .
                </p>

                <p className={paragraphClass}>
                  Note also that if your ledger is configured for it, or your
                  data violates your defined{" "}
                  <Link to="rules" className="textlink">
                    Entry Rules
                  </Link>{" "}
                  then your request will be rejected with a{" "}
                  <code>422 Unprocessable Entity</code>, with further
                  information about why it was rejected. You should be ready to
                  detect this status code and potentially resubmit corrected
                  data.
                </p>

                <div className="border border-gray-200 rounded-sm m-10">
                  <dl>
                    <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        API TOKEN
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
                        The API token is provided on your Ledger details
                        integration page. It is a long string of random numbers.
                        This must be passed in as a{" "}
                        <span className="font-semibold">header</span>, not as
                        part of the payload.
                      </dd>
                    </div>
                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        Endpoint
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
                        The custom endpoint for your application. This will take
                        a <code>POST</code> request, and consists of the pattern
                        [our url]/[ledger id].
                      </dd>
                    </div>
                    <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        Payload
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
                        The most complex part of the request, the payload is the
                        data sent. It is important to note that the payload is
                        sent without a root envelope, and that the only property
                        in the root is <code>entryName</code>. Your custom data
                        goes in the <code>eventData</code> sent.
                      </dd>
                    </div>
                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        Request overview
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
                        <dl>
                          <div className="bg-gray-50 px-3 py-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                              URL
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
                              The endpoint provided
                            </dd>
                          </div>
                          <div className="bg-white px-3 py-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                              Method
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
                              POST
                            </dd>
                          </div>
                          <div className="bg-gray-50 px-3 py-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                              Headers
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
                              <code className="text-xs">
                                Content-Type: application/json
                                <br />
                                X-API-TOKEN: a156424ff2a26e4f9bad
                              </code>
                            </dd>
                          </div>
                          <div className="bg-white px-3 py-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                              Payload
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
                              <code className="text-xs">
                                <pre>{JSON.stringify(data, null, 2)}</pre>
                              </code>
                            </dd>
                          </div>
                        </dl>
                      </dd>
                    </div>
                  </dl>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </RouteTransition>
  );
};

export default DocumentationIntegration;
