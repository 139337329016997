import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { ledgerService } from "../../services/LedgerService";
import { useQueryClient } from "@tanstack/react-query";

type LedgerSettingsProps = {
  ledger: any;
};

const LedgerSettings: React.FunctionComponent<LedgerSettingsProps> = ({
  ledger,
}: LedgerSettingsProps) => {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [sendingRequest, setSendingRequest] = useState(false);

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const handleClick = (event: any) => {
    event.preventDefault();
    if (confirmDelete) {
      setSendingRequest(true);
      ledgerService.delete(ledger.id).then(() => {
        setTimeout(async () => {
          setConfirmDelete(false);
          setSendingRequest(false);
          queryClient.invalidateQueries(["ledger", ledger.id]);
          navigate(`/ledgers/${ledger.id}`);
        }, 1300);
      });
    }
    setConfirmDelete(true);
  };
  return (
    <div className="max-w-2xl mx-auto my-6">
      <h3 className="text-2xl font-semibold mb-4">Ledger Settings</h3>

      <p className="text-gray-700 mb-6">
        Modify ledger details and configuration, availability and visibility.
      </p>

      <button
        className={`flex items-center justify-center py-2 px-6 border border-transparent text-base font-semibold rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 ${
          ledger.deletedAt ? "opacity-25 cursor-not-allowed" : ""
        }`}
        disabled={!!ledger.deletedAt}
        onClick={handleClick}
      >
        {!sendingRequest && (
          <span>
            <i className="fal fa-trash fa-fw mr-3"></i>
          </span>
        )}
        {sendingRequest && (
          <span>
            <i className="fal fa-spinner fa-fw fa-spin mr-3"></i>
          </span>
        )}

        {!confirmDelete && <>Delete this ledger</>}
        {confirmDelete && <>Confirm Deletion</>}
      </button>
    </div>
  );
};

export default LedgerSettings;
