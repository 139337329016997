import LedgerService from "../services/LedgerService";
import EntryService from "../services/EntryService";
import RuleService from "../services/RuleService";
import NotificationService from "@services/NotificationService";
import StatisticsService from "@services/StatisticsService";
import CompanyService from "@services/CompanyService";
import MembershipService from "@services/MembershipService";

const ledgerService = new LedgerService();
const entryService = new EntryService();
const ruleService = new RuleService();
const notificationService = new NotificationService();
const statisticsService = new StatisticsService();
const companyService = new CompanyService();
const membershipService = new MembershipService();

export const ledgersQuery = async () => {
  const { data } = await ledgerService.getAll();
  return data;
};

export const ledgerQuery = async (id: string) => {
  const { data } = await ledgerService.getOne(id);
  return data;
};

export const companyQuery = async () => {
  const { data } = await companyService.getAll();
  return data;
};

export const entryQuery = async (id: string) => {
  const { data } = await entryService.getOne(id);
  return data;
};

export const statisticsQuery = async (id: string) => {
  const { data } = await statisticsService.getOne(id);
  return data;
};

export const membershipQuery = async (companyId: string) => {
  const { data } = await membershipService.getOne(companyId);
  return data;
};

export const B2CMembershipQuery = async (id: string, companyName: string) => {
  const { data } = await membershipService.getOneByIdAndCompany(
    id,
    companyName
  );
  return data;
};

export const entriesQuery = async () => {
  const { data } = await entryService.getAll();
  return data;
};

export const rulesQuery = async (definitionId: string) => {
  if (!definitionId) return [];
  const { data } = await ruleService.getAll({ definitionId });
  return data;
};

export const notificationsQuery = async () => {
  const { data } = await notificationService.getAll();
  return data;
};
