import { useState } from "react";
import classNames from "classnames";

import LedgerService from "../../services/LedgerService";

import LedgerFormDefinitionSelect from "./LedgerFormDefinitionSelect";

import { ReactComponent as IconShieldExclamation } from "../../images/icon-shield-exclamation.svg";
import { useQueryClient } from "@tanstack/react-query";

const ledgerService = new LedgerService();

type LedgerSummaryEnforcementProps = {
  ledger: any;
};

const LedgerSummaryEnforcement: React.FunctionComponent<
  LedgerSummaryEnforcementProps
> = ({ ledger }: LedgerSummaryEnforcementProps) => {
  const [showModify, setShowModify] = useState(false);
  const [saving, setSaving] = useState(false);
  const queryClient = useQueryClient();

  const { enforcementMode } = ledger;
  const iconClass = classNames("flex-shrink-0", {
    "text-yellow-500": enforcementMode === 1 || enforcementMode === 2,
    "text-green-500": enforcementMode === 3,
  });

  const setEnforcementMode = async (value: any) => {
    setSaving(true);
    ledgerService.update(ledger.id, { enforcementMode: value }).then(() => {
      setTimeout(() => {
        queryClient.invalidateQueries(["ledger", ledger.id]);
        setShowModify(false);
        setSaving(false);
      }, 1600);
    });
  };
  return (
    <>
      <div className="flex flex-col md:flex-row gap-4 border border-gray-300 rounded-md p-4 md:p-6 text-gray-800">
        <div className={iconClass}>
          <IconShieldExclamation />
        </div>
        <div>
          <p>
            {ledger.enforcementMode === 1 && (
              <>
                Your ledger is currently set up with no enforcement of data
                structure and you can send any data you like.
              </>
            )}
            {ledger.enforcementMode === 2 && (
              <>
                Your ledger is set to enforce the structure of defined events,
                but will still allow undefined data types.{" "}
              </>
            )}
            {ledger.enforcementMode === 3 && (
              <>
                Your ledger is set to only accept strictly defined data. This
                will provide the most consistent and structured data, but may
                lack flexibility.
              </>
            )}
          </p>
          {!showModify && (
            <button
              className="text-blue-500 hover:text-blue-700 font-semibold mt-4"
              onClick={() => setShowModify(true)}
            >
              Change enforcement
            </button>
          )}

          {showModify && (
            <div className="flex mt-4">
              <div className="w-96">
                <LedgerFormDefinitionSelect
                  update={setEnforcementMode}
                  saving={saving}
                  preset={ledger.enforcementMode}
                />
              </div>
              {saving && (
                <div className="p-2">
                  <i className="fal fa-spinner fa-spin"></i> Saving
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default LedgerSummaryEnforcement;
