import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { Field, ErrorMessage } from "formik";
import { parseISO } from "date-fns";
import classNames from "classnames";
import DateTimePicker from "react-datetime-picker";
import Dropzone from "react-dropzone";
import Select from "react-select";

import { documentService } from "../../services/DocumentService";
import { useQuery } from "@tanstack/react-query";
import { ledgerQuery } from "@utils/queries";
import { formatEntry, truncateText } from "@utils/format";

const largeFields = [1, 6, 7, 11];

const placeholders = {
  7: "eg 35.6945628,139.7003868,17z",
  10: "eg 0xa87833f17dbb73c5da15ab47e4f30a237565e61e",
};

type EntryDynamicFieldProps = {
  field: any;
  setFieldValue: any;
  value: any;
  rules: any;
  ledger: any;
  allLedgers: any;
};

const EntryDynamicField: React.FunctionComponent<EntryDynamicFieldProps> = ({
  field,
  setFieldValue,
  value,
  rules,
  ledger,
  allLedgers,
}: EntryDynamicFieldProps) => {
  const containerClasses = classNames({
    "col-span-2": largeFields.includes(field.type),
  });
  const labelClasses = classNames("block text-sm font-medium text-gray-700");
  const [uploadedFile, setUploadedFile] = useState<any>(null);
  const [selectedLedgerId, setSelectedLedgerId] = useState("");
  const [selectedDefinition, setSelectedDefinition] = useState("");
  const [selectedEntryId, setSelectedEntryId] = useState(
    field.isMultiple ? [] : ""
  );

  const applicableRules = rules
    ? rules.filter((rule: any) => rule.fieldId === field.id)
    : [];
  const isRequired = !!applicableRules.find(({ type }: any) => type === 1);
  const placeholder = placeholders[field.type as keyof typeof placeholders];

  let { data: selectedLedger } = useQuery(
    ["ledger", selectedLedgerId, "field", field.id],
    () => selectedLedgerId && ledgerQuery(selectedLedgerId),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSuccess: () => {
        setSelectedDefinition("");
        setSelectedEntryId(field.isMultiple ? [] : "");
        setFieldValue(field.name, field.isMultiple ? [] : "");
      },
    }
  );

  const handleLinkLedgerChange = (ledgerId: string) => {
    setSelectedLedgerId(ledgerId);
    if (!ledgerId) {
      setSelectedDefinition("");
      setSelectedEntryId(field.isMultiple ? [] : "");
      setFieldValue(field.name, field.isMultiple ? [] : "");
    }
  };

  const handleLinkDefinitionChange = (entryName: string) => {
    setSelectedDefinition(entryName);
    setSelectedEntryId(field.isMultiple ? [] : "");
    setFieldValue(field.name, field.isMultiple ? [] : "");
  };

  const handleLinkEntryChange = (selectedOption: any) => {
    setSelectedEntryId(selectedOption);

    const entryId = field.isMultiple
      ? selectedOption.map((option: any) => option.value)
      : selectedOption.value;
    if (entryId && entryId.length > 0) {
      if (field.isMultiple) {
        const selectedEntries = selectedLedger.entries
          ?.filter((entry: any) => entryId.indexOf(entry.id) !== -1)
          .map((entry: any) => ({
            ledgerId: selectedLedgerId,
            ledgerName: selectedLedger.name,
            entry: entry,
          }));

        setFieldValue(field.name, selectedEntries);
      } else {
        const selectedEntry = selectedLedger.entries?.find(
          (entry: any) => entry.id === entryId
        );
        setFieldValue(field.name, {
          ledgerId: selectedLedgerId,
          ledgerName: selectedLedger.name,
          entry: selectedEntry,
        });
      }
    } else {
      setFieldValue(field.name, field.isMultiple ? [] : "");
    }
  };

  useEffect(() => {
    if (field.type === 11 && !value) {
      setSelectedLedgerId("");
      setSelectedDefinition("");
      setSelectedEntryId(field.isMultiple ? [] : "");
    }
  }, [field, value]);

  return (
    <div className={containerClasses}>
      <label htmlFor={field.name} className={labelClasses}>
        {field.label || field.name}
        {isRequired && <span className="text-red-500 ml-2">*</span>}
      </label>

      {field.type === 3 && (
        <DateTimePicker
          className="w-full"
          onChange={(date: any) => setFieldValue(field.name, date)}
          name={field.name}
          minDate={startDate(rules)}
          maxDate={endDate(rules)}
          maxDetail="second"
          required={isRequired}
          value={value}
        />
      )}

      {field.type === 4 && (
        <Field
          type="checkbox"
          name={field.name}
          checked={value}
          className="mt-3"
        />
      )}

      {field.type === 5 && (
        <DatePicker
          className="w-full border-2 border-gray-300 rounded-lg"
          selected={value}
          onChange={(date: any) => setFieldValue(field.name, date)}
          name={field.name}
          minDate={startDate(rules)}
          maxDate={endDate(rules)}
          required={isRequired}
          placeholderText="Select a date"
        />
      )}

      {field.type === 6 && (
        <div>
          {!uploadedFile && (
            <Dropzone
              onDrop={async ([file]) => {
                const request = await documentService.upload({
                  title: file.name,
                  ledgerId: ledger.id,
                  document: file,
                });

                const data = await request.json();
                setUploadedFile(data);
                setFieldValue(field.name, data.location);
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <section className="bg-gray-100 p-10 text-center cursor-pointer border-dashed border-2">
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />

                    <p>
                      <i className="fal fa-file-upload mr-2"></i>Drop some files
                      here, or click to select files
                    </p>
                  </div>
                </section>
              )}
            </Dropzone>
          )}
          {uploadedFile && (
            <div className="border p-2">
              File{" "}
              <span className="font-mono text-gray-800 text-sm bg-gray-200 p-1">
                {uploadedFile.title}
              </span>{" "}
              uploaded
            </div>
          )}
        </div>
      )}

      {field.type === 8 && (
        <select
          onChange={({ target }) => {
            setFieldValue(field.name, target.value);
          }}
          name="enumSelect"
          className="textfield mb-5 "
          value={value || ""}
        >
          <option value="">Select an option</option>
          {field.value?.split(",").map((listItem: any) => {
            return (
              <option value={listItem} key={listItem}>
                {listItem}
              </option>
            );
          })}
        </select>
      )}

      {field.type === 11 && (
        <>
          <select
            className="textfield w-full"
            value={selectedLedgerId}
            onChange={(e) => handleLinkLedgerChange(e.target.value)}
          >
            <option value="">Select the ledger</option>
            {allLedgers?.map((le: any) => (
              <option value={le.id} key={le.id}>
                {le.name}
              </option>
            ))}
          </select>
          {selectedLedgerId && (
            <select
              className="textfield w-full mt-2"
              value={selectedDefinition}
              onChange={(e) => handleLinkDefinitionChange(e.target.value)}
            >
              <option value="">Select the entry type</option>
              {selectedLedger?.definitions?.map((def: any) => (
                <option value={def.entryName} key={def.id}>
                  {def.entryName}
                </option>
              ))}
            </select>
          )}
          {selectedLedgerId && selectedDefinition && (
            <Select
              className="text-sm w-full mt-2"
              placeholder="Select an entry to link to this"
              options={selectedLedger?.entries
                ?.filter((entry: any) => entry.name === selectedDefinition)
                .map((entry: any) => ({
                  label: truncateText(formatEntry(entry), 100),
                  value: entry.id,
                }))}
              value={selectedEntryId}
              onChange={handleLinkEntryChange}
              isMulti={field.isMultiple}
              isClearable={true}
              backspaceRemovesValue={true}
              components={{ ClearIndicator: undefined }}
              required={isRequired}
            />
          )}
        </>
      )}

      {![3, 4, 5, 6, 8, 11].includes(field.type) && (
        <Field
          name={field.name}
          className="textfield w-full"
          onChange={({ target }: any) => {
            setFieldValue(field.name, target.value);
          }}
          required={isRequired}
          placeholder={placeholder}
        />
      )}
      <ErrorMessage
        component="p"
        className="sm:text-sm text-red-600"
        name={field.name}
      />
    </div>
  );
};

const startDate = (rules: any) => {
  const rule = rules.find(({ type }: any) => type === 4);
  return rule ? parseISO(rule.value) : new Date(1900, 0, 1);
};

const endDate = (rules: any) => {
  const rule = rules.find(({ type }: any) => type === 5);
  return rule ? parseISO(rule.value) : new Date(2100, 0, 1);
};

export default EntryDynamicField;
