import SpinnerIcon from "@components/icons/SpinnerIcon";
import classNames from "classnames";

const Loading: React.FunctionComponent<any> = (props) => {
  const { size = 40, className = "", loadingText = "Loading..." } = props;
  return (
    <div className="flex flex-col items-center">
      <SpinnerIcon
        width={size}
        height={size}
        className={classNames("text-primary-1", className)}
      />

      <span className="text-xs text-gray-400 mt-2">{loadingText}</span>
    </div>
  );
};

export default Loading;
