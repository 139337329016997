import packageJson from "../../../package.json";
import dataskerLogo from "../../images/DataskerLogoBlue.png";

type AuthLayoutProps = {
  children: React.ReactNode;
};

const AuthLayout: React.FunctionComponent<AuthLayoutProps> = ({ children }) => {
  return (
    <div
      className="flex flex-col w-full mt-24 rounded-lg antialiased bg-white justify-between"
      style={{
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        zIndex: 10,
      }}
    >
      <header className="bg-white w-full text-xl md:text-2xl flex rounded-lg px-10 items-center h-24 border font-bold tracking-tight text-gray-900 sm:text-4xl">
        Error Page
      </header>
      <section className="m-auto w-full py-6 mt-10 sm:w-1/2 xl:w-1/3 px-3 lg:px-6 rounded border bg-gray-100">
        <img
          src={dataskerLogo}
          alt="Datasker"
          className="sm:h-20 h-16 m-auto mb-6"
        />
        {children}
      </section>
      <footer>
        <div className="space-y-3 mx-auto mb-12 mt-4 px-4 sm:px-6 lg:px-8 text-sm text-gray-700 text-center">
          <p className="text-xs">Version: {packageJson.version}</p>
        </div>
      </footer>
    </div>
  );
};

export default AuthLayout;
