import LedgerItem from "./LedgerItem";

type LedgerListProps = {
  ledgers: any[];
};

const LedgerList: React.FunctionComponent<LedgerListProps> = ({
  ledgers,
}: LedgerListProps) => {
  return (
    <ul className="divide-y divide-gray-100">
      {!ledgers.length && (
        <div className="text-center px-4 py-24 md:py-44 text-gray-400">
          You currently have no ledgers.
        </div>
      )}
      {ledgers.map((ledger) => (
        <LedgerItem key={ledger.id} ledger={ledger} />
      ))}
    </ul>
  );
};

export default LedgerList;
