import { motion, AnimatePresence } from "framer-motion";
import { ReactNode } from "react";

type FormSuccessProps = {
  show: boolean;
  children: ReactNode;
};

const FormSuccess: React.FunctionComponent<FormSuccessProps> = ({
  children,
  show,
}: FormSuccessProps) => {
  return (
    <AnimatePresence>
      {show && (
        <motion.div
          key="success"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="mb-5 p-5 bg-green-200 rounded w-full"
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default FormSuccess;
