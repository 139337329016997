import DeleteTableData from "../layout/DeleteTableData";
import RuleService from "../../services/RuleService";
import EditInPlace from "../layout/EditInPlace";
import EditDateTime from "../layout/EditDateTime";
import { fieldTypes, ruleTypeConditions } from "@utils/lookups";
import { shortDate, shortTime } from "@utils/format";

const ruleService = new RuleService();

type RuleItemProps = {
  rule: any;
};

const componentSelector: React.FunctionComponent<RuleItemProps> = ({
  rule,
}) => {
  if (!rule) return null;
  const isEditTypeRule = [2, 3, 6, 7, 8, 9].includes(rule.type);
  const isDateField = rule.field.type === 5;
  const isDateTimeField = rule.field.type === 3;

  if (isDateField || isDateTimeField)
    return (
      <EditDateTime
        value={rule.value}
        name="value"
        id={rule.id}
        service={ruleService}
        timeIncluded={isDateTimeField}
      />
    );
  if (isEditTypeRule)
    return (
      <EditInPlace
        value={rule.value}
        name="value"
        id={rule.id}
        service={ruleService}
      />
    );

  return rule.value ? rule.value.toString() : "";
};

const RuleItem = ({ rule }: any) => {
  return (
    <tr className="group text-sm">
      <td className="px-4 py-3 align-middle">{rule.field.name}</td>
      <td className="px-4 py-3 align-middle">{rule.field.label}</td>
      <td className="px-4 py-3 align-middle text-center">
        {fieldTypes[rule.field.type as keyof typeof fieldTypes]}
      </td>
      <td className="px-4 py-3 align-middle text-center whitespace-nowrap">
        {ruleTypeConditions[rule.type as keyof typeof ruleTypeConditions]}
      </td>
      <td className="px-4 py-3 align-middle whitespace-nowrap">
        <div className="flex justify-center ml-5">
          {componentSelector({ rule })}
        </div>
      </td>
      <td className="px-4 py-3 align-middle text-center whitespace-nowrap">
        <span className="mr-2">{shortDate(rule.createdAt)}</span>
        <span className="text-gray-500">{shortTime(rule.createdAt)}</span>
      </td>
      <td className="px-4 py-3">
        <DeleteTableData id={rule.id} service={new RuleService()} />
      </td>
    </tr>
  );
};
export default RuleItem;
