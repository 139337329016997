import { createContext } from "react";

export const ACTIONS = {
  SET_USER: "SET_USER",
  UPDATE_USER: "UPDATE_USER",
  CLEAR_USER: "CLEAR_USER",
};

export const defaultLoginState = { user: null, token: null, loggedIn: false };

export const loginReducer = (
  state = defaultLoginState,
  { type, payload }: any
) => {
  switch (type) {
    case ACTIONS.CLEAR_USER:
      return defaultLoginState;
    case ACTIONS.SET_USER:
      return { ...payload };
    case ACTIONS.UPDATE_USER:
      return { ...state, ...payload };
    default:
      return { ...state };
  }
};

type ContextValue = {
  login: any;
  dispatch: React.Dispatch<any>;
};

export const LoginContext = createContext<ContextValue>({} as ContextValue);
