import DocumentationNav from "./DocumentationNav";
import RouteTransition from "../layout/RouteTransition";
import PageHeader from "../layout/PageHeader";

import NewLedgerImage from "../../images/documentation-new-ledger.png";

const Documentation = () => {
  const heading4Class = "text-xl font-semibold mb-4";
  const paragraphClass = "text-gray-700 mb-6";

  return (
    <RouteTransition>
      <PageHeader pageTitle="Documentation" />
      <div className="grid grid-cols-3 gap-6">
        <div className="col-span-3 md:col-span-1">
          <DocumentationNav />
        </div>
        <div className="col-span-3 md:col-span-2">
          <div className="shadow sm:rounded-md">
            <div className="bg-white md:rounded-md overflow-hidden">
              <div className="flex items-center w-full px-4 md:px-6 h-16 md:h-20 border-b border-gray-200">
                <h3 className="text-xl md:text-2xl font-semibold">
                  Getting Started
                </h3>
              </div>
              <article className="p-4 md:p-6">
                <h4 className={heading4Class}>What is a Ledger?</h4>

                <p className={paragraphClass}>
                  A ledger is a collection of data about a given subject or
                  business area. It can hold multiple different types of{" "}
                  <span className="italic">entries</span>. Entries don't all
                  have to be about the same events or transactions, but they
                  should be of interest to the same people or business entities.
                </p>

                <p className={paragraphClass}>
                  For example, if you created an ESG compliance ledger for a
                  construction company, it might include a number of different
                  ESG compliance events and interactions, such as site
                  inspections, lab test reports, water usage reports, and more.
                </p>
                <p className={paragraphClass}>
                  When you begin using Datasker you will start with no ledgers,
                  and can add whatever is needed .
                </p>

                <div className="grid grid-cols-1 gap-3 lg:grid-cols-2 mt-10">
                  <div>
                    <h4 className={heading4Class}>Creating a Ledger</h4>

                    <p className={paragraphClass}>
                      To create a new ledger all you have to do is go to the new
                      ledger form and enter a name for your ledger. You can also
                      add a description to make it more clear to other people
                      (as well as yourself) what data this ledger is intended to
                      store.
                    </p>

                    <p className={paragraphClass}>
                      Click on "Create Your new Ledger" and the core ledger
                      information will be saved and ready for you to begin
                      customising for your needs.
                    </p>
                  </div>
                  <div>
                    <img
                      src={NewLedgerImage}
                      alt="Create New Ledger Interface"
                      className="border shadow-lg relative object-cover left-10"
                    />
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </RouteTransition>
  );
};

export default Documentation;
