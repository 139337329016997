import React, { useState } from "react";

interface ShowOptionsProps {
  type: string;
  value?: string;
}

const ShowOptionsButton: React.FC<ShowOptionsProps> = ({ type, value }) => {
  const [showOptions, setShowOptions] = useState(false);

  if (type !== "8") return null;

  return (
    <>
      {!showOptions && (
        <div>
          {value && <li>{value.split(",")[0]} </li>}
          {value && value.split(",").length > 1 && (
            <button
              className="font-bold text-blue-500 text-base"
              onClick={() => setShowOptions(!showOptions)}
            >
              See more
            </button>
          )}
        </div>
      )}
      {showOptions && (
        <div>
          {showOptions &&
            (value
              ? value
                  .split(",")
                  .map((subValue, index) => <li key={index}>{subValue}</li>)
              : "")}
          <button
            className="font-bold text-blue-500 text-base"
            onClick={() => setShowOptions(!showOptions)}
          >
            See less
          </button>
        </div>
      )}
    </>
  );
};

export default ShowOptionsButton;
