import LedgerSummaryEntries from "./LedgerSummaryEntries";

type EntriesPanelProps = {
  ledger: any;
};

const EntriesPanel: React.FunctionComponent<EntriesPanelProps> = ({
  ledger,
}: EntriesPanelProps) => {
  return (
    <div>
      <h3 className="text-2xl font-semibold mb-6">Entries</h3>

      <LedgerSummaryEntries ledger={ledger} />
    </div>
  );
};
export default EntriesPanel;
