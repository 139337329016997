import { Link } from "react-router-dom";

type StatsProps = {
  ledgers: any[];
  entries: any[];
};

const Stats: React.FunctionComponent<StatsProps> = ({
  ledgers,
  entries,
}: StatsProps) => {
  const latestEntry = entries.length ? entries[entries.length - 1] : false;

  return (
    <div className="my-10 md:mx-32 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
      <div className="bg-white overflow-hidden shadow-sm rounded-lg">
        <div className="p-5">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <i className="fal fa-clipboard-list-check fa-lg text-gray-500"></i>
            </div>
            <div className="ml-5 w-0 flex-1">
              <dl>
                <dt className="text-sm font-medium text-gray-500 truncate">
                  Ledgers
                </dt>
                <dd>
                  <div className="text-lg font-medium text-gray-900">
                    There are {ledgers.length} Ledgers
                  </div>
                </dd>
              </dl>
            </div>
          </div>
        </div>
        <div className="border-t border-gray-100 px-5 py-3">
          <div className="text-sm">
            <Link
              to="/ledgers"
              className="font-medium text-gray-500 hover:text-blue-600"
            >
              View all
            </Link>
          </div>
        </div>
      </div>

      <div className="bg-white overflow-hidden shadow-sm rounded-lg">
        <div className="p-5">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <i className="fal fa-clock fa-lg text-gray-500"></i>
            </div>
            <div className="ml-5 w-0 flex-1">
              <dl>
                <dt className="text-sm font-medium text-gray-500 truncate">
                  Events
                </dt>
                <dd>
                  <div className="text-lg font-medium text-gray-900">
                    There are {entries.length} events
                  </div>
                </dd>
              </dl>
            </div>
          </div>
        </div>
        <div className="border-t border-gray-100 px-5 py-3">
          <div className="text-sm">
            <Link
              to="/ledgers"
              className="font-medium text-gray-500 hover:text-blue-600"
            >
              View all
            </Link>
          </div>
        </div>
      </div>

      <div className="bg-white overflow-hidden shadow-sm rounded-lg">
        <div className="p-5">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <i className="fal fa-history fa-lg text-gray-500"></i>
            </div>
            <div className="ml-5 w-0 flex-1">
              <dl>
                <dt className="text-sm font-medium text-gray-500 truncate">
                  Most recent Ledger Entry
                </dt>
                <dd>
                  {latestEntry && (
                    <div className="text-lg font-medium text-gray-900">
                      {latestEntry.name}
                    </div>
                  )}
                  {!latestEntry && (
                    <div className="text-lg font-medium text-gray-900">
                      No entry found
                    </div>
                  )}
                </dd>
              </dl>
            </div>
          </div>
        </div>
        <div className="border-t border-gray-100 px-5 py-3">
          <div className="text-sm">
            <Link
              to="/ledgers"
              className="font-medium text-gray-500 hover:text-blue-600"
            >
              View all
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stats;
