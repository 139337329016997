import { useMutation, useQueryClient } from "@tanstack/react-query";

import { notificationService } from "@services/NotificationService";

export const useReadAllNotifications = (options: Record<string, any> = {}) => {
  const queryClient = useQueryClient();

  return useMutation<any, any>({
    mutationFn: notificationService.readAll,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["notifications"] });
    },
    ...options,
  });
};
