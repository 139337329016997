import { Menu } from "@headlessui/react";

import NotificationIcon from "icons/NotificationIcon";
import { notificationsQuery } from "@utils/queries";
import { useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { formatDistance } from "date-fns";
import { useReadAllNotifications } from "@hooks/mutataion/useReadAllNotifications";

type NotificationButtonProps = {
  user: any;
};

const NotificationButton: React.FunctionComponent<NotificationButtonProps> = ({
  user,
}: NotificationButtonProps) => {
  const { data: notifications = [], isLoading } = useQuery(
    ["notifications"],
    notificationsQuery
  );

  const { mutateAsync: readAllNotifications } = useReadAllNotifications();

  const navigate = useNavigate();
  if (!user || isLoading) return null;

  return (
    <Menu
      as="div"
      className="md:flex items-center justify-end relative md:flex-1 lg:w-0 text-center"
    >
      <Menu.Button className="rounded-full cursor-pointer p-2 hover:bg-gray-700">
        <NotificationIcon />
        {notifications?.filter((n: any) => !n.viewed_at)?.length > 0 && (
          <p className="absolute right-0 top-0 bg-red-600 text-white text-xs font-bold rounded-full h-5 w-5 flex justify-center items-center">
            {notifications?.filter((n: any) => !n.viewed_at)?.length >= 10
              ? "9+"
              : notifications?.filter((n: any) => !n.viewed_at).length}
          </p>
        )}
      </Menu.Button>
      <Menu.Items className="absolute rounded-lg shadow-xl top-16 right-0 bg-white px-4 py-6 z-50 overflow-hidden">
        <h4 className="text-lg font-semibold text-gray-900">
          Your notifications
        </h4>
        <dl className="sm:w-96 w-60 border rounded text-left my-5 overflow-hidden">
          {!notifications.length && (
            <div className="text-center px-4 py-24 md:py-44 text-gray-400">
              You currently have no notifications.
            </div>
          )}
          {notifications?.slice(0, 3).map((item: any, key: number) => {
            return (
              <div
                key={key}
                className={classNames(
                  "p-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6",
                  key % 2 === 0 ? "bg-gray-50" : "bg-white"
                )}
              >
                <dt className="text-sm font-semibold text-gray-500">
                  {item.viewed_at === null && (
                    <span className="inline-block px-2 py-0.5 leading-5 text-xs border rounded-md bg-green-50 text-green-500 border-green-500 border-r-8 mr-2">
                      {" "}
                      New
                    </span>
                  )}
                  {item.relatedMessage?.subject}
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <p className="line-clamp-3">{item.relatedMessage?.body}</p>
                  <p className="text-gray-400 text-xs mt-2">
                    {item.createdAt &&
                      formatDistance(new Date(item.createdAt), new Date(), {
                        addSuffix: true,
                      })}
                  </p>
                </dd>
              </div>
            );
          })}
        </dl>

        <div className="mt-3 flex justify-center">
          <button
            className="flex items-center py-2 px-3 border border-gray-300 shadow-sm text-sm font-semibold rounded-md text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            onClick={async () => {
              navigate("/notifications/system");
              setTimeout(async () => {
                await readAllNotifications();
              }, 2000);
            }}
          >
            <i className="fal fa-solid fa-check-double"></i>
            <span className="pl-2">View All</span>
          </button>
        </div>
      </Menu.Items>
    </Menu>
  );
};

export default NotificationButton;
