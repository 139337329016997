import { Link } from "react-router-dom";

import DocumentationNav from "./DocumentationNav";
import RouteTransition from "../layout/RouteTransition";
import PageHeader from "../layout/PageHeader";

import NewDefinitionImage from "../../images/documentation-new-definition.png";

const DocumentationDefinitions = () => {
  const heading4Class = "text-xl font-semibold mb-4";
  const paragraphClass = "text-gray-700 mb-6";

  return (
    <RouteTransition>
      <PageHeader pageTitle="Documentation" />
      <div className="grid grid-cols-3 gap-6">
        <div className="col-span-3 md:col-span-1">
          <DocumentationNav />
        </div>
        <div className="col-span-3 md:col-span-2">
          <div className="shadow sm:rounded-md">
            <div className="bg-white md:rounded-md overflow-hidden">
              <div className="flex items-center w-full px-4 md:px-6 h-16 md:h-20 border-b border-gray-200">
                <h3 className="text-xl md:text-2xl font-semibold">
                  Data Definitions
                </h3>
              </div>
              <article className="p-4 md:p-6">
                <h4 className={heading4Class}>Defining Data</h4>

                <p className={paragraphClass}>
                  By default your ledger can accept any data sent to it, either
                  through a manual form, or sent by API integration. However
                  most people have specific sets of data that they want to send
                  regularly. Setting these events as a data definition allows
                  them to be validated and provide more structure and
                  consistency.
                </p>

                <h4 className={heading4Class}>Definition Enforcement</h4>

                <p className={paragraphClass}>
                  By default your ledger does not enforce or check for anything
                  about the contents of your data. It can be completely
                  unstructured. Even if you create a data definition, the
                  default behaviour is not to{" "}
                  <span className="font-bold">enforce</span> this.
                </p>

                <p className={paragraphClass}>
                  There are three enforcement modes:
                </p>
                <ol className="m-5">
                  <li>
                    <span className="font-bold">No Enforcement</span> - All data
                    you send to your ledger is accepted.
                  </li>
                  <li>
                    <span className="font-bold">Partial Enforcement</span> -
                    Data validation is run on any defined entries, but undefined
                    entries are allowed.
                  </li>
                  <li>
                    <span className="font-bold">Strict Enforcement</span> - Data
                    validation is mandatory, and undefined entries are rejected.
                  </li>
                </ol>

                <div className="grid grid-cols-1 lg:grid-cols-3 mt-10">
                  <div>
                    <h4 className={heading4Class}>
                      Creating an Entry Definition
                    </h4>

                    <p className={paragraphClass}>
                      On the Create an Entry Definition form, the most important
                      field is the <span className="font-bold">Entry Name</span>{" "}
                      field. This will be the name of the type of data you will
                      be defining. For example, a new user signup event might
                      have an entry name of{" "}
                      <span className="font-bold">UserSignup</span>, and this{" "}
                      <span className="font-bold">must</span> be consistent and
                      correct.
                    </p>
                  </div>
                  <div className="text-center lg:col-span-2">
                    <img
                      src={NewDefinitionImage}
                      alt="Create New Data Definition Interface"
                      className="border shadow-lg mx-auto lg:relative lg:left-10"
                    />
                  </div>
                </div>

                <h4 className="text-lg my-5">Entry Definition Fields</h4>

                <p className={paragraphClass}>
                  You can also add fields to your data structure, allowing you
                  to define what will be in the data sent. This includes both
                  the name, such as{" "}
                  <span className="italic">creation_time</span> an optional
                  label ie <span className="italic">Creation Time</span> can be
                  used for display purposes, and the field type - in this case a{" "}
                  <span className="italic">Date And Time</span>. You can add as
                  many fields as you need.
                </p>
                <p className={paragraphClass}>
                  Once fields are defined you can also add support for{" "}
                  <Link to="rules" className="textlink">
                    Definition Rules
                  </Link>
                  .
                </p>
              </article>
            </div>
          </div>
        </div>
      </div>
    </RouteTransition>
  );
};

export default DocumentationDefinitions;
