import _ from "lodash";
import { shortDate, shortTime } from "@utils/format";
import { fieldTypes } from "@utils/lookups";
import { Key } from "react";

type DefinitionDataProps = {
  definition: any;
  ledger: any;
};

const DefinitionData: React.FunctionComponent<DefinitionDataProps> = ({
  definition,
  ledger,
}: DefinitionDataProps) => {
  const fields = _.orderBy(definition?.fields, (f: any) => f.createdAt, "asc");

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg max-w-2xl mx-auto my-6">
      <dl>
        <div className="bg-white px-2 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium text-gray-500">Ledger</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            {ledger.name}
          </dd>
        </div>
        <div className="bg-gray-50 px-2 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium text-gray-500">Entry Name</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            {definition.entryName}
          </dd>
        </div>

        <div className="bg-white px-2 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium text-gray-500">Fields</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <div>
              {fields.map(
                (field: {
                  id: Key | null | undefined;
                  label: any;
                  name: any;
                  isMultiple: any;
                  type: number;
                  createdAt: Date;
                }) => {
                  return (
                    <div className="text-xs text-gray-600" key={field.id}>
                      <span className="text-black">
                        {field.label || field.name}
                      </span>{" "}
                      {field.isMultiple
                        ? "is multiple values of"
                        : `is ${field.type === 10 ? "an" : "a"}`}{" "}
                      {fieldTypes[field.type as keyof typeof fieldTypes]}
                    </div>
                  );
                }
              )}
            </div>
          </dd>
        </div>

        <div className="bg-gray-50 px-2 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium text-gray-500">Created At</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            {shortDate(definition.createdAt)} at{" "}
            {shortTime(definition.createdAt)}
          </dd>
        </div>
      </dl>
    </div>
  );
};

export default DefinitionData;
