import { useContext, useState } from "react";
import { Transition } from "@headlessui/react";

import NavUserDetails from "./NavUserDetails";
import NavItem from "./NavItem";

import { ReactComponent as NavLogo } from "../../images/nav-logo.svg";
import { LoginContext } from "@utils/reducers";
import NotificationButton from "./NotificationButton";

const Nav = () => {
  const {
    login: { user },
  } = useContext(LoginContext);
  const [isShowing, setIsShowing] = useState(false);
  const paths = window.location.pathname.split("/").filter(Boolean);
  return (
    <nav>
      <div className="mx-auto relative pb-48 bg-gray-800">
        <div className="grid place-items-center h-auto md:h-16 pt-2 md:pt-0">
          <div className="flex items-center space-between w-full max-w-7xl px-4 sm:px-8">
            <div className="flex items-center w-full">
              <div className="flex-initial">
                <NavItem to="/">
                  <div className="w-28">
                    <NavLogo />
                  </div>
                  <span className="sr-only">Workflow</span>
                </NavItem>
              </div>

              <nav className="ml-16 flex flex-row justify-between space-x-4 md:block hidden text-gray-400">
                <NavItem
                  to="/"
                  exact
                  isActive={!paths.length}
                  activeClassName={"text-white font-semibold bg-gray-900"}
                  className={
                    "text-sm font-semibold hover:text-gray-200 px-3 py-2 rounded-md"
                  }
                >
                  Home
                </NavItem>

                <NavItem
                  to="/ledgers"
                  isActive={paths[0] === "ledgers"}
                  activeClassName={"text-white font-semibold bg-gray-900"}
                  className={
                    "text-sm font-semibold hover:text-gray-200 px-3 py-2 rounded-md"
                  }
                >
                  Your Ledgers
                </NavItem>

                <NavItem
                  to="/documentation"
                  isActive={paths[0] === "documentation"}
                  activeClassName={"text-white font-semibold bg-gray-900"}
                  className={
                    "text-sm font-semibold hover:text-gray-200 px-3 py-2 rounded-md"
                  }
                >
                  Documentation
                </NavItem>
              </nav>
            </div>
            <div className="flex items-center justify-end">
              <div className="flex lg:gap-12">
                {paths.length &&
                paths.some((path) =>
                  path.toLowerCase().includes("error")
                ) ? null : (
                  <NotificationButton user={user} />
                )}
                <NavUserDetails user={user} />
              </div>
              <div className="md:hidden justify-end">
                <button
                  type="button"
                  onClick={() => setIsShowing(!isShowing)}
                  className="rounded-sm p-2 items-center text-gray-400"
                  aria-expanded="false"
                >
                  <span className="sr-only">Open menu</span>
                  {!isShowing ? (
                    <svg
                      className="block h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                  ) : (
                    <svg
                      className="block h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  )}
                  <span className="sr-only">Open menu</span>
                </button>
              </div>
            </div>
          </div>
          <Transition
            show={isShowing}
            enter="transition ease-out duration-100 transform"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transition ease-in duration-75 transform"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            {(ref) => (
              <div className="lg:hidden" id="mobile-menu">
                <div
                  ref={ref}
                  className="sm:px-3 sm:py-2 sm:rounded-md sm:block bg-transparent max-w-7xl mx-auto"
                >
                  <NavItem
                    to="/"
                    className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                  >
                    Home
                  </NavItem>

                  <NavItem
                    to="/ledgers"
                    className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                  >
                    Your Ledgers
                  </NavItem>

                  <NavItem
                    to="/documentation"
                    isActive={true}
                    className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                  >
                    Documentation
                  </NavItem>
                </div>
              </div>
            )}
          </Transition>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
