import { useParams } from "react-router-dom";

import LedgerData from "./ledger/LedgerData";
import LedgerNav from "./ledger/LedgerNav";
import RouteTransition from "./layout/RouteTransition";
import PageHeader from "./layout/PageHeader";
import { useQuery } from "@tanstack/react-query";
import { ledgerQuery } from "@utils/queries";
import LedgerNotFoundError from "../error/LedgerNotFoundError";

const Ledger: React.FunctionComponent = () => {
  const { id = "" } = useParams();

  let { data: ledger, isLoading } = useQuery(
    ["ledger", id],
    () => ledgerQuery(id),
    {
      keepPreviousData: true,
    }
  );

  if (isLoading) return <div></div>;

  if (!ledger) ledger = { name: "Ledger Not Found" };

  return (
    <RouteTransition>
      {ledger.name !== "Ledger Not Found" && (
        <PageHeader
          pageTitle={
            ledger.deletedAt ? `${ledger.name} (Archived)` : ledger.name
          }
          breadcrumbs={[
            ["All Ledgers", "/ledgers/"],
            [ledger.name, undefined],
          ]}
        />
      )}
      {ledger.name !== "Ledger Not Found" && (
        <main className="bg-white rounded-lg w-full shadow-sm">
          <LedgerNav ledger={ledger} />
          <section className="p-4 md:p-6">
            <LedgerData ledger={ledger} />
          </section>
        </main>
      )}
      {ledger.name === "Ledger Not Found" && (
        <main className="bg-white rounded-lg w-full shadow-sm">
          <section>
            <LedgerNotFoundError />
          </section>
        </main>
      )}
    </RouteTransition>
  );
};

export default Ledger;
