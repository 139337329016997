import { useState } from "react";

import { Formik, Field, Form } from "formik";
import DatePicker from "react-datepicker";
import DateTimePicker from "react-datetime-picker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import RuleService from "../../services/RuleService";

import { ReactComponent as IconPlus } from "../../images/icon-plus.svg";
import { useQueryClient } from "@tanstack/react-query";
import { ruleTypes } from "@utils/lookups";

const ruleService = new RuleService();

const fieldTypeRulesMap = {
  1: [1, 2, 3, 8, 9],
  2: [1, 6, 7, 8, 9],
  3: [1, 4, 5, 8, 9],
  4: [1, 10, 11],
  5: [1, 4, 5, 8, 9],
  6: [1],
  7: [1],
  8: [1],
  9: [1, 6, 7],
  10: [1],
  11: [1],
};

type RuleFormProps = {
  fields: any;
};

const RuleForm: React.FunctionComponent<RuleFormProps> = ({
  fields,
}: RuleFormProps) => {
  const [currentFieldId, setCurrentFieldId] = useState(fields[0]?.id);
  const currentField = fields.find((field: any) => field.id === currentFieldId);
  const [formError, setFormError] = useState("");
  const queryClient = useQueryClient();

  const availableTypes = Object.entries(ruleTypes).reduce(
    (types: any, [key, value]) => {
      if (
        fieldTypeRulesMap[
          currentField
            ? (currentField.type as keyof typeof fieldTypeRulesMap)
            : (1 as keyof typeof fieldTypeRulesMap)
        ].includes(+key)
      ) {
        types.push({ key, value });
      }
      return types;
    },
    []
  );

  const sortedFields = fields.sort((a: any, b: any) => {
    return a.type - b.type;
  });

  return (
    <Formik
      initialValues={{
        fieldId: fields[0]?.id,
        type: 1,
        value: "",
      }}
      onSubmit={async (values, actions) => {
        setFormError("");
        actions.setSubmitting(true);
        ruleService
          .create(values)
          .then(() => {
            actions.resetForm();
            queryClient.invalidateQueries(["rules"]);
          })
          .catch(({ response }) => {
            actions.resetForm();
            setFormError(response.data.message);
          });
      }}
    >
      {(props) => {
        const ruleType = +props.values.type;
        return (
          <Form className="grid grid-cols-7 gap-3  p-3">
            {formError && (
              <div className="mt-2 mb-2 col-span-7">
                <div className="p-2 text-sm bg-red-200 rounded-md border-red-400">
                  <p className="text-xs normal-case">{formError}</p>
                </div>
              </div>
            )}
            <label className="col-span-2 font-semibold pl-2" htmlFor="field">
              Field
            </label>
            <label className="col-span-2 font-semibold pl-2" htmlFor="type">
              Type
            </label>
            <label className="col-span-2 font-semibold pl-2" htmlFor="value">
              Value
            </label>

            <Field
              name="fieldId"
              as="select"
              className="textfield col-span-2"
              onChange={(event: any) => {
                setCurrentFieldId(event.target.value);
                props.setFieldValue("fieldId", event.target.value);
                props.setFieldValue("type", 1);
              }}
            >
              {sortedFields.map(({ id, label, name }: any) => {
                return (
                  <option key={id} value={id}>
                    {label ? label : name}
                  </option>
                );
              })}
            </Field>
            <Field
              name="type"
              as="select"
              className="textfield col-span-2"
              onChange={(event: any) => {
                props.setFieldValue("type", event.target.value);
              }}
            >
              {availableTypes.map(({ key, value }: any) => {
                return (
                  <option key={key} value={key}>
                    {value}
                  </option>
                );
              })}
            </Field>

            {ruleType === 1 && (
              <div className="textfield col-span-2 bg-gray-200 border"></div>
            )}

            {currentField?.type === 1 && [2, 3].includes(ruleType) && (
              <>
                <Field name="value" className="textfield" />
                <span className="pt-2 pl-2">characters</span>
              </>
            )}

            {currentField?.type === 1 && [8, 9].includes(ruleType) && (
              <Field name="value" className="textfield col-span-2" />
            )}

            {currentField?.type === 2 && [6, 7, 8, 9].includes(ruleType) && (
              <Field name="value" className="textfield col-span-2" />
            )}

            {currentField?.type === 3 && [4, 5, 8, 9].includes(ruleType) && (
              <DateTimePicker
                onChange={(setDate) => {
                  props.setFieldValue("value", setDate);
                }}
                className="textfield col-span-2 bg-white"
                name="dateTimePicker"
                minDate={new Date(1900, 0, 1)}
                maxDate={new Date(2100, 0, 1)}
                maxDetail="second"
                value={props.values.value ? props.values.value : new Date()}
              />
            )}

            {currentField?.type === 4 && [10, 11].includes(ruleType) && (
              <div className="textfield col-span-2 bg-gray-200 border"></div>
            )}

            {currentField?.type === 5 && [4, 5, 8, 9].includes(ruleType) && (
              <DatePicker
                onChange={(setDate) => {
                  props.setFieldValue("value", setDate);
                }}
                className="textfield col-span-2 bg-white"
                name="datePicker"
                minDate={new Date(1900, 0, 1)}
                maxDate={new Date(2100, 0, 1)}
                placeholderText="Select a date"
                value={props.values.value}
              />
            )}
            {currentField?.type === 8 && ruleType === 12 && (
              <Field name="value" className="textfield col-span-2" />
            )}

            {currentField?.type === 9 && [6, 7].includes(ruleType) && (
              <Field name="value" className="textfield col-span-2" />
            )}

            <button
              type="submit"
              className="flex justify-center items-center py-2 px-3 border border-transparent text-sm font-semibold rounded-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <IconPlus className="w-4 mr-1" />
              Add Rule
            </button>
          </Form>
        );
      }}
    </Formik>
  );
};

export default RuleForm;
