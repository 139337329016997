import { Link } from "react-router-dom";
import { useContext, useState } from "react";
import LedgerList from "./ledger/LedgerList";
import RouteTransition from "./layout/RouteTransition";
import PageHeader from "./layout/PageHeader";
import { ReactComponent as IconPlus } from "../images/icon-plus.svg";
import { useQuery } from "@tanstack/react-query";
import { ledgersQuery, companyQuery } from "@utils/queries";
import { LoginContext } from "@utils/reducers";

const Ledgers = () => {
  const {
    login: { user },
  } = useContext(LoginContext);
  const { data: ledgers = [] } = useQuery(["ledgers"], ledgersQuery);
  const { data: companies = [] } = useQuery(["companies"], companyQuery);
  const [selectedCompanyId, setSelectedCompanyId] = useState("");
  const dataskerCompany = companies.find(
    (company: any) => company.name === "Datasker"
  );

  const uniqueCompanyIds = [
    ...new Set(ledgers.map((ledger: any) => ledger.companyId)),
  ];

  const uniqueCompanyIdsAndNames = uniqueCompanyIds.map((companyId) => {
    const company = companies.find((company: any) => company.id === companyId);
    return {
      companyId,
      companyName: company ? company.name : "Others",
    };
  });

  const filteredLedgers = selectedCompanyId
    ? ledgers.filter((ledger: any) => ledger.companyId === selectedCompanyId)
    : ledgers;

  return (
    <RouteTransition>
      <PageHeader pageTitle="Your Ledger" />
      <main className="flex flex-col lg:flex-row gap-6">
        <section className="bg-white rounded-lg w-full shadow-sm">
          <div className="flex w-full justify-between px-4 md:px-6 h-16 md:h-20 items-center border-b border-gray-200">
            <h3 className="text-xl md:text-2xl font-semibold">
              {filteredLedgers.length}{" "}
              {filteredLedgers.length > 1 ? "Ledgers" : "Ledger"}
            </h3>

            <div className="flex items-center space-x-4">
              {user?.company?.id === dataskerCompany?.id && (
                <select
                  value={selectedCompanyId}
                  onChange={(e) => setSelectedCompanyId(e.target.value)}
                  className="py-2.5 px-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  <option value="">All Ledgers</option>
                  {uniqueCompanyIdsAndNames.map((company: any) => (
                    <option key={company.companyId} value={company.companyId}>
                      {company.companyName}
                    </option>
                  ))}
                </select>
              )}

              <Link to="/ledgers/new" className="flex-shrink-0 pl-3">
                <button
                  type="submit"
                  className="flex items-center py-2 px-3 border border-transparent text-sm font-semibold rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  <IconPlus className="w-4 mr-1" />
                  Create Ledger
                </button>
              </Link>
            </div>
          </div>
          <LedgerList ledgers={filteredLedgers} />
        </section>
      </main>
    </RouteTransition>
  );
};

export default Ledgers;
