import { Link } from "react-router-dom";
import EntryItem from "./EntryItem";

type EntryListProps = {
  entries: any;
  ledger: any;
};

const EntryList: React.FunctionComponent<EntryListProps> = ({
  entries,
  ledger,
}: EntryListProps) => {
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="md:w-1/5 px-4 md:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-4 md:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider sm:w-full"
                  >
                    Details
                  </th>
                  <th
                    scope="col"
                    className="px-4 md:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Created
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {!entries.length && (
                  <tr>
                    <td
                      colSpan={3}
                      className="text-center text-gray-600 text-sm p-3"
                    >
                      No matching entries in this ledger.{" "}
                      {!ledger.deletedAt && (
                        <>
                          {" "}
                          You can{" "}
                          <Link
                            to="entries/new"
                            className="text-blue-600 hover:text-blue-900"
                          >
                            create a manual entry
                          </Link>{" "}
                          to add data to this ledger.
                        </>
                      )}
                    </td>
                  </tr>
                )}
                {entries.map((entry: any) => (
                  <EntryItem key={entry.id} entry={entry} ledger={ledger} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EntryList;
