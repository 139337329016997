import RouteTransition from "./layout/RouteTransition";
import PageHeader from "./layout/PageHeader";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { B2CMembershipQuery } from "@utils/queries";
import Loading from "./shared/Loading";
import {
  getHashScanTransactionUrl,
  getHashScanAccountUrl,
} from "@utils/hashgraphUtils";
import { formatNumberWithCommas } from "@utils/format";

const B2CMembershipVerification = () => {
  const { id = "", companyName = "" } = useParams();
  let { data, isLoading } = useQuery(
    ["memberships", id],
    () => B2CMembershipQuery(id, companyName),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  if (isLoading) {
    return <Loading />;
  }

  const pageTitle =
    `${data?.issuer} Membership Verification` +
    (data?.name ? ` - ${data?.name}` : "");

  return (
    <RouteTransition>
      <PageHeader pageTitle={pageTitle} />
      <main className="flex flex-col lg:flex-row gap-6">
        <section className="bg-white rounded-lg w-full shadow-sm">
          <div className="flex w-full justify-between px-4 md:px-6 h-16 md:h-20 items-center border-b border-gray-200">
            <h3 className={"text-xl md:text-2xl font-semibold"}>
              Membership Status:{" "}
              <span
                className={
                  (data?.status === "active"
                    ? "text-green-400"
                    : "text-red-500") + " font-bold text-3xl ml-5"
                }
              >
                {data?.status
                  ? data?.status?.toUpperCase()
                  : "CUSTOMER NOT FOUND"}
              </span>
            </h3>
          </div>
          {data?.name ? (
            <div className="p-6">
              <table className="w-full text-left text-lg">
                <tbody>
                  <tr>
                    <td className="py-2 font-semibold w-1/5">Name:</td>
                    <td className="py-2 pl-8">{data?.name}</td>
                  </tr>
                  <tr>
                    <td className="py-2 font-semibold w-1/5">Role:</td>
                    <td className="py-2 pl-8">{data?.role}</td>
                  </tr>
                  <tr>
                    <td className="py-2 font-semibold w-1/5">Facility:</td>
                    <td className="py-2 pl-8">{data?.facility}</td>
                  </tr>
                  <tr>
                    <td className="py-2 font-semibold w-1/5">Address:</td>
                    <td className="py-2 pl-8">{data?.address}</td>
                  </tr>
                  <tr>
                    <td className="py-2 font-semibold w-1/5">Wallet ID:</td>
                    <td className="py-2 pl-8">
                      <a
                        href={getHashScanAccountUrl(data?.wallet_id)}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:underline"
                      >
                        {data?.wallet_id}
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2 font-semibold w-1/5">Total Volume:</td>
                    <td className="py-2 pl-8">
                      {formatNumberWithCommas(data?.customerContribution) || 0}{" "}
                      kg(s)
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2 font-semibold w-1/5">Issuer:</td>
                    <td className="py-2 pl-8">{data?.issuer}</td>
                  </tr>
                  <tr>
                    <td className="py-2 font-semibold w-1/5">Issuance Date:</td>
                    <td className="py-2 pl-8">
                      {new Date(data?.issuanceDate).toLocaleDateString()}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2 font-semibold w-1/5">Expiry Date:</td>
                    <td className="py-2 pl-8">
                      {new Date(data?.expiryDate).toLocaleDateString()}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2 font-semibold w-1/5">
                      NFT Transaction:
                    </td>
                    <td className="py-2 pl-8">
                      <a
                        href={getHashScanTransactionUrl(data?.nft_transaction)}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:underline"
                      >
                        {data?.nft_transaction}
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
              {/* <div className="pt-6 pr-10">
                <p className="text-xl text-right">Verify Badge Here</p>
              </div> */}
            </div>
          ) : (
            <div className="p-6">
              <p className="text-xl text-center">
                Please check the URL and try again.
              </p>
            </div>
          )}
        </section>
      </main>
    </RouteTransition>
  );
};

export default B2CMembershipVerification;
