import NotificationNav from "./NotificationNav";
import RouteTransition from "../layout/RouteTransition";
import PageHeader from "../layout/PageHeader";
import { useQuery } from "@tanstack/react-query";
import { notificationsQuery } from "@utils/queries";
import classNames from "classnames";
import { formatDistance } from "date-fns";

const SystemNotifications = () => {
  const { data: notifications = [], isLoading } = useQuery(
    ["notifications"],
    notificationsQuery
  );

  if (isLoading) return null;

  return (
    <RouteTransition>
      <PageHeader pageTitle="Notifications" />
      <div className="grid grid-cols-3 gap-6">
        <div className="col-span-3 md:col-span-1">
          <NotificationNav />
        </div>
        <div className="col-span-3 md:col-span-2">
          <div className="shadow sm:rounded-md">
            <div className="bg-white md:rounded-md overflow-hidden">
              <div className="flex items-center w-full px-4 md:px-6 h-16 md:h-20 border-b border-gray-200">
                <h3 className="text-xl md:text-2xl font-semibold">
                  System Notifications
                </h3>
              </div>
              <div className="p-4 md:p-6">
                {!notifications.length && (
                  <div className="text-center px-4 py-24 md:py-44 text-gray-400">
                    You currently have no system notifications
                  </div>
                )}
                {notifications?.map((item: any, key: number) => {
                  return (
                    <div
                      key={key}
                      className={classNames(
                        "p-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6",
                        key % 2 === 0 ? "bg-gray-50" : "bg-white"
                      )}
                    >
                      <dt className="text-sm font-semibold text-gray-500">
                        {!item.viewed_at && (
                          <span className="inline-block px-2 py-0.5 leading-5 text-xs border rounded-md bg-green-50 text-green-500 border-green-500 border-r-8 mr-2">
                            {" "}
                            New
                          </span>
                        )}
                        {item.relatedMessage?.subject}
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {item.relatedMessage?.body}
                        <p className="text-gray-400 text-xs mt-2 text-right">
                          {item.createdAt &&
                            formatDistance(
                              new Date(item.createdAt),
                              new Date(),
                              {
                                addSuffix: true,
                              }
                            )}
                        </p>
                      </dd>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </RouteTransition>
  );
};

export default SystemNotifications;
