import React, { useState } from "react";
import { Formik, ErrorMessage, Form } from "formik";
import FormSuccess from "./layout/FormSuccess";
import FormError from "./layout/FormError";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import dataskerLogo from "../images/logo512.png";
import RouteTransition from "./layout/RouteTransition";
import UserService from "../services/UserService";
import OtpInput from "react18-input-otp";
import SignInFooter from "./layout/SignInFooter";

const userService = new UserService();

const ResetPassword: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const [formErrors, setFormErrors] = useState<string[]>([]);
  const [step, setStep] = useState(1);
  const [success, setSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [resendSuccess, setResendSuccess] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const validateStep1 = Yup.object({
    email: Yup.string().email("Email is invalid").required("Email is required"),
  });

  const validateStep2 = Yup.object({
    code: Yup.string().required("Code is required"),
    newPassword: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .matches(/\d/, "Password must include a number")
      .required("New Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword")], "Password must match")
      .required("Confirm password is required"),
  });

  const handleSubmission = async (
    { email, code, newPassword }: any,
    actions: any
  ) => {
    actions.setSubmitting(true);
    setFormErrors([]);
    setIsLoading(true);
    setResendSuccess(false);
    try {
      if (step === 1) {
        const response = await userService.resetPassword(email);
        if (response?.status === 200) {
          setStep(2);
        } else if (response?.status === 404) {
          setFormErrors([
            "User with this email does not exist. Please check your email.",
          ]);
        } else {
          setFormErrors(["Unable to send code, please try again!"]);
        }
      } else if (step === 2) {
        const response = await userService.resetPasswordConfirm(
          email,
          newPassword,
          code
        );
        if (response?.status === 200) {
          setSuccess(true);
          setTimeout(() => {
            navigate("/login");
          }, 1500);
        } else if (response?.status === 400) {
          setFormErrors([
            "Password should contain the following requirement: Uppercase, Lowercase, Number, and special symbol!",
          ]);
        } else if (response?.status === 422) {
          setFormErrors(["Invalid verification code, please try again!"]);
        } else {
          setFormErrors(["Unable to reset password, please try again!"]);
        }
      }
    } catch (error) {
      const err = error as any;
      if (err.response && err.response.data && err.response.data.errors) {
        const errorMessages = err.response.data.errors.map(
          (errorObj: any) => errorObj.message
        );
        setFormErrors(errorMessages);
      } else {
        setFormErrors(["An unexpected error occurred."]);
      }
    } finally {
      actions.setSubmitting(false);
      setIsLoading(false);
    }
  };

  const resendCode = async (email: string) => {
    setIsLoading(true);
    try {
      const response = await userService.resetPassword(email);
      if (response?.status === 200) {
        setResendSuccess(true);
      } else {
        setFormErrors([
          "Unable to resend verification code, please try again!",
        ]);
      }
    } catch (error) {
      setFormErrors(["An unexpected error occurred while resending the code."]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <RouteTransition>
      <Formik
        initialValues={{
          email: "",
          code: "",
          newPassword: "",
          confirmPassword: "",
        }}
        validationSchema={step === 1 ? validateStep1 : validateStep2}
        onSubmit={handleSubmission}
      >
        {(props) => (
          <Form>
            <div className="mt-20 mb-20 bg-zinc-700">
              <div className=" mx-auto my-6">
                <img
                  src={dataskerLogo}
                  alt="Datasker Logo"
                  className="sm:h-32 h-20 m-auto"
                />
                <div className="m-auto flex my-4 justify-center sm:text-5xl text-3xl text-cyan-700 font-semibold">
                  Reset Password
                </div>
                <div className="mt-5 md:mt-0 md:col-span-2 max-w-2xl m-auto">
                  <div className=" sm:overflow-hidden bg-zinc-700">
                    <div className="px-4 py-5 space-y-6 sm:p-6 text-align ml-10 mr-10">
                      {step === 1 && (
                        <div>
                          <FormError
                            show={formErrors.length > 0}
                            errors={formErrors}
                            title=""
                          />
                          <div className="grid grid-cols-1 gap-3">
                            <div className="col-span-1 sm:col-span-1">
                              <label
                                htmlFor="email"
                                className="text-lg font-medium text-white flex items-end"
                              >
                                Email
                                <p className="font-extralight text-base inline ml-2 opacity-70">
                                  (required)
                                </p>
                              </label>
                              <div className="mt-1 flex">
                                <input
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  value={props.values.email}
                                  type="text"
                                  name="email"
                                  id="email"
                                  className="p-2 focus:ring-blue-500 focus:border-blue-500 flex-1 block w-full rounded-md sm:text-sm border border-gray-300"
                                  placeholder=""
                                />
                              </div>
                              <ErrorMessage
                                component="p"
                                className="sm:text-xs text-red-600 pt-2"
                                name="email"
                              />
                            </div>
                          </div>
                          {!isLoading && (
                            <div className="flex m-auto items-center mt-8">
                              <button
                                type="submit"
                                className="h-12 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded text-base bg-cyan-600 opacity-90 hover:bg-cyan-500 text-white border-x-8 m-auto"
                              >
                                Send Code
                              </button>
                            </div>
                          )}
                          {isLoading && (
                            <div className="flex m-auto items-center mt-8">
                              <button
                                disabled={true}
                                className="cursor-not-allowed h-12 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded text-base bg-cyan-600 opacity-30 hover:bg-cyan-500 text-white border-x-8 m-auto"
                              >
                                Send Code
                              </button>
                            </div>
                          )}
                        </div>
                      )}
                      {step === 2 && (
                        <div>
                          <div className="grid grid-cols-1 gap-3">
                            <div className="col-span-1 sm:col-span-1">
                              <FormSuccess show={success}>
                                Your password has been changed. Sending you to
                                the login page.
                              </FormSuccess>
                              <FormSuccess show={resendSuccess}>
                                Your code has been resent.
                              </FormSuccess>
                              <FormError
                                show={formErrors.length > 0}
                                errors={formErrors}
                                title=""
                              />
                              <h3 className=" text-base font-light text-white flex items-end ">
                                Verification code has been sent to you email:{" "}
                                {props.values.email}
                              </h3>
                            </div>
                          </div>
                          <div className="grid grid-cols-1 gap-3 mt-5">
                            <div className="col-span-1 sm:col-span-1">
                              <label
                                htmlFor="newPassword"
                                className=" text-lg font-medium text-white flex items-end"
                              >
                                New Password
                                <p className="font-extralight text-base inline ml-2 opacity-70">
                                  (required)
                                </p>
                              </label>
                              <div className="mt-1 flex rounded-md shadow-sm relative">
                                <input
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  value={props.values.newPassword}
                                  type={showPassword ? "text" : "password"}
                                  name="newPassword"
                                  id="newPassword"
                                  className="p-2 focus:ring-blue-500 focus:border-blue-500 flex-1 block w-full rounded-md sm:text-sm border border-gray-300"
                                  placeholder=""
                                />
                                <span
                                  onClick={togglePasswordVisibility}
                                  className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                                >
                                  <i
                                    className={
                                      showPassword
                                        ? "fa fa-eye-slash"
                                        : "fa fa-eye"
                                    }
                                    aria-hidden="true"
                                    style={{ color: "black" }}
                                  ></i>
                                </span>
                              </div>
                              <ErrorMessage
                                component="p"
                                className="sm:text-xs text-red-600 pt-2"
                                name="newPassword"
                              />
                            </div>
                          </div>
                          <div className="grid grid-cols-1 gap-3 mt-5">
                            <div className="col-span-1 sm:col-span-1">
                              <label
                                htmlFor="confirmPassword"
                                className=" text-lg font-medium text-white flex items-end"
                              >
                                Confirm Password
                                <p className="font-extralight text-base inline ml-2 opacity-70">
                                  (required)
                                </p>
                              </label>
                              <div className="mt-1 flex rounded-md shadow-sm relative">
                                <input
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  value={props.values.confirmPassword}
                                  type={
                                    showConfirmPassword ? "text" : "password"
                                  }
                                  name="confirmPassword"
                                  id="confirmPassword"
                                  className="p-2 focus:ring-blue-500 focus:border-blue-500 flex-1 block w-full rounded-md sm:text-sm border border-gray-300"
                                  placeholder=""
                                />
                                <span
                                  onClick={toggleConfirmPasswordVisibility}
                                  className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                                >
                                  <i
                                    className={
                                      showPassword
                                        ? "fa fa-eye-slash"
                                        : "fa fa-eye"
                                    }
                                    aria-hidden="true"
                                    style={{ color: "black" }}
                                  ></i>
                                </span>
                              </div>
                              <ErrorMessage
                                component="p"
                                className="sm:text-xs text-red-600 pt-2"
                                name="confirmPassword"
                              />
                            </div>
                          </div>

                          <div className="grid grid-cols-1 gap-3 mt-5">
                            <div className="col-span-1 sm:col-span-1">
                              <label
                                htmlFor="confirmPassword"
                                className=" text-lg font-medium text-white flex items-end"
                              >
                                Verification Code
                              </label>
                              <OtpInput
                                value={props.values.code}
                                onChange={(value: any) =>
                                  props.setFieldValue("code", value)
                                }
                                numInputs={6}
                                inputStyle="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:outline-none text-5xl 2xl:text-6xl text-center"
                                containerStyle="w-full flex gap-4 mt-3 justify-center"
                                errorStyle="border-red-300"
                                isInputNum={true}
                                isDisabled={isLoading}
                              />
                            </div>
                          </div>

                          {!isLoading && (
                            <div>
                              <div className="flex m-auto items-center mt-8">
                                <button
                                  type="submit"
                                  className="h-12 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded text-base bg-cyan-600 opacity-90 hover:bg-cyan-500 text-white border-x-8 m-auto"
                                >
                                  Change Password
                                </button>
                              </div>

                              <div className="flex m-auto items-center mt-8 text-white">
                                Didn't receive a code?{" "}
                                <button
                                  onClick={() => resendCode(props.values.email)}
                                  className="text-cyan-300 hover:underline hover:text-cyan-800 ml-2"
                                >
                                  Resend Code
                                </button>
                              </div>
                            </div>
                          )}
                          {isLoading && (
                            <div>
                              <div className="flex m-auto items-center mt-8">
                                <button
                                  disabled={true}
                                  className="cursor-not-allowed opacity-30 h-12 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded text-base bg-cyan-600 hover:bg-cyan-500 text-white border-x-8 m-auto"
                                >
                                  Change Password
                                </button>
                              </div>

                              <div className="flex m-auto items-center mt-8 text-white">
                                Didn't receive a code?{" "}
                                <button
                                  disabled={true}
                                  className="cursor-not-allowed opacity-30 text-cyan-300 hover:underline hover:text-cyan-800 ml-2"
                                >
                                  Resend Code
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <SignInFooter />
    </RouteTransition>
  );
};

export default ResetPassword;
