import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { Link } from "react-router-dom";

import { ReactComponent as IconLedgerOutline } from "../../images/icon-ledger-outline.svg";

type LedgerSummaryDefinitionsExistProps = {
  ledger: any;
};

const LedgerSummaryDefinitionsExist: React.FunctionComponent<
  LedgerSummaryDefinitionsExistProps
> = ({ ledger }: LedgerSummaryDefinitionsExistProps) => {
  const { definitions } = ledger;
  const navigate = useNavigate();

  return (
    <div className="flex flex-col md:flex-row gap-4 border border-gray-300 rounded-md p-4 md:p-6 text-gray-800">
      {definitions.length > 0 && (
        <>
          <div className="flex-shrink-0 text-gray-500">
            <IconLedgerOutline />
          </div>
          <div>
            <p className="mb-4">
              You have set up {definitions.length} data definitions to help
              structure and secure your data.
            </p>
            <div className="flex gap-4">
              <button
                className="text-blue-500 hover:text-blue-700 font-semibold"
                onClick={() => {
                  navigate(`/ledgers/${ledger.id}/definitions`);
                }}
              >
                View
              </button>
              <button
                className={classNames(
                  ledger.deletedAt
                    ? "cursor-not-allowed text-blue-200 font-semibold"
                    : "text-blue-500 hover:text-blue-700 font-semibold"
                )}
                onClick={() => {
                  navigate(`/ledgers/${ledger.id}/definitions/new`);
                }}
                disabled={ledger.deletedAt}
              >
                Create
              </button>
            </div>
          </div>
        </>
      )}
      {definitions.length === 0 && (
        <>
          <div className="flex-shrink-0 text-gray-300">
            <IconLedgerOutline />
          </div>
          <div>
            <p className="mb-4">
              You have no data definitions set up. It will not be possible to
              enforce any rules or assist you with data entry until you create a
              new one.
            </p>
            <Link
              to={`/ledgers/${ledger.id}/definitions/new`}
              className="text-blue-500 hover:text-blue-700 font-semibold"
            >
              Create
            </Link>
          </div>
        </>
      )}
    </div>
  );
};

export default LedgerSummaryDefinitionsExist;
