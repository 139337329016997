import { Link } from "react-router-dom";

import { shortDate, shortTime } from "@utils/format";

type LedgerCardProps = {
  ledger: any;
};

const LedgerCard: React.FunctionComponent<LedgerCardProps> = ({
  ledger,
}: LedgerCardProps) => {
  const entriesLength = ledger.entries.length;
  return (
    <div className="shadow-sm border rounded-xl bg-white flex flex-col justify-between p-6">
      <h3 className="text-lg font-semibold mb-4">
        <Link
          to={`/ledgers/${ledger.id}`}
          className={`textlink ${ledger.deletedAt ? "opacity-25" : ""}`}
        >
          {ledger.name}
        </Link>
      </h3>
      <div className="flex-grow mb-4">
        <p className="text-base text-gray-500">{ledger.description}</p>
      </div>
      <div className="text-gray-500 flex">
        <div className="text-sm flex-grow">
          {shortDate(ledger.createdAt)} at {shortTime(ledger.createdAt)}
        </div>
        <div className="text-sm text-right flex-grow-2">
          <Link to={`/ledgers/${ledger.id}/entries`} className="textlink">
            {entriesLength} {entriesLength === 1 ? "entry" : "entries"}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LedgerCard;
