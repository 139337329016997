const Footer = () => {
  return (
    <footer className="" aria-labelledby="footerHeading">
      <h2 id="footerHeading" className="sr-only">
        Footer
      </h2>
      <div className="bg-zinc-600 px-3">
        <div className="max-w-6xl mx-auto pb-8">
          <div className="flex flex-col md:flex-row">
            <div className="md:mt-8 mt-0 md:text-lg text-base text-gray-300 flex-grow px-2 md:w-3/5  md:order-1 order-2 leading-7">
              <p className="md:text-xl text-lg md:mt-10 mt-8 md:mb-8 mb-6">
                DATASKER is a solution developed and owned by CIVIC LEDGER PTY
                LTD
              </p>
              <p className="">Yuggera Nation</p>
              <p className="">Level 18, Waterfront Place</p>
              <p className="mb-4">
                1 Eagle Street, Brisbane / Meanjin, Queensland 4000 Australia
              </p>
              <p className="mb-4">
                We acknowledge Aboriginal and Torres Strait Islander peoples as
                the First Australians and Traditional Custodians of the lands
                where we live, learn, play and work.
              </p>
              <p>
                © 2024 Civic Ledger. All rights reserved. |{" "}
                <a
                  href="https://www.datasker.io/privacy-policy"
                  className="underline text-cyan-600 hover:text-cyan-400"
                >
                  Privacy Policy
                </a>{" "}
                | ABN 70 614 926 171
              </p>
            </div>
            <div className="md:mt-8 mt-0 md:text-lg text-base text-gray-300 flex-grow md:w-1/2 md:px-10 px-4  md:order-2 order-1 ">
              <div className="flex mt-10 mb-8 ">
                <div className="flex-grow px-1 flex-col justify-start">
                  <p className="text-gray-100 mb-7 font-bold md:text-xl text-base">
                    Our Work
                  </p>
                  <p className="mb-3">
                    <a
                      href="https://www.civicledger.com/"
                      className="underline text-cyan-600 hover:text-cyan-400"
                    >
                      Civic Ledger
                    </a>
                  </p>
                  <p>
                    <a
                      href="https://waterledger.com/"
                      className="underline text-cyan-600 hover:text-cyan-400"
                    >
                      Water Ledger
                    </a>
                  </p>
                </div>
                <div className="flex-grow text-gray-100 px-1 flex-col justify-start">
                  <p className="text-gray-100 mb-7 font-bold md:text-xl text-base">
                    Follow
                  </p>{" "}
                  <p className="mb-3">
                    <a
                      href="https://www.linkedin.com/company/civic-ledger"
                      className="underline text-cyan-600 hover:text-cyan-400"
                    >
                      LinkedIn
                    </a>
                  </p>
                  <p>
                    <a
                      href="https://twitter.com/civicledger"
                      className="underline text-cyan-600 hover:text-cyan-400"
                    >
                      X
                    </a>
                  </p>
                </div>
              </div>
              <div className="flex justify-center md:mt-12 st-2 w-full">
                <a
                  href="https://www.datasker.io/request-a-demo"
                  className="w-full"
                >
                  <button className="px-4 mt-5 w-full py-2 border border-transparent rounded bg-cyan-600 opacity-90 hover:bg-cyan-500 text-white font-bold text-lg">
                    Request a Demo
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
