import axios from "axios";
import BaseService from "@services/BaseService";

axios.defaults.baseURL = process.env.REACT_APP_API_HOST;

export default class UserService extends BaseService {
  entity = "users";
  logIn(email: string, password: string) {
    return axios.post("login", { email, password });
  }

  saveUser({ user, token }: any) {
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("token", token);
  }

  logOut() {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
  }

  static getLoggedInUser() {
    const userString = localStorage.getItem("user");
    const token = localStorage.getItem("token");
    if (!userString || userString === "undefined" || !token) {
      return { user: null, token: null, loggedIn: false };
    }
    const user = JSON.parse(userString);

    user.createdAt = new Date(user.createdAt);

    return { user, token, loggedIn: true };
  }

  static isLoggedIn() {
    const token = localStorage.getItem("token");
    return token !== null && token.length > 0;
  }

  async handleLoginVerification(
    email: string,
    password: string,
    mfaMethod: string
  ) {
    try {
      const response = await axios.post("/sendCode", {
        email: email,
        password: password,
        mfaMethod: mfaMethod,
      });
      return response;
    } catch (error: any) {
      return {
        success: false,
        error: error.message,
        status: error.response.status,
      };
    }
  }

  async handleLoginVerificationCode(
    email: string,
    password: string,
    code: string,
    mfaMethod: string
  ) {
    try {
      const response = await axios.post("/verifyCode", {
        email: email,
        password: password,
        mfaMethod: mfaMethod,
        code: code,
      });
      return response;
    } catch (error: any) {
      return {
        data: error.response.data,
        success: false,
        error: error.message,
        status: error.response.status,
      };
    }
  }

  async findUser(email: string) {
    try {
      const response = await axios.post("/getUser", {
        email: email,
      });
      return response;
    } catch (error: any) {
      return {
        success: false,
        error: error.message,
        status: error.response.status,
      };
    }
  }

  async signUp(email: string, password: string, tel: string) {
    try {
      const response = await axios.post("/signUp", {
        email: email,
        password: password,
        tel: tel,
      });
      return response;
    } catch (error: any) {
      return { success: false, status: error.response.status };
    }
  }

  async confirmSignUp(email: string, confirmationCode: string) {
    try {
      const response = await axios.post("/confirmSignUp", {
        email: email,
        confirmationCode: confirmationCode,
      });
      return { success: true, data: response.data, status: response.status };
    } catch (error: any) {
      return {
        success: false,
        error: error.response?.data || error.message,
        status: error.response?.status || error.status,
      };
    }
  }

  async resetPassword(email: string) {
    try {
      const response = await axios.post("/resetPassword", {
        email: email,
      });
      return response;
    } catch (error: any) {
      return {
        success: false,
        error: error.message,
        status: error.response.status,
      };
    }
  }

  async resetPasswordConfirm(email: string, password: string, code: string) {
    try {
      const response = await axios.post("/resetPasswordConfirm", {
        email: email,
        password: password,
        code: code,
      });
      return response;
    } catch (error: any) {
      return {
        success: false,
        error: error.message,
        status: error.response.status,
      };
    }
  }

  async resendConfirmSignUpCode(email: string) {
    try {
      const response = await axios.post("/resendConfirmSignUpCode", {
        email: email,
      });
      return response;
    } catch (error: any) {
      return {
        success: false,
        error: error.message,
        status: error.response.status,
      };
    }
  }
}
