import axios from "axios";

import ENV from "@config/env";
import BaseService from "@services/BaseService";

axios.defaults.baseURL = ENV.API_HOST;

export default class DocumentService extends BaseService {
  entity = "documents";

  upload(documentData: any) {
    const token = localStorage.getItem("token");

    const uploadFormData = new FormData();
    uploadFormData.append("title", documentData.title);
    uploadFormData.append("ledgerId", documentData.ledgerId);
    uploadFormData.append("document", documentData.document);

    return fetch(process.env.REACT_APP_API_HOST + this.entity, {
      method: "POST",
      body: uploadFormData,
      headers: { Authorization: `Bearer ${token}` },
    });
  }
}

export const documentService = new DocumentService();
