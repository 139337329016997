import LedgerSummaryDefinitionsExist from "./LedgerSummaryDefinitionsExist";
import LedgerSummaryDeployment from "./LedgerSummaryDeployment";
import LedgerSummaryEntries from "./LedgerSummaryEntries";

type LedgerDataProps = {
  ledger: any;
};

const LedgerData: React.FunctionComponent<LedgerDataProps> = ({
  ledger,
}: LedgerDataProps) => {
  if (ledger.name === "Ledger Not Found") return null;
  if (!ledger) return null;

  const heading4Class = "text-xl font-semibold mt-12 mb-4";
  const paragraphClass = "text-gray-700 mb-6";

  return (
    <article className="max-w-2xl mx-auto my-6">
      <h3 className="text-2xl font-semibold">Ledger Outline</h3>

      <h4 className={heading4Class}>Description</h4>
      <p className={paragraphClass}>{ledger.description}</p>

      <h4 className={heading4Class}>Definition Setup</h4>
      <p className={paragraphClass}>
        Definitions create a structure that allows you to better search, input,
        and validate your data and is the first step to making a valuable
        audit-ready ledger.
      </p>
      <LedgerSummaryDefinitionsExist ledger={ledger} />

      <h4 className={heading4Class}>Blockchain Setup and Deployment</h4>
      <p className={paragraphClass}>
        Because blockchain storage is immutable - ie it cannot be changed - it
        is important to make sure all the rest of your ledger setup is correct
        before finalising deployment.
      </p>
      <LedgerSummaryDeployment ledger={ledger} />

      <h4 className={heading4Class}>Entries</h4>
      <LedgerSummaryEntries ledger={ledger} />
    </article>
  );
};

export default LedgerData;
