import React, { useReducer, useEffect } from "react";
import "./App.css";
import { LoginContext, loginReducer } from "@utils/reducers";
import UserService from "@services/UserService";
import UserHome from "@components/UserHome";
import GuestHome from "@components/GuestHome";
import { BrowserRouter } from "react-router-dom";
import "./axiosConfig";

const App = () => {
  const [login, dispatch] = useReducer(
    loginReducer,
    UserService.getLoggedInUser()
  );
  useEffect(() => {
    const handleRuntimeError = () => {
      window.location.href = "/RuntimeError";
    };
    window.addEventListener("error", handleRuntimeError);
    return () => {
      window.removeEventListener("error", handleRuntimeError);
    };
  }, []);

  return (
    <BrowserRouter>
      <LoginContext.Provider value={{ login, dispatch }}>
        {login.loggedIn && <UserHome />}
        {!login.loggedIn && <GuestHome />}
      </LoginContext.Provider>
    </BrowserRouter>
  );
};

export default App;
