import NavItem from "../layout/NavItem";

const NotificationNav = () => {
  const navLinkClass =
    "flex gap-x-4 items-center p-2 text-sm font-semibold text-gray-800 rounded-lg opacity-50 hover:opacity-100";
  const navLinkActiveClass = "bg-gray-100 font-semibold opacity-100";

  return (
    <div className="col-span-1 bg-white shadow overflow-hidden border-gray-200 rounded-lg z-20 p-3 flex flex-col gap-y-1">
      <NavItem
        to="/notifications/system"
        activeClassName={navLinkActiveClass}
        className={navLinkClass}
      >
        <span className="text-gray-500">
          <i className="fal fa-solid fa-check-double"></i>
        </span>
        <span>System Notifications</span>
      </NavItem>
      <NavItem
        to="/notifications/inbox"
        activeClassName={navLinkActiveClass}
        className={navLinkClass}
      >
        <span className="text-gray-500">
          <i className="fal fa-solid fa-inbox"></i>
        </span>
        <span>Inbox</span>
      </NavItem>
    </div>
  );
};

export default NotificationNav;
