import { shortDate } from "@utils/format";
import { getTransactionUrl } from "@utils/hashgraphUtils";
import { motion } from "framer-motion";
import React from "react";

type EntryDataProps = {
  entry: any;
};

const EntryData: React.FunctionComponent<EntryDataProps> = ({
  entry,
}: EntryDataProps) => {
  return (
    <motion.div
      className="mt-0 mb-12 flex-grow"
      key="form"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <dl>
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Entry Name</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {entry.name}
            </dd>
          </div>

          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Ledger Name</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {entry?.ledger?.name ?? "Not found"}
            </dd>
          </div>

          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Created At</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {shortDate(entry.createdAt)}
            </dd>
          </div>

          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Updated At</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {shortDate(entry.updatedAt)}
            </dd>
          </div>

          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">
              TransactionHash
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 truncate">
              {entry.receipt?.transactionHash ?? "Not found"}
            </dd>
          </div>

          {entry.receipt?.transactionHash && (
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Verify</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <a href={`${getTransactionUrl(entry.receipt?.transactionId)}`}>
                  <i
                    className="fal fa-external-link"
                    title="verify on Ledger Works"
                  ></i>
                </a>
              </dd>
            </div>
          )}
        </dl>
      </div>
    </motion.div>
  );
};

export default EntryData;
