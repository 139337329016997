import RuleItem from "./RuleItem";

import RuleForm from "./RuleForm";

type RuleListProps = {
  definition: any;
  rules: any;
};

const RuleList: React.FunctionComponent<RuleListProps> = ({
  definition,
  rules,
}: RuleListProps) => {
  const sortedRules = rules.map((rule: any) => {
    const field = definition.fields.find(
      (field: any) => field.id === rule.fieldId
    );
    return { ...rule, field };
  });

  return (
    <div className="flex flex-col -mx-6 mt-12 px-6 pt-12 border-t border-gray-200">
      <h4 className="text-xl font-semibold mb-3">Definition Rules</h4>
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Field Name
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Optional Label
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Field Type
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Rule
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Value
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Created At
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                  ></th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {!rules.length && (
                  <tr>
                    <td
                      colSpan={6}
                      className="text-center text-gray-600 text-sm p-3"
                    >
                      <p>
                        This definition has no rules. You can add them below.
                      </p>
                    </td>
                  </tr>
                )}
                {sortedRules.map((rule: any) => (
                  <RuleItem key={rule.id} rule={rule} />
                ))}
              </tbody>
              <tfoot className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    colSpan={7}
                    className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    <RuleForm fields={definition.fields} />
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
export default RuleList;
