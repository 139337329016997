import { useParams } from "react-router-dom";
import LedgerNav from "./ledger/LedgerNav";
import RouteTransition from "./layout/RouteTransition";
import PageHeader from "./layout/PageHeader";
import DefinitionData from "./definition/DefinitionData";

import RuleList from "./definition/RuleList";
import { useQuery } from "@tanstack/react-query";
import { ledgerQuery, rulesQuery } from "@utils/queries";

const Definitions = () => {
  const { id = "", definitionId = "" } = useParams();

  let { data: ledger } = useQuery(["ledger", id], () => ledgerQuery(id), {
    keepPreviousData: true,
  });

  const { data: rules } = useQuery(
    ["rules", definitionId],
    () => rulesQuery(definitionId),
    {
      keepPreviousData: true,
    }
  );

  if (!ledger || !rules) return null;
  const definition = ledger.definitions.find(
    (def: any) => def.id === definitionId
  );

  if (!definition) return null;

  return (
    <RouteTransition>
      <PageHeader
        pageTitle={ledger.deletedAt ? `${ledger.name} (Archived)` : ledger.name}
        breadcrumbs={[
          ["All Ledgers", "/ledgers/"],
          [ledger.name, `/ledgers/${ledger.id}/definitions`],
          [definition.entryName, undefined],
        ]}
      />
      <main className="bg-white rounded-lg w-full shadow-sm">
        <LedgerNav ledger={ledger} />
        <section className="p-4 md:p-6">
          {definition && (
            <DefinitionData definition={definition} ledger={ledger} />
          )}

          <RuleList definition={definition} rules={rules} />
        </section>
      </main>
    </RouteTransition>
  );
};

export default Definitions;
