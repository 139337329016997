import { endOfDay, startOfDay } from "date-fns";
import DatePicker from "react-date-picker";

import MultiSelect from "../layout/MultiSelect";
import { Formik, Form } from "formik";

type EntryFilterOptionsProps = {
  filters: any;
  activeFilters: any;
  setActiveFilters: any;
  filterByDate: any;
  setFilterByDate: any;
  startDate: any;
  setStartDate: any;
  endDate: any;
  setEndDate: any;
};

const EntryFilterOptions: React.FunctionComponent<EntryFilterOptionsProps> = ({
  filters,
  activeFilters,
  setActiveFilters,
  filterByDate,
  setFilterByDate,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}: EntryFilterOptionsProps) => {
  const onStartDateChange = (start: any) => {
    const end = endDate || new Date();
    setFilterByDate(true);
    setStartDate(startOfDay(start));
    setEndDate(endOfDay(end));
  };
  const onEndDateChange = (end: any) => {
    const start = startDate || new Date();
    setFilterByDate(true);
    setStartDate(startOfDay(start));
    setEndDate(endOfDay(end));
  };

  return (
    <div className="overflow-visible">
      <div className="my-5">
        {
          <div>
            <div className="mb-2 text-sm font-medium hover:text-black text-gray-500">
              <span className="mr-2">Filter by Entry Name</span>
            </div>
            <Formik
              initialValues={{
                filters,
                startDate: "",
                endDate: "",
                entries: [],
              }}
              onSubmit={() => {}}
            >
              {(props) => (
                <Form>
                  <MultiSelect
                    name="entries"
                    placeholder="Select Entries"
                    value={props.values.entries}
                    isMulti={true}
                    onChange={props.setFieldValue}
                    isClearable={true}
                    backspaceRemovesValue={true}
                    setActiveFilters={setActiveFilters}
                    filters={filters}
                  />
                  {!filterByDate && (
                    <button
                      type="button"
                      className="my-2 text-sm font-medium hover:text-black text-gray-500"
                      onClick={() => setFilterByDate(!filterByDate)}
                    >
                      <span className="mr-2">Filter by Date?</span>
                      <i className="far fa-calendar-alt"></i>
                    </button>
                  )}
                  {filterByDate && (
                    <div>
                      <div className="my-2 text-sm font-medium hover:text-black text-gray-500">
                        <span className="mr-2">Filter by Date</span>
                      </div>
                      <div className="grid grid-cols-5">
                        <div className="col-span-2">
                          <div className="my-2 text-sm font-small hover:text-black text-gray-500">
                            <span className="mr-2">Start Date</span>
                          </div>
                          <DatePicker
                            onChange={(setDate) => {
                              props.setFieldValue("startDate", setDate);
                              onStartDateChange(setDate);
                            }}
                            className="col-span-2 bg-white"
                            name="value"
                            minDate={new Date(1900, 0, 1)}
                            maxDate={new Date(2100, 0, 1)}
                            value={props.values.startDate}
                          />
                        </div>
                        <div className="col-span-2">
                          <div className="my-2 text-sm font-small hover:text-black text-gray-500">
                            <span className="mr-2">End Date</span>
                          </div>
                          <DatePicker
                            onChange={(setDate) => {
                              props.setFieldValue("endDate", setDate);
                              onEndDateChange(setDate);
                            }}
                            className="col-span-2 bg-white"
                            name="value"
                            minDate={new Date(1900, 0, 1)}
                            maxDate={new Date(2100, 0, 1)}
                            value={props.values.endDate}
                          />
                        </div>
                        <div className="place-self-center">
                          <button
                            type="button"
                            className="mr-2 text-sm font-medium hover:text-black text-gray-500"
                            onClick={() => setFilterByDate(!filterByDate)}
                          >
                            <span className="mx-2">Clear</span>
                            <i className="fal fa-window-close"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        }
      </div>
    </div>
  );
};
export default EntryFilterOptions;
