import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";

const DeleteTableData = ({ id, service }: any) => {
  const queryClient = useQueryClient();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmission = () => {
    service
      .delete(id)
      .then(() => {
        queryClient.refetchQueries(service.entity);
      })
      .catch(({ response }: any) => {
        console.error(response);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleCancellation = () => {
    setIsSubmitting(false);
  };

  return (
    <div>
      {!isSubmitting && (
        <div className="grid place-items-center opacity-100 md:opacity-0 md:group-hover:opacity-100">
          <div
            className="grid w-7 h-7 place-items-center hover:bg-gray-100 rounded-full cursor-pointer"
            onClick={() => {
              setIsSubmitting(true);
            }}
          >
            <i className="fal fa-trash fa-fw text-base text-gray-500 rounded-full cursor-pointer" />
          </div>
        </div>
      )}
      {isSubmitting && (
        <div className="flex gap-1 place-items-center">
          <div className="mr-1 text-red-500">Delete?</div>
          <div
            className="grid w-7 h-7 place-items-center hover:bg-gray-100 opacity-50 hover:opacity-100 rounded-full cursor-pointer"
            onClick={handleSubmission}
          >
            <i className="fal fa-check fa-fw text-lg" />
          </div>
          <div
            className="grid w-7 h-7 place-items-center hover:bg-gray-100 opacity-50 hover:opacity-100 rounded-full cursor-pointer"
            onClick={handleCancellation}
          >
            <i className="fal fa-times fa-fw text-lg" />
          </div>
        </div>
      )}
    </div>
  );
};
export default DeleteTableData;
