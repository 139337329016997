import { buildYup } from "schema-to-yup";

export const createValidation = (definition: any, rules: any) => {
  const properties = definition.fields.reduce(
    (accumulator: any, field: any) => {
      const applicableRules = rules.filter(
        (rule: any) => rule.fieldId === field.id
      );

      let type = "string";
      if ([2, 9].includes(field.type)) type = "number";
      if ([3, 5].includes(field.type)) {
        return accumulator;
      }
      if (field.type === 4) type = "boolean";
      if (field.type === 11) type = "object";

      const required = !!applicableRules.find((rule: any) => rule.type === 1);

      accumulator[field.name] = {
        type,
        required,
      };

      if (field.type === 1) {
        const minLengthRule = applicableRules.find(
          (rule: any) => rule.type === 2
        );
        const maxLengthRule = applicableRules.find(
          (rule: any) => rule.type === 3
        );

        if (minLengthRule) {
          accumulator[field.name]["minLength"] = minLengthRule.value;
        }
        if (maxLengthRule) {
          accumulator[field.name]["maxLength"] = maxLengthRule.value;
        }
      }

      if ([2, 9].includes(field.type)) {
        const minimumRule = applicableRules.find(
          (rule: any) => rule.type === 6
        );
        const maximumRule = applicableRules.find(
          (rule: any) => rule.type === 7
        );

        if (minimumRule) {
          accumulator[field.name]["moreThan"] = Number(minimumRule.value);
        }
        if (maximumRule) {
          accumulator[field.name]["lessThan"] = Number(maximumRule.value);
        }
      }

      if ([3, 5].includes(field.type)) {
        const afterDateRule = applicableRules.find(
          (rule: any) => rule.type === 4
        );
        const beforeDateRule = applicableRules.find(
          (rule: any) => rule.type === 5
        );

        if (afterDateRule) {
          accumulator[field.name]["minDate"] = afterDateRule.value;
        }
        if (beforeDateRule) {
          accumulator[field.name]["maxDate"] = beforeDateRule.value;
        }
      }

      return accumulator;
    },
    {}
  );
  const schema = {
    $schema: "http://json-schema.org/draft-07/schema#",
    $id: "http://example.com/person.schema.json",
    title: "Person",
    description: "A person",
    type: "object",
    properties,
  };

  return buildYup(schema);
};
