import ErrorLayout from "@components/layout/ErrorLayout";
import UserService from "../services/UserService";

const userService = new UserService();

const UserInfoChangeError = () => {
  userService.logOut();
  setTimeout(function () {
    window.location.href = "/";
  }, 3000);
  return (
    <ErrorLayout
      title="User Information Change"
      description="User information has been changed, sending you back to the sign in."
    >
      <a
        href="/"
        className="inline-block btn-secondary text-sm rounded  hover:underline hover:text-blue-500"
      >
        Go to sign in
        <span className="inline-block ml-2" aria-hidden="true">
          &rarr;
        </span>
      </a>
    </ErrorLayout>
  );
};

export default UserInfoChangeError;
