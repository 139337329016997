import { NavLink } from "react-router-dom";
import classNames from "classnames";

const NavItem = ({
  activeClassName,
  className,
  isActive: isActiveProp,
  exact,
  ...props
}: any) => {
  return (
    <NavLink
      {...props}
      className={({ isActive }) => {
        const active = isActiveProp != null ? isActiveProp : isActive;

        return classNames(className, active ? activeClassName : null);
      }}
      end={!!exact}
    />
  );
};

export default NavItem;
