import ENV from "@config/env";

const url = ENV.API_HOST;

type LedgerDataProps = {
  ledger: any;
};

const LedgerData: React.FunctionComponent<LedgerDataProps> = ({
  ledger,
}: LedgerDataProps) => {
  const heading4Class = "text-xl font-semibold mt-12 mb-4";
  const paragraphClass = "text-gray-700 mb-6 break-all";

  return (
    <>
      <article className="max-w-2xl mx-auto my-6">
        <h3 className="text-2xl font-semibold">API Integration</h3>

        <h4 className={heading4Class}>ID</h4>
        <p className={paragraphClass}>{ledger.id}</p>

        <h4 className={heading4Class}>Entry Addition Endpoint</h4>
        <p className={paragraphClass}>
          <code className="block text-sm border rounded-sm px-3 py-2 break-all">
            POST {url}/{ledger.id}
          </code>
        </p>

        <h4 className={heading4Class}>List Entries Endpoint</h4>
        <p className={paragraphClass}>
          <code className="block text-sm border rounded-sm px-3 py-2 break-all">
            GET {url}/{ledger.id}
          </code>
        </p>

        <h4 className={heading4Class}>API Token</h4>
        <p className={paragraphClass}>{btoa(ledger.id)}</p>

        <h4 className={heading4Class}>API Auth Header</h4>
        <p className={paragraphClass}>
          <code className="block text-sm border rounded-sm px-3 py-2 break-all">
            Authorize: Bearer {btoa(ledger.id)}
          </code>
        </p>
      </article>
    </>
  );
};

export default LedgerData;
